import React, { FormEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { BASE_URL } from "../../../utils/Constants"
import '../../styles.css'
import ChangePasswordDialog from "./components/ChangePasswordDialog"
import "./css/LoginPage.css"


const LoginPage = () => {

    // Loading state, to display a spinner when an APi is called
    const [isLoading, setIsLoading] = useState(false)

    // States for changing email and password inputs
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // The error text under password input box
    const [passwordErrorText, setPasswordErrorText] = useState("")

    // States for changing the input type of the password field, together with the icon
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState("/images/show-password-disabled.svg");

    // A function returning a boolean if the email input is acceptable or not
    const isEmailAcceptable = (newEmail: string) => !(!newEmail.includes("@") || newEmail.endsWith("@"));

    // A function which handles a change in the email input box
    const emailInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextEmail = e.target.value;

        // Se l'email passa da accettabile a non accettabile, il logo vicino al password input si disabilita. Viceversa al contrario.
        if (isEmailAcceptable(email) && !isEmailAcceptable(nextEmail)) {
            if (icon === "/images/show-password.svg") {
                setIcon("/images/show-password-disabled.svg")
            } else if (icon === "/images/hide-password.svg") {
                setIcon("/images/hide-password-disabled.svg")
            }
        } else if (!isEmailAcceptable(email) && isEmailAcceptable(nextEmail)) {
            if (password !== "") {
                if (icon === "/images/show-password-disabled.svg") {
                    setIcon("/images/show-password.svg")
                } else if (icon === "/images/hide-password-disabled.svg") {
                    setIcon("/images/hide-password.svg")
                }
            }
        }
    }

    // A function which handles a change in the password input box
    const passwordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Azzera l'error text sotto
        setPasswordErrorText("")

        const nextPassword = e.target.value

        // Se l'email è accettabile e se la nuova password è valorizzata, si modifica l'icona dell'occhio vicino al password input box
        if (nextPassword !== "" && isEmailAcceptable(email)) {
            if (type === "text") {
                setIcon("/images/hide-password.svg")
            } else {
                setIcon("/images/show-password.svg")
            }
        } else {
            if (type === "text") {
                setIcon("/images/hide-password-disabled.svg")
            } else {
                setIcon("/images/show-password-disabled.svg")
            }
        }
    }

    const navigate = useNavigate();

    // Handles the submit button in the login form
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        callLoginAPI();
    }

    // LOGIN Api
    async function callLoginAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email.toLowerCase(),
                    password: password,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            if (responseJson.detail === "Password reset required") {
                setPasswordErrorText("Cambio password necessario")
                setShowChangePasswordDialog(true)
            } else {
                setPasswordErrorText("Indirizzo e-mail o password errata")
            }
        } else {
            // Se la chimata va a buon fine si va alla login success page
            const result = await response.json();
            const idToken = result.id_token
            const accessToken = result.access_token
            const userId = result.id
            const refreshToken = result.refresh_token
            navigate(`/login-success`, { state: { idToken, accessToken, userId, refreshToken } })
        }
    }

    // LOGIN with Google Api
    async function callGoogleLoginAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/google/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})
        })
        if (!response.ok) {
            const result = await response.json();
            setIsLoading(false)
            console.log(`error: ${result}`)
            // setPasswordErrorText("Indirizzo e-mail o password errata")
        } else {
            const result = await response.json();
            setIsLoading(false)
            window.open(result, "_self")
        }
    }

    // Function for handling the click on the eye icon
    const handleToggleShowPasswordIcon = () => {
        if (password !== '' && isEmailAcceptable(email)) {
            if (type === 'password') {
                setIcon("/images/hide-password.svg")
                setType('text')
            } else {
                setIcon("/images/show-password.svg")
                setType("password")
            }
        }
    }

    const onBackButtonClick = () => {
        window.open("https://www.holifya.com/", "_self")
    }

    const onCreateAccountClick = () => {
        // navigate("/create-account", { state: { newsletterAccepted: false } })
        navigate("/email-registration")
    }

    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)

    return (
        <div className={`App relative`}>
            <div className={`login-page-buttons-row ${(isLoading || showChangePasswordDialog) ? "visible_30_percent inactive" : ""}`}>
                <BackButton onClick={onBackButtonClick} />
                <button
                    className="small-button s12 w700 no_border secondary_action_color_background black_text"
                    onClick={onCreateAccountClick}
                >
                    Crea un Account
                </button>
            </div>
            <div className={`vertical_flex_group justify_content_center align_items_center height_80_percent ${(isLoading || showChangePasswordDialog) ? "visible_30_percent inactive" : ""}`}>
                <HolifyaFullLogo />
                <div className={`vertical_flex_group white_background width_392`}>
                    <div className="width_328 lh24 height_48 margin_top_30 margin_bottom_30 center self_center s18 w400">
                        Inserisci le tue credenziali per accedere al mondo Holifya
                    </div>
                    <form className="width_328 self_center" onSubmit={handleSubmit}>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                            <label className="s12 w600 uppercase">Indirizzo e-mail</label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="login-page-input-field s16 w400"
                                    type="text"
                                    placeholder="nome@esempio.com"
                                    onChange={
                                        e => {
                                            setEmail(e.target.value);
                                            emailInputChangeHandler(e)
                                        }
                                    }
                                />
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">
                                {(isEmailAcceptable(email) || email === "") ? "" : "Inserisci un indirizzo e-mail valido"}
                            </label>
                        </div>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                            <label className="s12 w600 uppercase">Password</label>
                            <div
                                className={`horizontal_flex_group align_items_end ${isEmailAcceptable(email) ? "black_border_bottom" : "light_gray_border_bottom"}`}
                            >
                                <input
                                    className={`login-page-input-field s16 w400 margin_left_2 ls1 ${isEmailAcceptable(email) ? "black_text" : "light_gray_text"}`}
                                    type={type}
                                    placeholder="Password"
                                    onChange={
                                        (e) => {
                                            setPassword(e.target.value);
                                            passwordInputChangeHandler(e)
                                        }
                                    }
                                    disabled={!isEmailAcceptable(email)}
                                />
                                <span
                                    className={`lh0 margin_right_5 self_center ${(password !== "" && isEmailAcceptable(email)) ? "pointer" : ""}`}
                                    onClick={handleToggleShowPasswordIcon}
                                >
                                    <img src={icon}></img>
                                </span>
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">{passwordErrorText}</label>
                        </div>
                        <button
                            className={`uppercase w700 s12 br5 height_55 width_328 blue_background no_border white_text center margin_bottom_20 ${(password !== "" && isEmailAcceptable(email)) ? "pointer" : ""}`}
                            disabled={password === "" || !isEmailAcceptable(email)}
                        >
                            Accedi
                        </button>
                    </form>

                    {/* <Divider text="o"></Divider>

                    <div className="width_328 self_center">
                        <LogoButton children={"Accedi con Google"} image="/images/google-logo.svg" onClick={callGoogleLoginAPI} />
                    </ div>
                    <div className="width_328 self_center">
                        <LogoButton children={"Accedi con Apple"} image="/images/apple-logo.svg" onClick={() => { navigate("/apple-login") }} />
                    </ div> */}
                    <div className="width_328 self_center">
                        <button
                            className="margin_top_10 width_328 height_55 pointer uppercase w700 s14 br5 transparent_background no_border black_text"
                            onClick={() => { navigate("/forgot-password") }}
                        >
                            Non riesci ad accedere?
                        </button>
                    </div>
                </div>
                {/* } */}
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            {
                showChangePasswordDialog ?
                <ChangePasswordDialog
                    onClick={() => setShowChangePasswordDialog(false)}
                /> : null
            }
        </div>
    )
}

export default LoginPage
