import "./css/SubscriptionItemCard.css"

export interface SubscriptionItem {
    createdAt: string,
    type: string,
}

interface Props {
    subscription: SubscriptionItem,
}

const handleSubPeriod = (type: string) => {
    switch (type) {
        case "diet_subscription":
        case "diet_bundle":
            return "Abbonamento 1 mese"
        case "3_months_subscription":
        case "3_months_bundle":
            return "Abbonamento 3 mesi"
        case "6_months_subscription":
        case "6_months_bundle":
            return "Abbonamento 6 mesi"
    }
}

const handleDate = (date: string) => {
    const dateDate = new Date(date)
    const year = dateDate.getFullYear().toString()
    const month = dateDate.getMonth() + 1 < 10 ? "0" + (dateDate.getMonth() + 1).toString() : (dateDate.getMonth() + 1).toString()
    const day = dateDate.getDate() < 10 ? "0" + (dateDate.getDate()).toString() : (dateDate.getDate()).toString()
    // return dateDate.toDateString()
    return `${day}/${month}/${year}`
}

export const SubscriptionItemCard: React.FC<Props> = ({
    subscription,
}) => {
    return (
        <div>
            <div className="subscription-item-card-date">
            Data inizio abbonamento: {handleDate(subscription.createdAt)}
        </div>
            <div className="subscription-item-card-external-container">
                <div className="subscription-item-card-interal-container">
                    <div className="subscription-item-card-left-element-container">
                        <div>
                            <img
                                src="/images/bundle-test-sub-icon.svg"
                            />
                        </div>
                        <div className="subscription-item-card-title">
                            {handleSubPeriod(subscription.type)}
                        </div>
                    </div>

                </div>
            </div>
            <div className="height_20" />
            <div className="subscription-item-card-subtitle">
                Se vuoi disdire il tuo abbonamento scrivici a support@holifya.com
            </div>
        </div>
    )
}
