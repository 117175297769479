import { QuizType } from "../../../../../utils/enums/QuizType";
import "./css/MyAnswersSurveyCard.css";

interface Props {
    onCardClick: () => void,
    survey: string,
    createdAt: string,
}

const surveyName = (survey: string) => {
    switch (survey) {
        case QuizType.QuizQ:
            return "Welcome survey";
        case QuizType.SurveyA:
            return "Anamnesi clinica";
        case QuizType.SurveyB:
            return "Anamnesi alimentare";
        default:
            return "Wrong survey type"
    }
}

const surveyContents = (survey: string) => {
    switch (survey) {
        case QuizType.QuizQ:
            return "Questo è il primo questionario a cui ti abbiamo sottoposto. Domande ampie su obiettivi e lifestyle per inquadrare il tuo primo passo in Holifya";
        case QuizType.SurveyA:
            return "Questo è il secondo questionario a cui ti abbiamo sottoposto. Domande sulle tue informazioni anamnestiche";
        case QuizType.SurveyB:
            return "Questo è il terzo questionario a cui ti abbiamo sottoposto, disponibile solo per chi acquista un piano nutrizionale. Le tue abitudini di vita / sport / alimentazione";
        default:
            return "Wrong survey type"
    }
}

const handleDate = (date: string) => {
    const day = date.substring(8, 10)
    const month = date.substring(5, 7)
    const year = date.substring(0, 4)
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
}

const MyAnswersSurveyCard: React.FC<Props> = ({
    onCardClick,
    survey,
    createdAt,
}) => {
    return (
        <div
            className={`my-answers-survey-card-container`}
            onClick={onCardClick}
        >
            <div className="my-answers-survey-card-internal-container">
                <div className="my-answers-survey-card-upper-element-container">
                    <div className="my-answers-survey-card-survey-name">
                        {surveyName(survey)}
                    </div>
                    <div className="my-answers-survey-card-survey-date">
                        {handleDate(createdAt)}
                    </div>
                </div>
                <div className="my-answers-survey-card-bottom-element-container">
                    <div className="my-answers-survey-card-contents-heading">Cosa contiene:</div>
                    <div className="my-answers-survey-card-contents-copy">
                        {surveyContents(survey)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAnswersSurveyCard
