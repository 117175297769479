import { trackingStates } from "../../../../utils/Constants"
import TrackingStatus from "../../../../utils/enums/TrackingStatus"
import "./css/SwabPathWidget.css"

interface Props {
    trackingStatus: TrackingStatus,
    trackingCode: string,
    onLinkClick: () => void,
}

const SwabPathWidget: React.FC<Props> = ({
    trackingStatus,
    trackingCode,
    onLinkClick,
}) => {

    const handleProgressBar = () => {
        switch (trackingStatus) {
            case TrackingStatus.Purchased:
                return "width_7_percent"
            case TrackingStatus.Delivered:
                return "width_13_percent"
            case TrackingStatus.TestDone:
                return "width_29_percent"
            case TrackingStatus.SampleRegistered:
                return "width_45_percent"
            case TrackingStatus.PickupRequested:
                return "width_45_percent"
            case TrackingStatus.Departed:
                return "width_50_percent"
            case TrackingStatus.DeliveredToLaboratory:
                return "width_67_percent"
            case TrackingStatus.StartingToExtract:
                return "width_82_percent"
            case TrackingStatus.StartingAnalysis:
                return "width_95_percent"
            case TrackingStatus.AnalysisDone:
                return "width_100_percent"
        }
    }

    const handleTitleTwo = () => {
        switch (trackingStatus) {
            case TrackingStatus.Purchased:
                return <div className="vertical_flex_group justify_content_space_between">
                    <div>TNT si sta occupando della consegna del tuo kit.</div>
                    <div className="w400">Accedi al tracking di TNT con <span className="pointer primary_action_color_text underline_text" onClick={() => window.open(trackingCode, "_blank")}>questo link</span></div>
                </div>
            case TrackingStatus.Delivered:
                return <span>Il tuo campione è arrivato! <span onClick={onLinkClick} className="primary_action_color_text w400 underline_text pointer">Effettua l'autoprelievo adesso</span></span>
            case TrackingStatus.TestDone:
                return <span onClick={onLinkClick} className="primary_action_color_text w400 underline_text pointer">Registra il tuo campione adesso!</span>
            case TrackingStatus.SampleRegistered:
                return <span>Decidi come far arrivare il tuo campione in laboratorio. <span onClick={onLinkClick} className="primary_action_color_text w400 underline_text pointer">TNT Point o ritiro al domicilio?</span></span>
            case TrackingStatus.PickupRequested:
                return <span>Hai cambiato i tuoi piani? <span onClick={onLinkClick} className="primary_action_color_text w400 underline_text pointer">Richiedi un ritiro con informazioni differenti</span></span>
            case TrackingStatus.Departed:
                return <span>Il tuo campione è in viaggio verso il laboratorio.</span>
            case TrackingStatus.DeliveredToLaboratory:
                return <span>Il tuo campione è in laboratorio in fase di accettazione. Questa fase può durare qualche giorno.</span>
            case TrackingStatus.StartingToExtract:
                return <span>La fase di del DNA estrazione è iniziata.</span>
            case TrackingStatus.StartingAnalysis:
                return <span>Il tuo DNA è in analisi. Manca sempre meno! <span onClick={onLinkClick} className="primary_action_color_text w400 underline_text pointer">Come avviene l'analisi?</span></span>
            case TrackingStatus.AnalysisDone:
                return "Il tuo risultato è arrivato! Visualizzalo in Home o nell'area “Esami e test” all'interno del menu."
        }
    }

    return (
        <div className="swab-path-widget-container">
            <div className="swab-path-widget-internal-container">
                <div className="swab-path-widget-heading">
                    Il tuo campione
                </div>
                <div className="swab-path-widget-path-container">
                    <div className="swab-path-widget-path-internal-container">
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.Purchased) ? "swab-path-widget-filled-dot" : "swab-path-widget-empty-dot"}`}></div>
                            <div className={`swab-path-widget-path-states`}>Acquisto</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.TestDone) ? "swab-path-widget-filled-dot" : (trackingStatus === TrackingStatus.Delivered ? "swab-path-widget-empty-dot-blue" : "swab-path-widget-empty-dot")}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.Delivered ? "blue-text-swab-path" : ""}`}>Effettua il test</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.SampleRegistered) ? "sample-path-filled-dot" : (trackingStatus === TrackingStatus.TestDone ? "sample-path-empty-dot-blue" : "sample-path-empty-dot")}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.TestDone ? "blue-text-swab-path" : ""}`}>Registra campione</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.PickupRequested) ? "sample-path-filled-dot" : (trackingStatus === TrackingStatus.SampleRegistered ? "sample-path-empty-dot-blue" : "sample-path-empty-dot")}`}></div>
                            <div className={`swab-path-widget-path-states ${(trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested) ? "blue-text-swab-path" : ""}`}>Richiedi il ritiro</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.DeliveredToLaboratory) ? "sample-path-filled-dot" : "sample-path-empty-dot"}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.DeliveredToLaboratory ? "blue-text-swab-path" : ""}`}>In laboratorio</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.StartingToExtract) ? "sample-path-filled-dot" : "sample-path-empty-dot"}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.StartingToExtract ? "blue-text-swab-path" : ""}`}>Inizio estrazione</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.StartingAnalysis) ? "sample-path-filled-dot" : "sample-path-empty-dot"}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.StartingAnalysis ? "blue-text-swab-path" : ""}`}>Analisi</div>
                        </div>
                        <div className="swab-path-widget-single-path-element">
                            <div className={`${trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.AnalysisDone) ? "sample-path-filled-dot" : "sample-path-empty-dot"}`}></div>
                            <div className={`swab-path-widget-path-states ${trackingStatus === TrackingStatus.AnalysisDone ? "blue-text-swab-path" : ""}`}>Risultato</div>
                        </div>
                        <div className="swab-path-widget-progression-bar-empty">
                            <div className={`swab-path-widget-progression-bar-filled ${handleProgressBar()}`} />
                        </div>
                    </div>
                </div>
                <div className="swab-path-widget-title-two">
                    {handleTitleTwo()}
                </div>
            </div>
        </div>
    )
}

export default SwabPathWidget
