import "./css/SurveyBWidget.css"

interface Props {
    onClick: () => void,
    hasBigPriority: boolean,
}

const SurveyBWidget: React.FC<Props> = ({
    onClick,
    hasBigPriority,
}) => {
    return (
        <div className={`${hasBigPriority ? "survey-b-widget-container-priority" : "survey-b-widget-container"}`}>
            <div className={`survey-b-widget-left-element-container`}>
                <div className={`survey-b-widget-left-element-internal-container`}>
                    <div className="survey-b-widget-heading">Le tue preferenze alimentari e lifestyle</div>
                    <div className="survey-b-widget-copy">Rispondi in pochi minuti entro il primo consulto con il tuo nutrizionista.<br></br>
                        Puoi modificare le tue risposte quando nell'area “Le mie domande”<br></br>
                        all'interno del menu.</div>
                    <button
                        className={`${hasBigPriority ? "survey-b-widget-answer-button-priority-desktop primary_action_color_background" : "survey-b-widget-answer-button-desktop"}`}
                        onClick={onClick}
                    >
                        Rispondi
                    </button>
                </div>
            </div>
            <div className={`${hasBigPriority ? "survey-b-widget-right-element-container-priority" : "survey-b-widget-right-element-container"}`}>
                <div className={`survey-b-widget-right-element-internal-container`}>
                <button
                        className={`${hasBigPriority ? "survey-b-widget-answer-button-priority-mobile primary_action_color_background" : "survey-b-widget-answer-button-mobile"}`}
                        onClick={onClick}
                    >
                        Rispondi
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SurveyBWidget
