import { NewQuestion, NewQuestionType } from "../pages/surveys/components/NewQuestion";

export const answerAltro = "Altro"

const quizQNewQuestion1: NewQuestion = {
    id: 1,
    question: "Raccontaci di te: quali sono i tuoi obiettivi?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/quizQ/bilancia.svg",
            text: "Pesoforma",
        },
        {
            icon: "/images/quizQ/piatto.svg",
            text: "Migliore alimentazione in generale",
        },
        {
            icon: "/images/quizQ/intestino.svg",
            text: "Salute gastrointestinale (gonfiore, digestione…)",
        },
        {
            icon: "/images/quizQ/latte.svg",
            text: "Scoprire e agire su intolleranze",
        },
        {
            icon: "/images/quizQ/longevità.svg",
            text: "Longevità e prevenzione a 360°",
        },
        {
            icon: "/images/quizQ/sport.svg",
            text: "Nutrizione per sport performance",
        },
        {
            icon: "/images/quizQ/integratori.svg",
            text: "Integratori adatti alle mie esigenze",
        },
        {
            icon: "/images/quizQ/salute.svg",
            text: "Migliore stato di salute in generale",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_7_percent",
}

export const question1Answers = [
    "Pesoforma",
    "Migliore alimentazione in generale",
    "Salute gastrointestinale (gonfiore, digestione…)",
    "Scoprire e agire su intolleranze",
    "Longevità e prevenzione a 360°",
    "Nutrizione per sport performance",
    "Integratori adatti alle mie esigenze",
    "Migliore stato di salute in generale",
]

const quizQNewQuestion1bis: NewQuestion = {
    id: 1.2,
    question: "Tra questi, qual è il tuo obiettivo principale?",
    comment: "Seleziona una risposta",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/bilancia.svg",
            text: "Pesoforma",
        },
        {
            icon: "/images/quizQ/piatto.svg",
            text: "Migliore alimentazione in generale",
        },
        {
            icon: "/images/quizQ/intestino.svg",
            text: "Salute gastrointestinale (gonfiore, digestione…)",
        },
        {
            icon: "/images/quizQ/latte.svg",
            text: "Scoprire e agire su intolleranze",
        },
        {
            icon: "/images/quizQ/longevità.svg",
            text: "Longevità e prevenzione a 360°",
        },
        {
            icon: "/images/quizQ/sport.svg",
            text: "Nutrizione per sport performance",
        },
        {
            icon: "/images/quizQ/integratori.svg",
            text: "Integratori adatti alle mie esigenze",
        },
        {
            icon: "/images/quizQ/salute.svg",
            text: "Migliore stato di salute in generale",
        },
    ],
    progressionBarWidth: "width_7_percent",
}

const quizQNewQuestion2: NewQuestion = {
    id: 2,
    question: "Come ti chiami?",
    comment: "",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Nome",
        },
        {
            icon: "",
            text: "Cognome",
        },
    ],
    progressionBarWidth: "width_14_percent",
}

const quizQNewQuestion3: NewQuestion = {
    id: 3,
    question: "Qual è la tua data di nascita?",
    comment: "",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "GG / MM / AAAA",
        },
    ],
    progressionBarWidth: "width_21_percent",
}

const quizQNewQuestion4: NewQuestion = {
    id: 4,
    question: "Qual è il tuo sesso biologico?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/donna.svg",
            text: "Donna",
        },
        {
            icon: "/images/quizQ/uomo.svg",
            text: "Uomo",
        },
        {
            icon: "/images/quizQ/no-gender.svg",
            text: "Preferisco non rispondere",
        },
    ],
    progressionBarWidth: "width_28_percent",
}

export const quizQNewQuestion5a: NewQuestion = {
    id: 5,
    question: "Molto bene, cosa vorresti ottenere?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "",
            text: "Raggiungere il pesoforma perché sono sovrappeso",
        },
        {
            icon: "",
            text: "Mettere su peso o massa muscolare",
        },
        {
            icon: "",
            text: "Capire meglio il mio corpo e mangiare meglio",
        },
        {
            icon: "",
            text: "Essere guidato e motivato da un medico / nutrizionista / coach",
        },
        {
            icon: "",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_42_percent",
}

export const quizQNewQuestion5b: NewQuestion = {
    id: 5,
    question: "Molto bene, cosa vorresti risolvere?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "",
            text: "Gonfiore post-pasto",
        },
        {
            icon: "",
            text: "Reflusso / acidità",
        },
        {
            icon: "",
            text: "Colon irritabile",
        },
        {
            icon: "",
            text: "Stipsi (stitichezza)",
        },
        {
            icon: "",
            text: "Diarrea",
        },
        {
            icon: "",
            text: "Difficoltà a digerire",
        },
        {
            icon: "",
            text: "Mal di testa post-pasto",
        },
        {
            icon: "",
            text: "Disturbi gastrointestinali non specificati",
        },
        {
            icon: "",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_42_percent",
}

export const quizQNewQuestion5bis: NewQuestion = {
    id: 5.1,
    question: "Hai già effettuato test?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/test-standard.svg",
            text: "Sì, ho fatto un test e sono risultato intollerante...",
        },
        {
            icon: "/images/quizQ/test-lens.svg",
            text: "Sì, ho fatto un test ma non sono risultato intollerante",
        },
        {
            icon: "/images/quizQ/thinking.svg",
            text: "Non ho mai fatto un test ma penso di avere intolleranze",
        },
        {
            icon: "/images/quizQ/answer-negative.svg",
            text: "No",
        },
    ],
    progressionBarWidth: "width_42_percent",
}

export const quizQNewQuestion5tris: NewQuestion = {
    id: 5.2,
    question: "Specifica quale test hai fatto e a cosa sei risultato intollerante",
    comment: "",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Specifica",
        },
    ],
    progressionBarWidth: "width_42_percent",
}

const quizQNewQuestion6: NewQuestion = {
    id: 6,
    question: "Alcune di queste condizioni ti riguardano?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "",
            text: "Gravidanza o allattamento",
        },
        {
            icon: "",
            text: "Patologie autoimmuni",
        },
        {
            icon: "",
            text: "Celiachia",
        },
        {
            icon: "",
            text: "Diabete o insulino-resistenza",
        },
        {
            icon: "",
            text: "Obesità",
        },
        {
            icon: "",
            text: "Disordini alimentari (anoressia, bulimia)",
        },
        {
            icon: "",
            text: "Fame nervosa",
        },
        {
            icon: "",
            text: "Allergie",
        },
        {
            icon: "",
            text: "Patologie renali",
        },
        {
            icon: "",
            text: "Patologie cardiovascolari",
        },
        {
            icon: "",
            text: "Patologie oncologiche",
        },
        {
            icon: "",
            text: "Patologie epatiche",
        },
        {
            icon: "",
            text: answerAltro,
        },
        {
            icon: "",
            text: "Nessuna delle precedenti",
        },
    ],
    progressionBarWidth: "width_49_percent",
}

const quizQBreakPage1: NewQuestion = {
    id: 0,
    type: NewQuestionType.BreakPage,
    answers: [],
    icon: "/images/quizQ/dna-test-blue-break-page.svg",
    blueText: "Lo sapevi che...",
    copy: "Tantissime informazioni per personalizzare un piano alimentare efficace sono racchiuse nel nostro DNA.",
}

const quizQNewQuestion7: NewQuestion = {
    id: 7,
    question: "Vuoi effettuare anche un test del DNA?",
    comment: "Se lo acquisti assieme a un percorso hai un prezzo riservato",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/dna-test-blue.svg",
            text: "Sì, sarei molto interessato",
        },
        {
            icon: "/images/quizQ/holifya.svg",
            text: "Ho già fatto un test del DNA Holifya!",
        },
        {
            icon: "/images/quizQ/dna-test-orange.svg",
            text: "Ho già fatto un test del DNA, non con Holifya",
        },
        {
            icon: "/images/quizQ/answer-negative.svg",
            text: "No grazie, voglio solo essere seguito da un nutrizionista",
        },
    ],
    progressionBarWidth: "width_56_percent",
}

const quizQNewQuestion8: NewQuestion = {
    id: 8,
    question: "Vorresti aiutare anche i tuoi cari a intraprendere un percorso nutrizionale?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/heart.svg",
            text: "Sì, vorrei un codice sconto per una persona a cui voglio bene!",
        },
        {
            icon: "/images/quizQ/multiple-hearts.svg",
            text: "Sì, vorrei un codice sconto per tutta la mia famiglia!",
        },
        {
            icon: "/images/quizQ/only-me.svg",
            text: "No, il percorso è solo per me",
        },
    ],
    progressionBarWidth: "width_63_percent",
}

const quizQNewQuestion9: NewQuestion = {
    id: 9,
    question: "Avremmo dovuto chiederti altro su obiettivi e abitudini?",
    comment: "Condividilo con il tuo nutrizionista",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "Raccontaci di più",
        },
    ],
    progressionBarWidth: "width_70_percent",
}

const quizQBreakPage2: NewQuestion = {
    id: 0,
    type: NewQuestionType.BreakPage,
    answers: [],
    blackText: "Fantastico!",
    icon: "/images/quizQ/party.svg",
    copy: "Ora indicaci le tue preferenze sul nutrizionista che vorresti ti affiancasse. Faremo il possibile per soddisfarle e trovare il professionista più adatto a te.",
}

const quizQNewQuestion10: NewQuestion = {
    id: 10,
    question: "Hai preferenze sul genere del tuo nutrizionista?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/donna-filled.svg",
            text: "Preferirei fosse donna",
        },
        {
            icon: "/images/quizQ/uomo-filled.svg",
            text: "Preferirei fosse uomo",
        },
        {
            icon: "/images/quizQ/rocket.svg",
            text: "Indifferente, vorrei fosse il più adatto per i miei obiettivi",
        },
    ],
    progressionBarWidth: "width_77_percent",
}

const quizQNewQuestion11: NewQuestion = {
    id: 11,
    question: "In cosa dovrebbe aiutarti di più il nutrizionista?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/quizQ/support.svg",
            text: "Motivazione e supporto per evitare “sgarri”",
        },
        {
            icon: "/images/quizQ/custom-plan.svg",
            text: "Redazione piano alimentare iper-personalizzato",
        },
        {
            icon: "/images/quizQ/cooking.svg",
            text: "Consigli su come cucinare pasti sani per me",
        },
        {
            icon: "/images/quizQ/grocery.svg",
            text: "Lista della spesa personalizzata",
        },
        {
            icon: "/images/quizQ/salute.svg",
            text: "Domande sulla salute, prevenzione e nutrizione",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_84_percent",
}

const quizQNewQuestion12: NewQuestion = {
    id: 12,
    question: "Hai preferenze riguardo agli orari delle visite?",
    comment: "Puoi selezionare più risposte",
    type: NewQuestionType.Multiselect,
    answers: [
        {
            icon: "/images/quizQ/morning.svg",
            text: "Mattina | 8.30 - 13.00",
        },
        {
            icon: "/images/quizQ/afternoon.svg",
            text: "Pomeriggio | 13.00 - 18.00",
        },
        {
            icon: "/images/quizQ/evening.svg",
            text: "Sera | 18.00 - 21.00",
        },
    ],
    progressionBarWidth: "width_91_percent",
}

const quizQNewQuestion13: NewQuestion = {
    id: 13,
    question: "Quale è il tuo indirizzo e-mail?",
    comment: "Sarà più veloce creare un account e metterti in contatto con il tuo nutrizionista Holifya.",
    type: NewQuestionType.Input,
    answers: [
        {
            icon: "",
            text: "nome@esempio.com",
        },
    ],
    progressionBarWidth: "width_35_percent",
}

const quizQNewQuestion14: NewQuestion = {
    id: 14,
    question: "Come ci hai conosciuti?",
    comment: "",
    type: NewQuestionType.Radio,
    answers: [
        {
            icon: "/images/quizQ/passaparola.svg",
            text: "Passaparola / Consiglio di un amico",
        },
        {
            icon: "/images/quizQ/browser.svg",
            text: "Motori di ricerca",
        },
        {
            icon: "/images/quizQ/newspaper.svg",
            text: "Articoli di giornale o siti web",
        },
        {
            icon: "/images/quizQ/blog.svg",
            text: "Articoli dal blog Holifya",
        },
        {
            icon: "/images/quizQ/facebook.svg",
            text: "Facebook",
        },
        {
            icon: "/images/quizQ/instagram.svg",
            text: "Instagram",
        },
        {
            icon: "/images/quizQ/uomo-filled.svg",
            text: "Il mio nutrizionista, medico o coach",
        },
        {
            icon: "/images/quizQ/influencer.svg",
            text: "Influencer",
        },
        {
            icon: "/images/quizQ/altro.svg",
            text: answerAltro,
        },
    ],
    progressionBarWidth: "width_100_percen",
}

export const quizQNewQuestionsPart1 = [
    quizQNewQuestion1,
    quizQNewQuestion1bis,
    quizQNewQuestion2,
    quizQNewQuestion3,
    quizQNewQuestion4,
    quizQNewQuestion13,
]

export const quizQNewQuestionsPart2 = [
    quizQNewQuestion6,
    quizQBreakPage1,
    quizQNewQuestion7,
    quizQNewQuestion8,
    quizQNewQuestion9,
    quizQBreakPage2,
    quizQNewQuestion10,
    quizQNewQuestion11,
    quizQNewQuestion12,
    // quizQNewQuestion13,
    quizQNewQuestion14,
]