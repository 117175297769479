import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { BASE_URL } from "../../../utils/Constants";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import { NavigationToRewardPage } from "../../../utils/enums/NavigationToRewardPage";
import { ShopifyItemName } from "../../../utils/enums/ShopifyItemType";
import "./css/QuizQSuccess.css";

const QuizQSuccess = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const name = location.state.name
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const productName = location.state.productName
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const fromPlatform = location.state.fromPlatform
    const bundleType = location.state.bundleType
    const fromWhere = location.state.fromWhere

    const [stage, setStage] = useState(1)

    const shouldAssignNutritionist = fromWhere === NavigationToQuizQ.RedeemSuccess || (fromWhere === NavigationToQuizQ.Login && productName === ShopifyItemName.NutritionistConsult) || fromWhere === NavigationToQuizQ.NoPurchase

    const [ordersAvailable, setOrdersAvailable] = useState(false)

    async function callGetOrdersByUserIdApi(tempUserId: string, tempIdToken: string) {
        const response = await fetch(`${BASE_URL}/api/orders/user/${tempUserId}`, {
            method: 'GET',
            headers: {
                'Authorization': tempIdToken!
            }
        })
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length > 0) {
                setOrdersAvailable(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callGetOrdersByUserIdApi(userId, idToken)
        {
            if (shouldAssignNutritionist) {
                setTimeout(() => {
                    setStage(2)
                }, 2500)
            }
        }
        {
            setTimeout(() => {
                handleNavigation()
                // (isAfterPurchase && !fromPlatform) ?
                //     ((productName === ShopifyItemName.ExpertConsult || productName === ShopifyItemName.NutritionistConsult) ?
                //         navigate(`/survey-a-home`, { state: { userId, idToken, accessToken, productName, name } }) :
                //         navigate(`/platform`, { state: { name, idToken, accessToken, userId, productName } })) :
                //     navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest, fromPlatform, bundleType } })
            }, shouldAssignNutritionist ? 5000 : 2000)
        }
    }, [])

    const handleNavigation = () => {
        switch (productName) {
            case ShopifyItemName.NutritionistConsult:
            case ShopifyItemName.OneMonthSub:
            case ShopifyItemName.ThreeMonthsSub:
            case ShopifyItemName.SixMonthsSub:
            case ShopifyItemName.OneMonthBundle:
            case ShopifyItemName.ThreeMonthsBundle:
            case ShopifyItemName.SixMonthsBundle:
                return navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, fromWhere: NavigationToRewardPage.OnboardingAfterPurchaseNutritionistConsult } })
            case "":
                return navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, fromWhere: NavigationToRewardPage.OnboardingNoPurchase } })
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <div className="vertical_flex_group white_background width_392 height_50_percent align_items_center justify_content_space_between">
                <div className="">
                    <HolifyaFullLogo />
                </div>
                {!ordersAvailable ?
                    <div>
                        <div className="vertical_flex_group align_items_center s25 w700 lh35">
                            <div>Grazie!</div>
                            <div className="w400 center">Stiamo analizzando le tue <br></br>risposte!</div>
                        </div>
                    </div> :
                    <div>
                        <div className="s30 w700 lh38 self_center margin_top_50 margin_bottom_20 center">
                            Perfetto!
                        </div>
                        <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">
                            Stiamo creando la tua area riservata sulla base delle tue prime risposte!
                        </div>
                    </div>}
                <div>
                    <LoadingSpinner />
                </div>
                {
                    shouldAssignNutritionist ? (
                        stage === 1 ?
                            <div className="vertical_flex_group align_items_center w700 s16 lh26">
                                <img src="/images/reward-page-answers-analysis-icon.svg" />
                                <div className="margin_top_10 quiz-q-success-first-analysis-color">Definizione protocollo personalizzato</div>
                            </div> :
                            <div className="vertical_flex_group align_items_center w700 s16 lh26">
                                <img src="/images/reward-page-expert-choice.svg" />
                                <div className="margin_top_10 quiz-q-success-second-analysis-color">Selezione professionista dedicato</div>
                            </div>
                    ) : null
                }
            </div>
        </div>
    )
}


export default QuizQSuccess
