import Expert from "../../../components/Expert"
import RewardPageExpertCard from "./RewardPageExpertCard"
import "./css/RewardPageSingleConsultancy.css"

interface Props {
    expert: Expert
}

const RewardPageSingleConsultancy: React.FC<Props> = ({
    expert,
}) => {
    return (
        <div className="reward-page-single-consultancy-container">
            <div className="reward-page-single-consultancy-internal-container">
                <div className="reward-page-single-consultancy-copy">
                    Acquista il primo consulto e riscattalo nella tua area.<br></br>
                    Verrai associato al professionista più idoneo che abbiamo già selezionato per te!
                </div>
                <div>
                    <div className="w700 s16 lh38">
                        Il tuo Nutrizionista dedicato
                    </div>
                    <RewardPageExpertCard
                        expert={expert}
                    />
                </div>
                <div className="reward-page-single-consultancy-offers">
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Nutrizionista selezionato per te</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Definizione obiettivi</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Autodiagnosi</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Definizione preferenze alimentari</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Condividi i tuoi referti</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">1° appuntamento con il tuo Nutrizionista</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Piano alimentare</div>
                    </div>
                </div>
                <div className="reward-page-single-consultancy-button-row">
                    <div className="margin_right_20 w400 s30 lh60">79€</div>
                    <button className="reward-page-purchase-button primary_action_color_background">Acquista</button>
                </div>
            </div>
        </div>
    )
}

export default RewardPageSingleConsultancy
