import "./css/ExpertCommentsAndHintsWidget.css"

interface Props {
    title: string,
    author: string,
    content: string[],
}

const ExpertCommentsAndHintsWidget: React.FC<Props> = ({
    title,
    author,
    content,
}) => {
    return (
        <div className="expert-comments-hints-widget-container">
            <div className="expert-comments-hints-widget-internal-container">
                <div>
                    <div className="expert-comments-hints-widget-title">{title}</div>
                    <div className="expert-comments-hints-widget-author">{author}</div>
                </div>
                <div>
                    {
                        content.length === 1 ?
                            <div className="expert-comments-hints-widget-single-content">
                                {content}
                            </div> :
                            content.map(element => (
                                <li
                                    key={content.indexOf(element)}
                                    className="expert-comments-hints-widget-multiple-contents">{element}
                                </li>
                            ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ExpertCommentsAndHintsWidget
