import { ConsultancyStatus } from "../../../../utils/enums/ConsultancyStatus"
import "./css/ConsultancyPathWidget.css"

interface Props {
    consultancyStatus: ConsultancyStatus,
}

const ConsultancyPathWidget: React.FC<Props> = ({
    consultancyStatus,
}) => {

    const handleProgressBar = () => {
        switch (consultancyStatus) {
            case 0:
                return "width_0"
            case 1:
                return "width_30_percent"
            case 2:
                return "width_50_percent"
            case 3:
                return "width_70_percent"
            case 4:
                return "width_70_percent"
        }
    }

    const handleTitleTwo = () => {
        switch (consultancyStatus) {
            case 0:
                return <div><span className="underline_text primary_action_color_text pointer">Prenota subito</span> il tuo primo videoconsulto per proseguire nel tuo percorso!</div>
            case 1:
                return <div>Stai per partecipare al tuo primo consulto con il tuo Nutrizionista. Il tuo percorso sta per cominciare!</div>
            case 2:
                return <div>Il tuo piano alimentare è quasi pronto! Potrai trovarlo qui, ma ti avviseremo anche con una notifica alla tua casella e-mail.</div>
            case 3:
                return <div>Il tuo piano alimentare è quasi pronto! Potrai trovarlo qui, ma ti avviseremo anche con una notifica alla tua casella e-mail.</div>
            case 4:
                return <div></div>
        }
    }

    return (
        <div className="consultancy-path-widget-container">
            <div className="consultancy-path-widget-internal-container">
                <div className="consultancy-path-widget-heading">
                    Il tuo percorso
                </div>
                <div className="consultancy-path-widget-path-container">
                    <div className="horizontal_flex_group justify_content_space_between align_items_center width_95_percent height_100_percent relative">
                        <div className="height_90_percent vertical_flex_group">
                            <div className={`${consultancyStatus >= 0 ? "consultancy-path-widget-filled-dot" : "consultancy-path-widget-empty-dot"}`}></div>
                            <div className={`consultancy-path-widget-path-states ${consultancyStatus === 0 ? "consultancy-path-green-text" : null}`}>Test DNA</div>
                        </div>
                        <div className="height_90_percent vertical_flex_group">
                            <div className={`${consultancyStatus >= 1 ? "consultancy-path-widget-filled-dot" : "consultancy-path-widget-empty-dot"}`}></div>
                            <div className={`consultancy-path-widget-path-states ${consultancyStatus === 1 ? "consultancy-path-green-text" : null}`}>Prenota primo consulto<br></br>nutrizionista</div>
                        </div>
                        <div className="height_90_percent vertical_flex_group">
                            <div className={`${consultancyStatus >= 2 ? "consultancy-path-widget-filled-dot" : "consultancy-path-widget-empty-dot"}`}></div>
                            <div className={`consultancy-path-widget-path-states ${consultancyStatus === 2 ? "consultancy-path-green-text" : null}`}>Primo consulto</div>
                        </div>
                        <div className="height_90_percent vertical_flex_group">
                            <div className={`${consultancyStatus >= 3 ? "consultancy-path-widget-filled-dot" : "consultancy-path-widget-empty-dot"}`}></div>
                            <div className={`consultancy-path-widget-path-states ${consultancyStatus === 3 ? "consultancy-path-green-text" : null}`}>Percorso alimentare<br></br>personalizzato</div>
                        </div>
                        <div className="height_90_percent vertical_flex_group">
                            <div className={`consultancy-path-widget-empty-dot`}></div>
                            <div className={`consultancy-path-widget-path-states`}>Secondo consulto</div>
                        </div>
                        <div></div>
                        <div className="consultancy-path-widget-progression-bar-empty">
                            <div className={`consultancy-path-widget-progression-bar-filled ${handleProgressBar()}`} />
                        </div>
                    </div>
                </div>
                <div className="consultancy-path-widget-title-two">
                    {handleTitleTwo()}
                </div>
            </div>
        </div>
    )
}

export default ConsultancyPathWidget
