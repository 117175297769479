interface Props {
    answer: string,
    onCardClick: (text: string) => void,
    choice: string,
    transformToInputBoxText?: string,
    onTextInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    displayEmoji?: boolean,
}

const RadioAnswerCard: React.FC<Props> = ({
    answer,
    onCardClick,
    choice,
    transformToInputBoxText,
    onTextInputChange,
    displayEmoji,
}
) => {
    return (
        <div
            className={`dna-test-pickup-request-radio-choice-element ${(choice !== answer || choice === "") ? ("drop_shadow gray_border white_background") : ("card_selected_background")}`}
            onClick={() => onCardClick(answer)}
        >
            <input
                className="pointer margin_right_20 margin_left_20"
                type="radio"
                name="choice"
                value={answer}
                id={answer}
                checked={choice === answer}
                readOnly
            />
            <div>
                <div
                    className="horizontal_flex_group justify_content_center align_items_center radio_container"
                >
                    <div className={`radio_internal_element ${choice === answer ? "" : "invisible"}`}>

                    </div>
                </div>
            </div>
            {(displayEmoji || displayEmoji === undefined) ?
                <div className="margin_right_20">
                    <img src="/images/filled-rectangle.svg" />
                </div> : null
            }
            {
                !(answer === transformToInputBoxText && choice === answer) ?
                    <div className="w600 s20 lh26">
                        {answer}
                    </div> :
                    <input
                        className="answer_input_radio_field"
                        type="text"
                        onChange={onTextInputChange}
                        placeholder="Specifica la risposta"
                        autoFocus
                    />
            }
        </div>
    )
}

export default RadioAnswerCard
