import ShopifyItem from "../../../../../components/ShopifyItem"
import { handleDate } from "../../../../../utils/Functions"
import { ProductType } from "../../../../../utils/enums/ProductType"
import { ShopifyItemName } from "../../../../../utils/enums/ShopifyItemType"
import "./css/MyProduct.css"

interface Props {
    product: ShopifyItem
    infoText: string,
    onCardClick?: () => void,
    selected?: boolean,
    isClickable?: boolean,
    isChooseProductView?: boolean,
    onHandleClick?: () => void,
}

const productImage = (product: string) => {
    switch (product) {
        case ProductType.DNATestAndSub:
            return "/images/bundle-test-sub-icon.svg"
        case ProductType.Consultancy:
        case ShopifyItemName.NutritionistConsult:
            return "/images/video-consultancy-icon.svg"
        case ProductType.DNATest:
            return "/images/my-purchases-dna-test-icon.svg"
        case "DNA Complete":
        case "Test del DNA Complete":
        case ShopifyItemName.OneMonthBundle:
        case ShopifyItemName.ThreeMonthsBundle:
        case ShopifyItemName.SixMonthsBundle:
            return "/images/my-purchases-dna-test-icon.svg"
        default:
            return "Wrong product";
    }
}

const productName = (product: string) => {
    switch (product) {
        case ProductType.DNATestAndSub:
            return "Bundle Test + Abbonamento"
        case ProductType.Consultancy:
            return "Videoconsulto con Specialista"
        case ProductType.DNATest:
            return "Test del DNA"
        case ShopifyItemName.NutritionistConsult:
            return "Videoconsulto con Nutrizionista"
        case "DNA Complete":
        case "Test del DNA Complete":
        case ShopifyItemName.OneMonthBundle:
        case ShopifyItemName.ThreeMonthsBundle:
        case ShopifyItemName.SixMonthsBundle:
            return "Test del DNA"
        default:
            return "Wrong product";
    }
}

const MyProduct: React.FC<Props> = ({
    product,
    infoText,
    onCardClick,
    selected,
    isClickable,
    isChooseProductView,
    onHandleClick,
}) => {
    return (
        <div className="vertical_flex_group">
            {
                isChooseProductView ? null :
                    <div>
                        <div className="uppercase w600 s14 lh26">Codice Acquisto: {product.confirmationNumber}</div>
                    </div>
            }
            {
                product.createdAt ? <div className="uppercase w600 s14 lh26">Data di associazione: {handleDate(product.createdAt)}</div> : null
            }
            <div
                className={`my-purchases-my-product-card ${isClickable ? "pointer" : ""} ${selected ? "card_selected_background no_border" : "white_background black_border"}`}
                onClick={onCardClick}
            >
                <div className="horizontal_flex_group">
                    <div className="margin_left_20">
                        <img src={productImage(product.name)} />
                    </div>
                    <span className="margin_left_20 w600 s20 lh26">{productName(product.name)}</span>
                </div>
                {
                    product.name === ProductType.DNATestAndSub ?
                        <div
                            className="margin_right_20 pointer w700 s14 lh17 uppercase"
                            onClick={onHandleClick}
                        >
                            Gestisci
                        </div> : null
                }
            </div>
            <div>{infoText}</div>
        </div>
    )
}

export default MyProduct
