import { useLocation } from "react-router-dom"
import CheckoutForm from "./CheckoutForm"

const Checkout = () => {

    const location = useLocation()
    const idToken = location.state.idToken
    const userId = location.state.userId
    const mode = location.state.mode
    const bundleType = location.state.bundleType
    const nutritionist = location.state.nutritionist

    return (
        <div>
            <CheckoutForm
                idToken={idToken}
                userId={userId}
                mode={mode}
                bundleType={bundleType}
                nutritionist={nutritionist}
            />
        </div>
    )
}

export default Checkout
