import Expert from "../../../components/Expert"
import ExpertsTagCard from "../../../components/ExpertsTagCard"
import "./css/RewardPageExpertCard.css"

interface Props {
    expert: Expert,
    onInfoClick?: () => void,
}

const RewardPageExpertCard: React.FC<Props> = ({
    expert,
    onInfoClick,
}) => {
    return (
        <div className="reward-page-expert-card-container">
            <div className="reward-page-expert-card-internal-container">
                <div className="reward-page-expert-card-photo-info-container">
                    <div className="reward-page-expert-card-photo-container">
                        <img className="reward-page-expert-card-photo" src={expert.avatar} />
                    </div>
                    <div className="reward-page-expert-card-info-container">
                        <div className="vertical_flex_group">
                            <div className="w700 s12 lh20">
                                {expert.position}
                            </div>
                            <div className="horizontal_flex_group justify_content_space_between">
                                <div className="w700 s20 lh20">{expert.name} {expert.familyName}</div>
                                <div className="w700 s14 lh20 experts-blue-text">{expert.role}</div>
                            </div>
                            <div className="reward-page-expert-card-tags-container">
                                {
                                    expert.skills.map(skill => (
                                        <div key={skill}>
                                            <div className="width_20 height_10" />
                                            <ExpertsTagCard name={skill} isLast={false} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reward-page-expert-card-info-button-container">
                    <button
                        className="reward-page-expert-card-info-button ternary_action_color_background"
                        onClick={onInfoClick}
                    >
                        Informazioni
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RewardPageExpertCard
