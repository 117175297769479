import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HolifyaFullLogo from "../../../../components/HolifyaFullLogo";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import { handleQuestionId, separateStrings } from "../../../../utils/Functions";
import { surveyAQuestions } from "../../../../utils/SurveyAQuestions";
import { surveyBQuestions } from "../../../../utils/SurveyBQuestions";
import { QuizType } from "../../../../utils/enums/QuizType";
import InputAnswerCard from "../../../surveys/components/InputAnswerCard";
import MultiSelectAnswerCard from "../../../surveys/components/MultiSelectAnswerCard";
import { NewQuestion, NewQuestionType } from "../../../surveys/components/NewQuestion";
import RadioAnswerCard from "../../../surveys/components/RadioAnswerCard";
import MenuPopup from "../../components/MenuPopup";
import "./css/MyAnswersSurveyTypeEditSingleQuestion.css";

const MyAnswersSurveyTypeEditSingleQuestion = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        handleQuestion()
    }, [])

    // *******************************************************************************************************************

    // Question displayed to be changed
    const [currentQuestion, setCurrentQuestion] = useState<NewQuestion>()

    const handleQuizQQuestions = () => {

    }

    // Function which depending on the survey type, sets the current question
    const handleQuestion = () => {
        console.log(surveyType)
        switch (surveyType) {
            case QuizType.QuizQ: {
                handleQuizQQuestions()
                // setCurrentQuestion(quizQQuestions[index]);
                break;
            }
            case QuizType.SurveyA: {
                setCurrentQuestion(surveyAQuestions[index]);
                break;
            }
            case QuizType.SurveyB: {
                setCurrentQuestion(surveyBQuestions[index]);
                break;
            }
        }
    }

    // Function which depending on the question type, returns the new answer
    const handleAnswer = () => {
        switch (currentQuestion?.type) {
            case NewQuestionType.Radio: {
                return radioChoice;
            }
            case NewQuestionType.Input:{
                return inputAnswer
            }
            case NewQuestionType.Multiselect: {
                return multiSelectAnswers.join("; ")
            }
        }
    }

    // Constants coming from navigation
    const surveyType = location.state.surveyType    // type of the syrvey
    const index = location.state.index  // index of the question in the big array of question
    const correctAnswer = location.state.correctAnswer    // old answer, we want to modify it

    // Answer for radio questions, at the beginning is set as the previous answer
    const [radioChoice, setRadioChoice] = useState(correctAnswer)
    // Answer for input questions, at the beginning is set as the previous answer
    const [inputAnswer, setInputAnswer] = useState(correctAnswer)
    // Array of selectedAnswers for multiselect questions, at the beginning is set as the previous answer
    const [multiSelectAnswers, setMultiSelectAnswers] = useState(separateStrings(correctAnswer, "; "))

    // Boolean determining if the answer has been changed
    const [answerChanged, setAnswerChanged] = useState(false)

    // For input questions
    const isInputAcceptable = (input: string, isBirthDate?: boolean) => (currentQuestion?.type === NewQuestionType.Input ? (isBirthDate ? (/\d{1,2}\/\d{1,2}\/\d{2,4}/.test(input) && input.length <= 10) : (/^[0-9]+$/.test(input) && input.length >= 2 && input.length <= 3)) : true);

    // Submit button disabled or not
    const submitChangeAnswerButtonDisabled = currentQuestion?.type === NewQuestionType.Input ? !(answerChanged && isInputAcceptable(inputAnswer, (surveyType === QuizType.QuizQ && currentQuestion.id === 3))) : !answerChanged

    // Handles the click on the radio card
    const onRadioCardClick = (choice: string) => {
        setRadioChoice(choice)
        setAnswerChanged(true)
    }

    // Handles the change on input questions
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputAnswer(e.target.value)
        setAnswerChanged(true)
    }

    // Handles the click on the multiselect card
    const onMultiSelectCardClick = (choice: string) => {
        const index = multiSelectAnswers.indexOf(choice);
        if (index > -1) {
            setMultiSelectAnswers([...multiSelectAnswers.slice(0, index), ...multiSelectAnswers.slice(index + 1)]);
        } else {
            setMultiSelectAnswers([...multiSelectAnswers, ...[choice]]);
        }
        setAnswerChanged(true)
    }

    const onCloseClick = () => {
        navigate(`/platform/my-answers/${surveyType}`, { state: { productName, userId, idToken, accessToken, refreshToken } })
    }

    const onConfirmClick = () => {
        navigate(`/platform/my-answers/${surveyType}`, { state: { productName, userId, idToken, accessToken, refreshToken, answerChanged: true, index, correctAnswer: handleAnswer() } })
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => { navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => { navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => { navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-my-answers-edit-answers-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className={`vertical_flex_group align_items_center height_25_percent width_100_percent black_border_bottom`}>
                        <div className="horizontal_flex_group justify_content_flex_end width_100_percent height_30_percent">
                            <div className="margin-right-7-percent margin-top-3-percent">
                                <HolifyaFullLogo />
                            </div>
                        </div>
                        <div className="height_25_percent width_100_percent"></div>
                        <div className="vertical_flex_group justify_content_center width_70_percent height_45_percent w700 s25 lh35">
                            <div>
                                {currentQuestion !== undefined ?
                                    handleQuestionId(currentQuestion.id) : null
                                }
                            </div>
                            <div className="s35 w400">
                                {currentQuestion !== undefined ?
                                    currentQuestion.question : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="platform-my-answers-edit-answers-answers-card-big-container">
                        <div className="platform-my-answers-edit-answers-answers-card-container">
                            {
                                currentQuestion !== undefined ? (
                                    currentQuestion.type === NewQuestionType.Radio ?
                                        <div className="horizontal_flex_group wrap width_100_percent height_35_percent margin_top_80 justify_content_center">
                                            {
                                                currentQuestion.answers?.map(answer => (
                                                    <RadioAnswerCard
                                                        key={answer.text}
                                                        answer={answer}
                                                        isChecked={true}
                                                        onClick={() => onRadioCardClick(answer.text)}
                                                        isNowInput={false}
                                                        onRadioInputChangeHandler={() => console.log()}
                                                    />
                                                ))
                                            }
                                        </div> : null
                                ) : null
                            }
                            {
                                currentQuestion !== undefined ? (
                                    currentQuestion.type === NewQuestionType.Input ?
                                        <div className="vertical_flex_group align_items_center width_100_percent margin_top_80">
                                            {
                                                currentQuestion.answers?.map(answer => (
                                                    <InputAnswerCard
                                                        key={answer.text}
                                                        index={index}
                                                        onChange={(e) => onInputChange(e)}
                                                        inputAnswers={[]}
                                                        icon={answer.icon}
                                                        placeholder={""}                                                    />))
                                            }
                                        </div> : null
                                ) : null
                            }
                            {
                                currentQuestion !== undefined ? (
                                    currentQuestion.type === NewQuestionType.Multiselect ?
                                        <div className="horizontal_flex_group wrap width_100_percent height_35_percent margin_top_80 justify_content_center">
                                            {
                                                currentQuestion.answers?.map(answerElement => (
                                                    <MultiSelectAnswerCard
                                                        key={answerElement.text}
                                                        answer={answerElement}
                                                        isChecked={multiSelectAnswers.includes(answerElement.text)}
                                                        isNowInput={false}
                                                        onMultiSelectInputChangeHandler={() => console.log()}
                                                        onClick={onMultiSelectCardClick}                                                    />
                                                ))
                                            }
                                        </div> : null
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="platform-my-answers-edit-answers-buttons-row">
                        <CloseButton
                            onClick={onCloseClick}
                            showBorder={true}
                            noFlexEnd={true}
                        />
                        <button
                            className={`width_15_percent height_55 primary_action_color_background no_border white_text uppercase br5 w700 s14 lh17 ${submitChangeAnswerButtonDisabled ? "" : "pointer"}`}
                            onClick={onConfirmClick}
                            disabled={submitChangeAnswerButtonDisabled}
                        >
                            Conferma
                        </button>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswersSurveyTypeEditSingleQuestion
