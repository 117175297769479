import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import { FormEvent, useState } from "react"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { BASE_URL } from "../../../utils/Constants"
import "./css/EmailRegistrationInsertCode.css"

const EmailRegistrationInsertCode = () => {

    const location = useLocation();
    const email = location.state.email
    const newsletterAccepted = location.state.newsletterAccepted
    const password = location.state.password
    const tempId = location.state.tempId
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const bundleType = location.state.bundleType

    const [code, setCode] = useState("")
    const [codeErrorText, setCodeErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        callConfirmCodeAPI();
    }

    async function callConfirmCodeAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/confirm`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                    code: code
                }
            )
        })
        setIsLoading(false)
        if (!response.ok) {
            setCodeErrorText("Codice non valido")
        } else {
            navigate(`/registration-success`, { state: { email, password, tempId, doesUserWantTheTest, bundleType } })
        }
    }

    const handleResendCode = (e: FormEvent) => {
        callResendCodeAPI();
    }

    async function callResendCodeAPI() {
        fetch(`${BASE_URL}/auth/resend-code`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                })
        })
    }

    const onBackButtonClick = () => {
        navigate(`/email-registration`, { state: { newsletterAccepted, tempId, doesUserWantTheTest, bundleType } })
    }

    return (
        <div className="App">
            <div className='email-registration-insert-code-buttons-row'>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className="vertical_flex_group justify_content_center height_80_percent">
                {
                    isLoading ? <LoadingSpinner /> :
                        <div className="vertical_flex_group white_background width_392">
                            <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20">Inserisci codice</div>
                            <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center width_328 self_center">
                                Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. <br></br>Inseriscilo qui sotto.
                            </div>
                            <form
                                className="vertical_flex_group margin_top_20 margin_bottom_10 width_328 self_center"
                                onSubmit={handleSubmit}
                            >
                                <div className="vertical_flex_group">
                                    <label className="s12 w600 uppercase">Codice di verifica</label>
                                    <div className="vertical_flex_group black_border_bottom align_items_end">
                                        <input
                                            className="email-registration-insert-code-input-field ls1"
                                            type="text"
                                            placeholder="000000"
                                            onChange={
                                                e => {
                                                    setCode(e.target.value);
                                                }
                                            }
                                        />
                                    </div>
                                    <label className="s12 w600 lh17 error_text margin_top_10 margin_bottom_20">{codeErrorText}</label>
                                </div>
                                <button
                                    className={`width_328 height_55 uppercase w700 s14 br5 blue_background no_border white_text center ${code.length === 6 ? "pointer" : ""}`}
                                    disabled={!(code.length === 6)}
                                >
                                    Conferma codice
                                </button>
                                <div className="width_328 self_center margin_top_10 margin_bottom_10">
                                    <button
                                        className="height_55 pointer uppercase w700 s12 br5 width_328 resend_code_button_background no_border"
                                        onClick={handleResendCode}
                                        type="button"
                                    >
                                        Invia nuovo codice
                                    </button>
                                </div>
                            </form>
                        </div>
                }
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'></div>
        </div>
    )
}

export default EmailRegistrationInsertCode
