import "./css/RewardPageWidget.css"

interface Props {
    onClick: () => void,
}

const RewardPageWidget: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className={`reward-page-widget-container`}>
            <div className={`reward-page-widget-left-element-container`}>
                <div className={`reward-page-widget-left-element-internal-container`}>
                    <div className="reward-page-widget-heading">Inizia il tuo percorso</div>
                    <div className="reward-page-widget-copy">
                        Inizia il tuo programma di nutrizione iper personalizzata costruito ad hoc per te, le tue esigenze, e il tuo DNA!
                    </div>
                    <button
                        className={`reward-page-widget-answer-button`}
                        onClick={onClick}
                    >
                        Acquista ora
                    </button>
                </div>
            </div>
            <div className={`reward-page-widget-right-element-container`}>
            </div>
        </div>
    )
}

export default RewardPageWidget
