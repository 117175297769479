import { Route, Routes } from 'react-router-dom';
import './App.css';
import AppleLogin from './components/fake/AppleLogin';
import ForgotEmail from './pages/loginFlow/forgotPassword/ForgotEmail';
import ForgotPassword from './pages/loginFlow/forgotPassword/ForgotPassword';
import ForgotPasswordInsertCode from './pages/loginFlow/forgotPassword/ForgotPasswordInsertCode';
import GoogleLogin from './pages/loginFlow/login/GoogleLogin';
import LoginPage from './pages/loginFlow/login/LoginPage';
import CreateAccount from './pages/loginFlow/registration/CreateAccount';

import 'typeface-roboto';
import DataProtectionScreen from './components/fake/DataProtectionScreen';
import NewsletterScreen from './components/fake/NewsletterScreen';
import ChangePasswordSuccess from './pages/loginFlow/forgotPassword/ChangePasswordSuccess';
import NewPassword from './pages/loginFlow/forgotPassword/NewPassword';
import LoginSuccess from './pages/loginFlow/login/LoginSuccess';
import SaveQuizQPage from './pages/loginFlow/login/SaveQuizQPage';
import EmailRegistration from './pages/loginFlow/registration/EmailRegistration';
import EmailRegistrationInsertCode from './pages/loginFlow/registration/EmailRegistrationInsertCode';
import RegistrationSuccess from './pages/loginFlow/registration/RegistrationSuccess';
import ChooseProduct from './pages/onboarding/ChooseProduct';
import InsertProductCode from './pages/onboarding/InsertProductCode';
import PharmaciesRegisterSwabCode from './pages/onboarding/PharmaciesRegisterSwabCode';
import ProductChoiceSuccess from './pages/onboarding/ProductChoiceSuccess';
import RedeemPage from './pages/onboarding/RedeemPage';
import RewardPage from './pages/onboarding/RewardPage';
import RewardPageNew from './pages/onboarding/RewardPageNew';
import RewardPageNewChooseProduct from './pages/onboarding/RewardPageNewChooseProduct';
import Checkout from './pages/onboarding/components/Checkout';
import Dashboard from './pages/platform/dashboard/Dashboard';
import Experts from './pages/platform/experts/Experts';
import ExamsAndTests from './pages/platform/menu/examsAndTests/ExamsAndTests';
import ExamsAndTestsCollecting from './pages/platform/menu/examsAndTests/ExamsAndTestsCollecting';
import ExamsAndTestsCollectingPacking from './pages/platform/menu/examsAndTests/ExamsAndTestsCollectingPacking';
import ExamsAndTestsCollectingRegisterSwabCode from './pages/platform/menu/examsAndTests/ExamsAndTestsCollectingRegisterSwabCode';
import ExamsAndTestsCollectingRequestPickup from './pages/platform/menu/examsAndTests/ExamsAndTestsCollectingRequestPickup';
import ExamsAndTestsCollectingTutorial from './pages/platform/menu/examsAndTests/ExamsAndTestsCollectingTutorial';
import ExamsAndTestsSpecificExamsPage from './pages/platform/menu/examsAndTests/ExamsAndTestsSpecificExamsPage';
import MyAnswers from './pages/platform/menu/myAnswers/MyAnswers';
import MyAnswersSurveyType from './pages/platform/menu/myAnswers/MyAnswersSurveyType';
import MyAnswersSurveyTypeEditSingleQuestion from './pages/platform/menu/myAnswers/MyAnswersSurveyTypeEditSingleQuestion';
import MyApointments from './pages/platform/menu/myApointments/MyApointments';
import MyPurchases from './pages/platform/menu/myPurchases/MyPurchases';
import MyPurchasesRedeem from './pages/platform/menu/myPurchases/MyPurchasesRedeem';
import MyPurchasesRedeemChoose from './pages/platform/menu/myPurchases/MyPurchasesRedeemChoose';
import PaymentMethod from './pages/platform/menu/paymentMethod/PaymentMethod';
import PaymentMethodAddNewPaymentMethod from './pages/platform/menu/paymentMethod/PaymentMethodAddNewPaymentMethod';
import PaymentMethodAddNewPaymentReturn from './pages/platform/menu/paymentMethod/PaymentMethodAddNewPaymentReturn';
import PersonalData from './pages/platform/menu/personalData/PersonalData';
import Plan from './pages/platform/plan/Plan';
import QuizQHomePage from './pages/surveys/quizQNew/QuizQHomePage';
import QuizQNew from './pages/surveys/quizQNew/QuizQNew';
import QuizQNewRewardPage from './pages/surveys/quizQNew/QuizQNewRewardPage';
import QuizQSuccess from './pages/surveys/quizQNew/QuizQSuccess';
import UnderTheAgePage from './pages/surveys/quizQNew/UnderTheAgePage';
import SurveyAHome from './pages/surveys/surveyANew/SurveyAHome';
import SurveyANew from './pages/surveys/surveyANew/SurveyANew';
import SurveyASuccess from './pages/surveys/surveyANew/SurveyASuccess';
import SurveyBHome from './pages/surveys/surveyBNew/SurveyBHome';
import SurveyBNew from './pages/surveys/surveyBNew/SurveyBNew';

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='quiz-q-new' element={<QuizQNew />} />
        <Route path='quiz-q-new/:bundleType' element={<QuizQNew />} />
        <Route path='quiz-q-new/reward-page' element={<QuizQNewRewardPage />} />
        <Route path='quiz-q-new/under-the-age-page' element={<UnderTheAgePage />} />
        <Route path='save-quiz-q-page' element={<SaveQuizQPage />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='create-account' element={<CreateAccount />} />
        <Route path='google-login' element={<GoogleLogin />} />
        <Route path='login/google' element={<GoogleLogin />} />
        <Route path='apple-login' element={<AppleLogin />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='forgot-email' element={<ForgotEmail />} />
        <Route path='forgot-password-insert-code' element={<ForgotPasswordInsertCode />} />
        <Route path='new-password' element={<NewPassword />} />
        <Route path='email-registration' element={<EmailRegistration />} />
        <Route path='email-registration-code' element={<EmailRegistrationInsertCode />} />
        <Route path='registration-success' element={<RegistrationSuccess />} />
        <Route path='login-success' element={<LoginSuccess />} />
        <Route path='change-password-success' element={<ChangePasswordSuccess />} />
        <Route path='data-protection-screen' element={<DataProtectionScreen />} />
        <Route path='newsletter-screen' element={<NewsletterScreen />} />
        <Route path='redeem-page' element={<RedeemPage />} />
        <Route path='insert-product-code' element={<InsertProductCode />} />
        <Route path='insert-product-code/pharmacies-swab-code-register' element={<PharmaciesRegisterSwabCode />} />
        <Route path='choose-product' element={<ChooseProduct />} />
        <Route path='product-choice-success' element={<ProductChoiceSuccess />} />
        <Route path='quiz-q-home' element={<QuizQHomePage />} />
        <Route path='reward-page' element={<RewardPage />} />
        <Route path='reward-page-new' element={<RewardPageNew />} />
        <Route path='reward-page-new/choose-product' element={<RewardPageNewChooseProduct />} />
        <Route path='reward-page-new/choose-product/checkout' element={<Checkout />} />
        <Route path='quiz-q-success' element={<QuizQSuccess />} />
        <Route path='platform' element={<Dashboard />} />
        <Route path='survey-a-home' element={<SurveyAHome />} />
        <Route path='survey-a-new' element={<SurveyANew />} />
        <Route path='survey-a-success' element={<SurveyASuccess />} />
        <Route path='survey-b-home' element={<SurveyBHome />} />
        <Route path='survey-b-new' element={<SurveyBNew />} />
        <Route path='platform/home' element={<Dashboard />} />
        <Route path='platform/plan' element={<Plan />} />
        <Route path='platform/experts' element={<Experts />} />
        <Route path='platform/exams-and-tests' element={<ExamsAndTests />} />
        <Route path='platform/exams-and-tests/:examType' element={<ExamsAndTestsSpecificExamsPage />} />
        <Route path='platform/exams-and-tests/collecting' element={<ExamsAndTestsCollecting />} />
        <Route path='platform/exams-and-tests/collecting/tutorial' element={<ExamsAndTestsCollectingTutorial />} />
        <Route path='platform/exams-and-tests/collecting/register-swab-code' element={<ExamsAndTestsCollectingRegisterSwabCode />} />
        <Route path='platform/exams-and-tests/collecting/packing' element={<ExamsAndTestsCollectingPacking />} />
        <Route path='platform/exams-and-tests/collecting/request-pickup' element={<ExamsAndTestsCollectingRequestPickup />} />
        <Route path='platform/personal-data' element={<PersonalData />} />
        <Route path='platform/my-answers' element={<MyAnswers />} />
        <Route path='platform/my-answers/:surveyType' element={<MyAnswersSurveyType />} />
        <Route path='platform/my-answers/:surveyType/edit-single-question' element={<MyAnswersSurveyTypeEditSingleQuestion />} />
        <Route path='platform/my-purchases' element={<MyPurchases />} />
        <Route path='platform/my-purchases/redeem' element={<MyPurchasesRedeem />} />
        <Route path='platform/my-purchases/redeem/choose' element={<MyPurchasesRedeemChoose />} />
        <Route path='platform/my-apointments' element={<MyApointments />} />
        <Route path='platform/payment-method' element={<PaymentMethod />} />
        <Route path='platform/payment-method/add-new-payment-method' element={<PaymentMethodAddNewPaymentMethod />} />
        <Route path='platform/payment-method/add-new-payment-method-return' element={<PaymentMethodAddNewPaymentReturn />} />
        {/* <Route index element={<TopPage />} /> */}
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </>
  );
};


export default App;
