import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import { FormEvent, LegacyRef, useRef, useState } from "react"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { BASE_URL } from "../../../utils/Constants"
import "./css/ForgotPassword.css"

const ForgotPassword = () => {

    const [emailNotFoundText, setEmailNotFoundText] = useState("")
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const isEmailAcceptable = (newEmail: string) => !(!newEmail.includes("@") || newEmail.endsWith("@"));

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailNotFoundText("")
    };

    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        callAPI()
    }

    async function callAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            setEmailNotFoundText("Indirizzo e-mail non trovato")
        } else {
            navigate(`/forgot-password-insert-code`, { state: { email } })
        }
    }

    const onBackButtonClick = () => {
        navigate("/login")
    }

    return (
        <div className="App relative">
            <div className={`forgot-password-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`vertical_flex_group justify_content_center height_80_percent ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <div className="vertical_flex_group white_background width_392">
                    <div className="s30 w700 margin_top_20 margin_bottom_20 lh24 self_center">Password dimenticata</div>
                    <div className="s18 w400 lh26 self_center center width_328 margin_top_30 margin_bottom_30">
                        Inserisci l'e-mail del tuo account e ti invieremo un codice nella casella e-mail per reimpostare la password
                    </div>
                    <form
                        className="vertical_flex_group margin_top_20 margin_bottom_10 width_328 self_center"
                        onSubmit={handleSubmit}
                    >
                        <div className="margin_bottom_20">
                            <label className="s12 w600 uppercase">Indirizzo e-mail</label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="forgot-password-input-field s16 w400"
                                    type="text"
                                    placeholder="mail@sconosciuta.com"
                                    onChange={
                                        e => {
                                            setEmail(e.target.value);
                                            inputChangeHandler(e)
                                        }
                                    }
                                />
                            </div>
                            <div className="s12 w600 lh17 error_text margin_top_10">
                                {emailNotFoundText}
                                {(isEmailAcceptable(email) || email == "") ? "" : "Inserisci un indirizzo email valido"}
                            </div>
                        </div>
                        <button
                            className={`width_328 height_55 uppercase w700 s14 br5 blue_background no_border white_text center margin_bottom_10 ${isEmailAcceptable(email) ? "pointer" : ""}`}
                            disabled={!isEmailAcceptable(email)}
                        >
                            Invia e-mail
                        </button>
                    </form>
                    <div className="self_center">
                        <button
                            className="pointer uppercase w700 s12 br5 width_328 height_55 uppercase pointer s14 w700 br5 margin_bottom_10 transparent_background no_border black_text"
                            onClick={() => { navigate("/forgot-email") }}
                        >
                            Hai dimenticato l'indirizzo e-mail?
                        </button>
                    </div>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default ForgotPassword
