import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { BASE_URL } from "../../../utils/Constants";
import "./css/EmailRegistration.css";

const EmailRegistration = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const tempId = location.state !== null ? location.state.tempId : undefined
    const doesUserWantTheTest = location.state !== null ? location.state.doesUserWantTheTest : undefined
    const bundleType = location.state !== null ? location.state.bundleType : undefined

    const [typePassword, setTypePassword] = useState('password');
    const [iconPassword, setIconPassword] = useState("/images/show-password-disabled.svg");
    const [typePasswordConfirm, setTypePasswordConfirm] = useState('password');
    const [iconPasswordConfirm, setIconPasswordConfirm] = useState("/images/show-password-disabled.svg");

    const [name, setName] = useState(location.state !== null ? location.state.name : '')
    const [familyName, setFamilyName] = useState(location.state !== null ? location.state.familyName : '')
    const [email, setEmail] = useState(location.state !== null ? location.state.email : '');
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] = useState(false);
    const [mailErrorText, setMailErrorText] = useState("")
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [passwordConfirmErrorText, setPasswordConfirmErrorText] = useState("")

    const isEmailAcceptable = (newEmail: string) => !(!newEmail.includes("@") || newEmail.endsWith("@"));

    const [newsletterAccepted, setNewsletterAccepted] = useState(location.state !== null ? location.state.newsletterAccepted : false)

    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
    // regex /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
    const symbols = [".", ",", ":", ";", "-", "_", "?", "!", "$", "€", "£", "&", "%", "/", "(", ")", "=", "'", "*", "\""]
    const lettersUppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

    const isPasswordAcceptable = (newPassword: string) => newPassword.length >= 8 && numbers.some(el => newPassword.includes(el)) && symbols.some(el => newPassword.includes(el)) && lettersUppercase.some(el => newPassword.includes(el))

    const emailInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEmailAlreadyRegistered(false)

        const nextEmail = e.target.value;

        if (isEmailAcceptable(email) && !isEmailAcceptable(nextEmail)) {
            if (iconPassword === "/images/show-password.svg") {
                setIconPassword("/images/show-password-disabled.svg")
            } else if (iconPassword === "/images/hide-password.svg") {
                setIconPassword("/images/hide-password-disabled.svg")
            }
            if (iconPasswordConfirm === "/images/show-password.svg") {
                setIconPasswordConfirm("/images/show-password-disabled.svg")
            } else if (iconPasswordConfirm === "/images/hide-password.svg") {
                setIconPasswordConfirm("/images/hide-password-disabled.svg")
            }
        } else if (!isEmailAcceptable(email) && isEmailAcceptable(nextEmail)) {
            if (password !== "") {
                if (iconPassword === "/images/show-password-disabled.svg") {
                    setIconPassword("/images/show-password.svg")
                } else if (iconPassword === "/images/hide-password-disabled.svg") {
                    setIconPassword("/images/hide-password.svg")
                }
            }
            if (passwordConfirm !== "") {
                if (iconPasswordConfirm === "/images/show-password-disabled.svg") {
                    setIconPasswordConfirm("/images/show-password.svg")
                } else if (iconPasswordConfirm === "/images/hide-password-disabled.svg") {
                    setIconPasswordConfirm("/images/hide-password.svg")
                }
            }
        }
    }

    const passwordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextPassword = e.target.value
        if (isEmailAcceptable(email)) {
            if (nextPassword !== '') {
                if (typePassword !== "text") {
                    setIconPassword("/images/show-password.svg")
                } else {
                    setIconPassword("/images/hide-password.svg")
                }
            } else {
                if (typePassword !== "text") {
                    setIconPassword("/images/show-password-disabled.svg")
                } else {
                    setIconPassword("/images/hide-password-disabled.svg")
                }
            }
        }
        if (isEmailAcceptable(email) && nextPassword !== "") {
            if (isPasswordAcceptable(nextPassword)) {
                setPasswordErrorText("")
            } else {
                setPasswordErrorText("La password non rispetta i criteri di validità")
            }
        }
    }

    const passwordConfirmInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextPasswordConfirm = e.target.value
        if (nextPasswordConfirm !== '') {
            if (typePasswordConfirm !== "text") {
                setIconPasswordConfirm("/images/show-password.svg")
            } else {
                setIconPasswordConfirm("/images/hide-password.svg")
            }
        } else {
            if (typePasswordConfirm !== "text") {
                setIconPasswordConfirm("/images/show-password-disabled.svg")
            } else {
                setIconPasswordConfirm("/images/hide-password-disabled.svg")
            }
        }
    }

    const handleTogglePassword = () => {
        if (password !== '' && isEmailAcceptable(email)) {
            if (typePassword === 'password') {
                setIconPassword("/images/hide-password.svg")
                setTypePassword('text')
            } else {
                setIconPassword("/images/show-password.svg")
                setTypePassword("password")
            }
        }
    }

    const handleTogglePasswordConfirm = () => {
        if (passwordConfirm !== '') {
            if (typePasswordConfirm === 'password') {
                setIconPasswordConfirm("/images/hide-password.svg")
                setTypePasswordConfirm('text')
            } else {
                setIconPasswordConfirm("/images/show-password.svg")
                setTypePasswordConfirm("password")
            }
        }
    }

    const handleEmailBlur = () => {
        if ((!email.includes("@") || email.endsWith("@")) && email != '') {
            setMailErrorText("Inserisci un indirizzo e-mail valido")
            // setIsEmailAlreadyRegistered(false)
            // } else if (email === "tommaso.marchi@holifya.co" || email === "@admin") {
            //     setMailErrorText("")
            //     setIsEmailAlreadyRegistered(true)
        } else {
            setMailErrorText("")
            // setIsEmailAlreadyRegistered(false)
        }
    }

    const handlePasswordConfirmBlur = () => {
        if (passwordConfirm !== password && passwordConfirm != "") {
            setPasswordConfirmErrorText("Le password non corrispondono")
        } else if (name === "" || familyName === "") {
            setPasswordConfirmErrorText("Inserisci nome e cognome")
        } else {
            setPasswordConfirmErrorText("")
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (name !== "" && familyName !== "") {
            callAPI();
        } else {
            alert("Some fields are missing!")
        }
    }

    async function callAPI() {
        const response = await fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    name: name,
                    family_name: familyName,
                    newsletter_policy: newsletterAccepted,
                })
        })
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            if (response.status === 422) {
                alert("form error")
            } else {
                setIsEmailAlreadyRegistered(true)
            }
        } else {
            navigate(`/email-registration-code`, { state: { email, newsletterAccepted, password, tempId, doesUserWantTheTest, bundleType } })
        }
    }

    const onBackButtonClick = () => {
        // navigate("/create-account", { state: { newsletterAccepted } })
        navigate("/login")
    }

    useEffect(() => {
    })

    return (
        <div className="App">
            <div className='email-registration-buttons-row'>
                {
                    tempId === undefined ? 
                    <BackButton onClick={onBackButtonClick} /> : null
                }
            </div>
            <div className="email-registration-central-element-external-container">
                <div className="email-registration-central-element-internal-container">
                    <div className="s30 w700 margin_bottom_20 lh24 self_center">
                        Crea il tuo account
                    </div>
                    <div className="w400 s14 center margin_top_20 margin_bottom_20 self_center ln22 width_328">
                        Creando un account, dichiari di aver letto e compreso le
                        <a
                            href={"/assets/personal-data-treatment.pdf"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={
                                {
                                    textDecoration: "underline",
                                    color: "black",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    marginLeft: "5px"
                                }}
                        >
                            Informazioni relative al trattamento dei dati personali
                        </a>.
                    </div>
                    <form className="width_328 self_center" onSubmit={handleSubmit}>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                            <label className="s12 w600 uppercase">
                                Nome
                            </label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="email-registration-input-field s16 w400"
                                    type="text"
                                    value={name}
                                    placeholder="Nome"
                                    onChange={
                                        e => {
                                            setName(e.target.value)
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                            <label className="s12 w600 uppercase">
                                Cognome
                            </label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="email-registration-input-field s16 w400"
                                    type="text"
                                    value={familyName}
                                    placeholder="Cognome"
                                    onChange={
                                        e => {
                                            setFamilyName(e.target.value)
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                            <label className="s12 w600 uppercase">
                                Indirizzo e-mail
                            </label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="email-registration-input-field s16 w400"
                                    type="text"
                                    value={email}
                                    placeholder="nome@esempio.com"
                                    onChange={
                                        e => {
                                            setEmail(e.target.value.toLowerCase());
                                            emailInputChangeHandler(e);
                                        }
                                    }
                                    onBlur={handleEmailBlur} />
                            </div>
                            {
                                isEmailAlreadyRegistered ?
                                    <div className="s12 w600 lh17 error_text margin_top_10">L'indirizzo e-mail è già in uso. <span onClick={() => { navigate("/login") }} className="underline_text pointer">
                                        Clicca qui per accedere.</span>
                                    </div> : <div></div>
                            }
                            <div className="s12 w600 lh17 error_text margin_top_10">{mailErrorText}</div>
                        </div>
                        <div className="s14 w600 lh22 margin_top_10 margin_bottom_20 width_328 blue_text">
                            Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                        </div>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_20">
                            <label className="s12 w600 uppercase">
                                Password
                            </label>
                            <div
                                className={`horizontal_flex_group align_items_end ${isEmailAcceptable(email) ? "black_border_bottom" : "light_gray_border_bottom"}`}
                            >
                                <input
                                    className={`ls1 email-registration-input-field s16 w400 ${isEmailAcceptable(email) ? "black_text" : "light_gray_text"}`}
                                    type={typePassword}
                                    placeholder="Password"
                                    onChange={
                                        e => {
                                            setPassword(e.target.value);
                                            passwordInputChangeHandler(e)
                                        }
                                    }
                                    disabled={!isEmailAcceptable(email)}
                                />
                                <span
                                    className={`lh0 self_center ${isEmailAcceptable(email) && password !== "" ? "pointer" : ""}`}
                                    onClick={handleTogglePassword}
                                >
                                    <img src={iconPassword} />
                                </span>
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">{passwordErrorText}</label>
                        </div>
                        <div className="vertical_flex_group margin_top_10 margin_bottom_20">
                            <label className="s12 w600 uppercase">
                                Conferma Password
                            </label>
                            <div
                                className={`horizontal_flex_group align_items_end margin_top_10 ${(isEmailAcceptable(email) && isPasswordAcceptable(password)) ? "black_border_bottom" : "light_gray_border_bottom"}`}
                            >
                                <input
                                    className={`ls1 email-registration-input-field s16 w400 ${(isEmailAcceptable(email) && isPasswordAcceptable(password)) ? "black_text" : "light_gray_text"}`}
                                    type={typePasswordConfirm}
                                    placeholder="Conferma password"
                                    onChange={
                                        e => {
                                            setPasswordConfirm(e.target.value);
                                            passwordConfirmInputChangeHandler(e)
                                        }
                                    }
                                    onBlur={handlePasswordConfirmBlur}
                                    disabled={!(isEmailAcceptable(email) && isPasswordAcceptable(password))}
                                />
                                <span
                                    className={`lh0 self_center ${isPasswordAcceptable(password) && passwordConfirm != "" ? "pointer" : ""}`}
                                    onClick={handleTogglePasswordConfirm}
                                >
                                    <img src={iconPasswordConfirm} />
                                </span>
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">{passwordConfirmErrorText}</label>
                        </div>
                        <div className="w400 s14 width_328 left horizontal_flex_group self_center margin_top_20 margin_bottom_20 align_items_start">
                            <input
                                className="pointer"
                                type="checkbox"
                                checked={newsletterAccepted}
                                onChange={() => { setNewsletterAccepted(!newsletterAccepted) }}
                            />
                            <div>
                                <div
                                    className="horizontal_flex_group justify_content_center align_items_center email-registration-checkbox-container"
                                    onClick={() => { setNewsletterAccepted(!newsletterAccepted) }}
                                >
                                    <div className={`email-registration-checkbox-internal-element ${newsletterAccepted ? "" : "invisible"}`}>

                                    </div>
                                </div>
                            </div>
                            <span className="margin_left_10">
                                Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                                <a
                                    href={"/assets/newsletter.pdf"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={
                                        {
                                            textDecoration: "underline",
                                            color: "black",
                                            fontWeight: "600",
                                            cursor: "pointer",
                                        }}
                                >
                                    Informativa relativa alla newsletter
                                </a>
                            </span>.
                        </div>
                        <button
                            className={`width_328 height_55 uppercase w700 s12 br5 blue_background no_border white_text margin_top_20 margin_bottom_20 center ${(isPasswordAcceptable(password) && password === passwordConfirm && name !== "" && familyName !== "" && !isEmailAlreadyRegistered) ? "pointer" : ""}`}
                            disabled={!(isPasswordAcceptable(password) && password === passwordConfirm && name !== "" && familyName !== "" && !isEmailAlreadyRegistered)}
                        >
                            Continua
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailRegistration
