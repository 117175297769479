interface Props {
    children?: React.ReactNode;
    image: string;
    onClick?: () => void;
}

const LogoButton: React.FC<Props> = ({
    children,
    image,
    onClick,
}) => {
    return (
        <button
            className='horizontal_flex_group align_items_center justify_content_space_between transparent_background s14 margin_top_20 w700 width_328 br5 pointer black_border height_55'
            onClick={onClick}
        >
            <div className="margin_10">
                <img src={image} />
            </div>
            <div>
                {children}
            </div>
            <div className="invisible margin_10">
                <img src={image} />
            </div>
        </button>
    )
}

export default LogoButton
