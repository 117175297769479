import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import "./css/ForgotEmail.css"

const ForgotEmail = () => {

    const navigate = useNavigate();

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    return (
        <div className="App">
            <div className='forgot-email-buttons-row'>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className="vertical_flex_group justify_content_center white_background width_392 height_80_percent">
                <div className="s30 w700 lh24 margin_top_20 margin_bottom_20 self_center">E-mail dimenticata</div>
                <div className="s18 lh26 width_328 center margin_top_20 margin_bottom_20 w400 self_center">
                    Se sei sicuro di avere un account attivo ma non ricordi la tua mail di accesso scrivici a <span className="blue_text">support@holifya.com</span>.
                </div>
                <div className="s18 lh26 width_328 center margin_top_20 margin_bottom_20 w400 self_center">
                    Il nostro team cercherà di aiutarti a recuperarla.
                </div>
                <div className="self_center">
                    <button className="width_328 height_55 pointer uppercase s14 w700 br5 blue_background white_text no_border margin_top_10 margin_bottom_10">
                        <a href="mailto:support@holifya.com" style={
                            {
                                textDecoration: "none",
                                color: "white"
                            }
                        }>Scrivici</a>
                    </button>
                </div>
                <div className="self_center">
                    <button
                        className="width_328 height_55 br5 uppercase pointer w700 s14 white_background black_border margin_top_10 margin_bottom_10"
                        onClick={() => { window.open("https://www.holifya.com/", "_self") }}
                    >
                        Vai alla homepage
                    </button>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'></div>
        </div>
    )
}

export default ForgotEmail
