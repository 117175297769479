import CloseButton from "./CloseButton"

interface Props {
    onInfoCloseClick: () => void,
    image?: string,
}

const SwabExampleDialog: React.FC<Props> = ({
    onInfoCloseClick,
    image,
}) => {
    return (
        <div className="swab-code-example-dialog gray_border drop_shadow white_background br20 absolute center-div">
            <div className="horizontal_flex_group justify_content_space_between align_items_center margin-top-7-percent">
                <div className="margin-left-7-percent w700 s20 lh40">Il codice campione</div>
                <div className="margin-right-7-percent">
                    <CloseButton
                        onClick={onInfoCloseClick}
                        showBorder={true}
                    />
                </div>
            </div>
            <div className={`width_86_percent height_40_percent ${image !== undefined ? "" : "dark-gray-background"} self_center margin-bottom-7-percent`}>
                {
                    image !== undefined ? <img src={image} /> : null
                }
            </div>
        </div>
    )
}

export default SwabExampleDialog
