import { useLocation, useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../components/HolifyaFullLogo"
import { eleonora } from "../../utils/Experts";
import { useEffect, useRef, useState } from "react";
import CloseButton from "../../components/CloseButton";
import { BASE_URL } from "../../utils/Constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./css/RewardPage.css"
import RewardPageBundleProduct from "./components/RewardPageBundleProduct";
import RewardPageSingleConsultancy from "./components/RewardPageSingleConsultancy";
import RewardPageSingleTest from "./components/RewardPageSingleTest";
import { ExpertRole } from "../../utils/enums/ExpertRole";
import Expert from "../../components/Expert";
import { handleExpertRole } from "../../utils/Functions";

const RewardPage = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const name = location.state.name
  const idToken = location.state.idToken
  const accessToken = location.state.accessToken
  const userId = location.state.userId

  const [showInfoBanner, setShowInfoBanner] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [goal, setGoal] = useState("")

  const onInfoClicked = () => {
    setShowInfoBanner(true)
  }

  const onInfoDismissClicked = () => {
    setShowInfoBanner(false)
  }

  const handleGoToInsertPurchaseCode = () => {
    navigate(`/insert-product-code`, { state: { quizAlreadyTaken: "true", name, idToken, accessToken, userId } })
  }

  useEffect(() => {
    callAPIUserEmr()
    callAPIGetMedicalUsersList(ExpertRole.Nutritionist)
  }, [])

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  // GET user emr by user_id
  async function callAPIUserEmr() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET)
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      setGoal(responseJson.data.avatar)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET medical user list
  async function callAPIGetMedicalUsersList(role: string) {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${role}`, requestOptionsGET)
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const nutritionist = responseJson[0]
      console.log(nutritionist)
      const newExpert: Expert = {
        id: nutritionist.id,
        position: "Dott.",
        name: nutritionist.data.name,
        familyName: nutritionist.data.family_name,
        role: handleExpertRole(nutritionist.role),
        skills: nutritionist.tags,
        summary: "",
        avatar: nutritionist.calendly_data.calendly_user.avatar_url,
      }
      console.log(newExpert)
      setNutritionist(newExpert)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  const [nutritionist, setNutritionist] = useState<Expert>()

  const onShowMoreClicked = () => {
    section2.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onGoToBundleClicked = () => {
    section1.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const section1 = useRef<null | HTMLDivElement>(null);
  const section2 = useRef<null | HTMLDivElement>(null);

  return (
    <div className="App Quiz justify_content_center relative">
      <div className={`reward-page-container ${(showInfoBanner || isLoading) ? "visible_30_percent inactive" : ""}`}>
        <div
          ref={section1}
          className="reward-page-section-1-container"
        >
          <div className="reward-page-section-1-title-block">
            <HolifyaFullLogo />
            <div className="w400 s25 lh35">
              <div className="center">
                Grazie per aver risposto al questionario, <span className="w700">{name}</span>! {String.fromCodePoint(0x1F64F)}
              </div>
              <div className="">
                Sulla base delle tue risposte questo è il percorso che fa per te!
              </div>
            </div>
          </div>
          <div className={`${(showInfoBanner || isLoading) ? "visible_30_percent inactive" : ""} reward-page-section-1-expert-container`}>
            <div className="margin_left_20 margin_bottom_20 w700 s20 lh20">
              Percorso di nutrizione personalizzata
            </div>
            <RewardPageBundleProduct
              expert={nutritionist !== undefined ? nutritionist : eleonora}
              goal={goal}
              onInfoClicked={onInfoClicked}
            />
          </div>
          <div className="reward-page-section-1-buttons-row">
            <button
              className="reward-page-show-more-button"
              onClick={onShowMoreClicked}
            >
              Mostra altro
            </button>
            <button
              className="reward-page-already-purchased-section-1-button"
              onClick={handleGoToInsertPurchaseCode}
            >
              Ho già un codice acquisto
            </button>
          </div>
        </div>
        <div
          ref={section2}
          className="reward-page-section-2-container"
        >
          <div className="reward-page-section-2-products-external-container">
            <div className="reward-page-section-2-products-internal-container">
              <div className="vertical_flex_group justify_content_space_between">
                <div className="margin_left_20 w700 s20 lh20 margin_bottom_20">
                  Solo il 1° Consulto Nutrizionista
                </div>
                <RewardPageSingleConsultancy
                  expert={nutritionist !== undefined ? nutritionist : eleonora}
                />
              </div>
              <div className="vertical_flex_group justify_content_space_between">
                <div className="margin_left_20 w700 s20 lh20 margin_bottom_20">
                  Solo il Test del DNA
                </div>
                <RewardPageSingleTest />
              </div>
            </div>
          </div>
          <div className="reward-page-section-2-buttons-row">
            <div className="vertical_flex_group justify_content_flex_end align_items_start height_100_percent">
              <div className="w400 s25 lh30 margin_left_20 margin_bottom_20">
                Li vuoi entrambi?
              </div>
              <button
                className="reward-page-go-to-bundle-button secondary_action_color_background"
                onClick={onGoToBundleClicked}
              >
                Percorso di nutrizione personalizzata
              </button>
            </div>
            <button
              className="reward-page-already-purchased-section-2-button"
              onClick={handleGoToInsertPurchaseCode}
            >
              Ho già un codice acquisto
            </button>
          </div>
        </div>
      </div>
      {
        showInfoBanner ?
          <div className="absolute width_80_percent height_60_percent gray_border drop_shadow white_background br20 vertical_flex_group justify_content_center align_items_center">
            <CloseButton
              onClick={onInfoDismissClicked}
              noFlexEnd={true}
            />
          </div> : null
      }
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default RewardPage
