import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import Survey from "../../../../components/Survey";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import { handleSurveyOrderPosition } from "../../../../utils/Functions";
import MenuPopup from "../../components/MenuPopup";
import MyAnswersSurveyCard from "./components/MyAnswersSurveyCard";
import "./css/MyAnswers.css";

const MyAnswers = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callAPIAllSurveysRecovery()
    }, [])

    // *******************************************************************************************************************

    // Surveys already answered by the user
    const [surveysCompleted, setSurveysCompleted] = useState<Survey[]>([])

    const [isSurveyAMissing, setIsSurveyAMissing] = useState(false)
    const [isSurveyBMissing, setIsSurveyBMissing] = useState(false)

    // GET all surveys survey by user_id
    async function callAPIAllSurveysRecovery() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/surveys`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const surveys: Survey[] = []
            for (const survey of responseJson) {
                surveys.push(
                    {
                        type: survey.type,
                        qAndAs: survey.data,
                        createdAt: survey.created_at,
                        orderPosition: handleSurveyOrderPosition(survey),
                    }
                )
            }
            surveys.sort((a: Survey, b: Survey) => a.orderPosition - b.orderPosition)
            setSurveysCompleted(surveys)
            if (surveys.length < 3) {
                setIsSurveyBMissing(true)
            }
            if (surveys.length < 2) {
                setIsSurveyAMissing(true)
            }
        } else if (response.status === 401) {
            navigate("/login")
            // call refreshToken
            // call again callAPISurveyRecovery
        } else {
            // console.log(responseQuizTaken)
        }
    }

    const onSurveyCardClick = (survey: Survey) => {
        navigate(`/platform/my-answers/${survey.type}`, { state: { productName, userId, idToken, accessToken, refreshToken, answerChanged: false } })
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => { navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => {
                                navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })
                                onMenuIconClick()
                            }}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-my-answers-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="platform-my-answers-title-row">
                        <HorizontalLogoText
                            image={"/images/my-answers-big-icon.svg"}
                            text="Le mie risposte"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="platform-my-answers-content-container">
                        <div className="platform-final-element-container">
                            <div className="w400 s18 lh24 margin_bottom_30">
                                Qui trovi tutte le risposte che hai fornito in fase di completamento dei questionari. Puoi modificarle ricercandole tra i vari questionari compilati nel tempo.
                            </div>
                            <div className="vertical_flex_group margin_bottom_30">
                                <div className="w700 s20 lh40 margin_bottom_10">Questionari Iniziali</div>
                                <div className="horizontal_flex_group justify_content_center wrap">
                                    {
                                        surveysCompleted.map(survey => (
                                            <MyAnswersSurveyCard
                                                key={survey.type}
                                                onCardClick={() => onSurveyCardClick(survey)}
                                                survey={survey.type}
                                                createdAt={survey.createdAt}
                                            />
                                        ))
                                    }
                                    {
                                        isSurveyAMissing ? 
                                        <div className="platform-my-answers-survey-placeholder"/> : null
                                    }
                                    {
                                        isSurveyBMissing ? 
                                        <div className="platform-my-answers-survey-placeholder"/> : null
                                    }
                                </div>
                            </div>
                            {/* <div className="vertical_flex_group">
                                <div className="w700 s20 lh40 margin_bottom_20">Questionari di check-up</div>
                                <div
                                    className="horizontal_flex_group justify_content_space_between align_items_center width_100_percent height_90 margin_bottom_20 br20 pointer drop_shadow gray_border white_background w600 s20 lh26"
                                >
                                    <div className="margin_left_20">Questionario check-up</div>
                                    <div className="margin_right_20">Una data a caso</div>
                                </div>
                                <div
                                    className="horizontal_flex_group justify_content_space_between align_items_center width_100_percent height_90 margin_bottom_20 br20 pointer drop_shadow gray_border white_background w600 s20 lh26"
                                >
                                    <div className="margin_left_20">Questionario check-up</div>
                                    <div className="margin_right_20">Una data a caso</div>
                                </div>
                                <div
                                    className="horizontal_flex_group justify_content_space_between align_items_center width_100_percent height_90 br20 pointer drop_shadow gray_border white_background w600 s20 lh26"
                                >
                                    <div className="margin_left_20">Questionario check-up</div>
                                    <div className="margin_right_20">Una data a caso</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswers
