import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import CollectingActionsWidget from "../../dashboard/widgets/CollectingActionsWidget";
import SwabPathWidget from "../../dashboard/widgets/SwabPathWidget";

const ExamsAndTestsCollecting = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Variables to display on the menu
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans for absolute elements
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // When the user clicks on the logos in the navbar
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
            setSwabCode(responseJson.swabcode)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [trackingCode, setTrackingCode] = useState("")
    const [orderId, setOrderId] = useState("")
    const [swabCode, setSwabCode] = useState<string | undefined>(undefined)

    const [dnaTestPurchased, setDnaPurchased] = useState(false)

    // When the user clicks on the button on the bottom element
    const onGoToNextStepClick = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                navigate(`/platform/exams-and-tests/collecting/tutorial`, { state: { userId, productName, accessToken, idToken, refreshToken } })
                break;
            case TrackingStatus.TestDone:
                navigate(`/platform/exams-and-tests/collecting/register-swab-code`, { state: { userId, productName, accessToken, idToken, refreshToken } })
                break;
            case TrackingStatus.SampleRegistered:
                navigate(`/platform/exams-and-tests/collecting/packing`, { state: { userId, productName, accessToken, idToken, refreshToken } })
                break;
            case TrackingStatus.PickupRequested:
                navigate(`/platform/exams-and-tests/collecting/request-pickup`, { state: { userId, productName, accessToken, idToken, refreshToken } })
                break;
        }
    }

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length > 0) {
                setDnaPurchased(true)
                setTrackingStatus(responseJson[0].tracking_status)
                setTrackingCode(responseJson[0].tracking_gone)
                setOrderId(responseJson[0].order_id)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-exams-and-tests-collecting-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="vertical_flex_group justify_content_space_between height_13_percent width_100_percent">
                        <BackButton
                            onClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                        />
                        <div className="horizontal_flex_group justify_content_space_between align_items_center width_89_percent">
                            <HorizontalLogoText
                                image={"/images/dna-test-big-icon.svg"}
                                text="Test del DNA"
                                isInOwnView={true}
                            />
                            {/* <div>
                                <button
                                    className="pointer"
                                    onClick={() => callUpdateTrackerAPI(TrackingStatus.Purchased)}
                                >
                                    Reset status
                                </button>
                                {trackingStatus === TrackingStatus.Purchased ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.Delivered)}
                                    >
                                        Set to delivered
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.PickupRequested ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.Departed)}
                                    >
                                        Set to departed
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.Departed ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.DeliveredToLaboratory)}
                                    >
                                        Set delivered to the lab
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.DeliveredToLaboratory ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.StartingToExtract)}
                                    >
                                        Set starting to extract
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.StartingToExtract ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.StartingAnalysis)}
                                    >
                                        Set starting analysis
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.StartingAnalysis ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.AnalysisDone)}
                                    >
                                        Set to done
                                    </button> : null
                                }
                                {trackingStatus === TrackingStatus.AnalysisDone ?
                                    <button
                                        className="pointer"
                                        onClick={() => callUpdateTrackerAPI(TrackingStatus.ReportGenerated)}
                                    >
                                        Set To final stage
                                    </button> : null
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="vertical-scroll-group-87 width_100_percent">
                        {
                            dnaTestPurchased ?
                                <div className="platform-final-element-container">
                                    <div className="height_100_percent">
                                        <div className="dna-test-collecting-copy">
                                            <div>Quando il tuo report genetico sarà pronto potrai visualizzarlo qui.</div>
                                            {(swabCode !== undefined && swabCode !== null && swabCode !== "") ?
                                                <div>Il tuo codice campione è: {swabCode}</div>
                                                : null
                                            }
                                        </div>
                                        {/* <div className="dna-test-collecting-elements-external-container">
                                <div className={`dna-test-collecting-elements-internal-container ${trackingStatus === TrackingStatus.Delivered ? "justify_content_space_between" : ""}`}>
                                    <SwabPath
                                        sampleState={trackingStatus}
                                        linkOnTitleTwo={onGoToNextStepClick}
                                    />
                                    {
                                        (trackingStatus === TrackingStatus.Delivered || trackingStatus === TrackingStatus.TestDone || trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested) ?
                                            <CollectingOverview
                                                sampleState={trackingStatus}
                                                onButtonClick={onGoToNextStepClick}
                                            /> : null
                                    }
                                </div>
                            </div> */}
                                        <SwabPathWidget
                                            trackingStatus={trackingStatus}
                                            trackingCode={trackingCode}
                                            onLinkClick={onGoToNextStepClick} />
                                        {
                                            (trackingStatus === TrackingStatus.Delivered || trackingStatus === TrackingStatus.TestDone || trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested) ?
                                                <div>
                                                    <div className="height_35"></div>
                                                    <CollectingActionsWidget
                                                        trackingStatus={trackingStatus}
                                                        onButtonClick={onGoToNextStepClick}
                                                    />
                                                </div> : null
                                        }
                                    </div>
                                </div> :
                                <div className="height_100_percent">
                                    <div className="dna-test-collecting-copy">
                                        <div>
                                            Per avere accesso a questa funzione, <a href="https://www.holifya.com/pages/test-dna" target="_blank">acquista un test del DNA</a>.
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollecting
