import { FormEvent, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../components/BackButton"
import HolifyaFullLogo from "../../components/HolifyaFullLogo"
import LoadingSpinner from "../../components/LoadingSpinner"
import ShopifyItem from "../../components/ShopifyItem"
import { BASE_URL, PHARMACIES_CONFIRMATION_NUMBER } from "../../utils/Constants"
import { ShopifyItemSku } from "../../utils/enums/ShopifyItemType"
import "./css/InsertProductCode.css"

const InsertProductCode = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const quizAlreadyTaken = location.state.quizAlreadyTaken
    const username = location.state.username
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const fromWhere = location.state.fromWhere
    const doesUserWantTheTest = location.state.doesUserWantTheTest

    // State for confirmation number and error message
    const [confirmationNumber, setConfirmationNumber] = useState("")
    const [codeErrorText, setCodeErrorText] = useState("")

    // Boolean to active or disactive the submit button
    const submitButtonDisabled = confirmationNumber.length < 6

    // When the user submits the form
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        callFindOrderWithConfermationCodeApi();
    }

    useEffect(() => {
    }, [])

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // The get function with the confirmation code, depending on the answer the behaviour is different
    async function callFindOrderWithConfermationCodeApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/find/${confirmationNumber}`, requestOptions)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const productsNumber = responseJson.items.length
            if (productsNumber > 1) {
                const shopifyItems: ShopifyItem[] = []
                for (const item of responseJson.items) {
                    shopifyItems.push(
                        {
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            type: item.type,
                            shopifyId: responseJson.shopify_id,
                            confirmationNumber: responseJson.confirmation_number
                        }
                    ) 
                }
                navigate(`/choose-product`, { state: { username, idToken, accessToken, userId, shopifyItems } })
            } else if (confirmationNumber === PHARMACIES_CONFIRMATION_NUMBER) {
                const item = responseJson.items[0]
                const shopifyItem: ShopifyItem = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    type: ShopifyItemSku.DnaTest,
                    shopifyId: responseJson.shopify_id,
                    confirmationNumber: responseJson.confirmation_number,
                }
                navigate("pharmacies-swab-code-register", { state: { name: username, idToken, accessToken, userId, shopifyItem } })
            } else {
                const item = responseJson.items[0]
                const shopifyItem: ShopifyItem = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    type: item.type,
                    shopifyId: responseJson.shopify_id,
                    confirmationNumber: responseJson.confirmation_number,
                }
                await callSaveOrderApi(shopifyItem);
            }
        } else if (response.status === 401) {
            setCodeErrorText("Errore di recupero dati (token)")
        } else if (response.status === 400) {
            setCodeErrorText("Ordine già riscattato")
        } else if (response.status === 404) {
            setCodeErrorText("Ordine non trovato")
        } else {
            setCodeErrorText("Errore recupero dati")
        }
    }

    // Saves the product 
    async function callSaveOrderApi(shopifyItem: ShopifyItem) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    shopify_id: shopifyItem.shopifyId,
                    confirmation_number: shopifyItem.confirmationNumber,
                    items: [
                        {
                            id: shopifyItem.id,
                            name: shopifyItem.name,
                            price: shopifyItem.price,
                            type: shopifyItem.type,
                        }
                    ],
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            navigate(`/product-choice-success`, { state: { redeemedProductName: shopifyItem.name, username, idToken, accessToken, userId } })
        }
    }

    const [isLoading, setIsLoading] = useState(false)

    const handleBackButton = () => {
        switch (fromWhere) {
            case "redeem-page":
                navigate("/redeem-page", { state: { username, idToken, accessToken, userId } })
                break
            case "reward-page":
                navigate("")
                break
            case "reward-page-new":
                navigate(`/reward-page-new`, { state: { name: username, idToken, accessToken, userId, doesUserWantTheTest } })
                break
            case "reward-page-new-choose-product":
                navigate(`/reward-page-new/choose-product`, { state: { quizAlreadyTaken: "true", name: username, idToken, accessToken, userId, doesUserWantTheTest } })
                break
            default:
                navigate("")
        }
    }

    return (
        <div className="App Quiz justify_content_space_between relative">
            <div className={`insert-product-code-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <BackButton
                    onClick={handleBackButton}
                />
                {/* Just for centering reasons */}
                <div
                    className="insert-product-code-logo-one-div"
                >
                    <HolifyaFullLogo />
                </div>
            </div>
            <div className={`insert-product-code-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <div className="insert-product-code-internal-container">
                    <div className="insert-product-code-logo-two-div">
                        <HolifyaFullLogo />
                    </div>
                    <div className="">
                        <img src="/images/check-mail.svg" />
                    </div>
                    <div className="center s25 lh35">
                        <span className="w700">Fantastico!</span><br></br>Controlla la tua e-mail.
                    </div>
                    <div className="insert-product-code-copy">
                        Al momento dell'acquisto ti abbiamo inviato una mail contenente un <span className="w700">codice acquisto</span>.
                        <br></br>Copialo e incollalo qui sotto per sbloccare la tua esperienza Holifya!
                    </div>
                    <form
                        className="vertical_flex_group margin_top_20 margin_bottom_10 width_328 self_center"
                        onSubmit={handleSubmit}
                    >
                        <div className="vertical_flex_group margin_bottom_10">
                            <label className="s12 w600 uppercase">Codice acquisto</label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="input_field ls1"
                                    type="text"
                                    placeholder="000000"
                                    onChange={
                                        e => {
                                            setConfirmationNumber(e.target.value);
                                        }
                                    }
                                />
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">{codeErrorText}</label>
                        </div>
                        <button
                            className={`width_328 height_55 uppercase w700 s14 br5 blue_background no_border white_text center margin_top_10 ${!submitButtonDisabled ? "pointer" : ""}`}
                            disabled={submitButtonDisabled}
                        >Conferma codice
                        </button>
                    </form>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default InsertProductCode
