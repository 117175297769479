import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo"
import LoadingSpinner from "../../../components/LoadingSpinner"
import QuestionAndAnswer from "../../../components/QuestionAndAnswer"
import { BASE_URL } from "../../../utils/Constants"
import { handleQuestionId, separateStrings } from "../../../utils/Functions"
import { answerAltro, surveyBQuestions } from "../../../utils/SurveyBQuestions"
import InputAnswerCard from "../components/InputAnswerCard"
import MultiSelectAnswerCard from "../components/MultiSelectAnswerCard"
import { NewQuestionType } from "../components/NewQuestion"
import RadioAnswerCard from "../components/RadioAnswerCard"
import "./css/SurveyBNew.css"

const SurveyBNew = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const userId = location.state.userId
  const productName = location.state.productName
  const accessToken = location.state.accessToken
  const idToken = location.state.idToken
  const refreshToken = location.state.refreshToken
  const name = location.state.name

  const [isLoading, setIsLoading] = useState(false)

  const [customAnswer, setCustomAnswer] = useState("")

  // Questions of this survey
  const questions = surveyBQuestions

  // Index of the current question to display
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  // Current question displayed on screen
  const currentQuestion = questions[currentQuestionIndex]

  // In a radio question, this represents the currrent answer
  const [radioAnswer, setRadioAnswer] = useState("")
  // In a multiselect question, this is the array of the selected answers
  const [multiselectAnswersSelected, setMultiselectAnswersSelected] = useState<string[]>([]);

  const [inputAnswersFilled, setInputAnswersFilled] = useState<string[]>([]);
  const [question29InputAnswers, setQuestion29InputAnswers] = useState([""])
  const [question30InputAnswers, setQuestion30InputAnswers] = useState([""])

  // When the user clicks on a radio answer card
  const onRadioCardClick = (choice: string) => {
    setRadioAnswer(choice)
    if (choice !== answerAltro) {
      setTimeout(() => {
        saveRadioAndRefresh(choice)
      }, 250)
    }
    console.log(savedAnswers)
  }

  const saveRadioAndRefresh = (radioChoice: string) => {
    saveRadioAnswer(radioChoice)
    refreshOrSendRadio(radioChoice)
  }

  const saveRadioAnswer = (choice: string) => {
    if (choice === answerAltro && customAnswer !== "") {
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(customAnswer)
      } else {
        savedAnswers[currentQuestionIndex] = customAnswer
      }
    } else {
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(choice)
      } else {
        savedAnswers[currentQuestionIndex] = choice
      }
    }
    setSavedAnswers(savedAnswers)
    setRadioAnswer("")
  }

  const refreshOrSendRadio = (choice: string) => {
    const nextIndex = currentQuestionIndex + 1
    setCurrentQuestionIndex(nextIndex)
  }

  // When clicking on a multiselect question card
  const onMultiSelectCardClick = (answer: string) => {
    const index = multiselectAnswersSelected.indexOf(answer);
    if (index > -1) {
      setMultiselectAnswersSelected([...multiselectAnswersSelected.slice(0, index), ...multiselectAnswersSelected.slice(index + 1)]);
    } else {
      setMultiselectAnswersSelected([...multiselectAnswersSelected, ...[answer]]);
    }
  }

  const onNewInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = e.target.value
    setCustomAnswer(newAnswer)
  }

  const [tempString, setTempString] = useState("")

  // When the user types in the input field
  const onInputCardChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (currentQuestion.id === 29) {
      question29InputAnswers[index] = e.target.value
      setQuestion29InputAnswers(question29InputAnswers)
    } else if (currentQuestion.id === 30) {
      question30InputAnswers[index] = e.target.value
      setQuestion30InputAnswers(question30InputAnswers)
    }
    setTempString(e.target.value)
  }

  // When the user clicks on back button
  const onBackButtonClick = () => {
    console.log(currentQuestionIndex)
    if (currentQuestionIndex === 0) {
      navigate("/platform", { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    } else {
      var previousIndex = currentQuestionIndex - 1
      const previousQuestion = questions[previousIndex]
      if (previousQuestion.type === NewQuestionType.Multiselect) {
        setMultiselectAnswersSelected(separateStrings(savedAnswers[previousIndex], "; "))
      } else if (previousQuestion.type === NewQuestionType.Radio) {
        setRadioAnswer(savedAnswers[previousIndex])
      }
      setCurrentQuestionIndex(previousIndex)
    }
  }

  // When the user clicks on confirm button
  const onConfirmButtonClick = () => {
    saveAnswer()
    refreshOrSend()
  }

  const swapAnswers = (oldAnswer: string, newAnswer: string, arr: string[]) => {
    const index = arr.indexOf(oldAnswer)
    arr[index] = newAnswer
  }

  // Save the answer of the current question on the array of the saved answers
  const saveAnswer = () => {
    if (currentQuestion.type === NewQuestionType.Multiselect) {

      if (multiselectAnswersSelected.includes(answerAltro) && customAnswer !== "") {
        swapAnswers(answerAltro, customAnswer, multiselectAnswersSelected)
      }

      // Inizia il processo di creazione di un'unica stringa
      const answersString = multiselectAnswersSelected.join("; ")
      // Processo di creazione di un'unica stringa finito

      // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(answersString)
      } else {
        savedAnswers[currentQuestionIndex] = answersString
      }
    } else if (currentQuestion.type === NewQuestionType.Input) {

      // Inizia il processo di creazione di un'unica stringa
      var answersString = ""
      switch (currentQuestion.id) {
        case 29:
          answersString = question29InputAnswers.join("; ")
          break;
        case 30:
          answersString = question30InputAnswers.join("; ")
          break;
        default:
          answersString = inputAnswersFilled.join("; ")
      }
      // Processo di creazione di un'unica stringa finito

      // Inserisco la stringa nell'array delle risposte, mettendo in coda oppure sostituendo
      if (savedAnswers.length === currentQuestionIndex) {
        savedAnswers.push(answersString)
      } else {
        savedAnswers[currentQuestionIndex] = answersString
      }

    } else if (currentQuestion.type === NewQuestionType.Radio) {
      if (radioAnswer === answerAltro && customAnswer !== "") {
        if (savedAnswers.length === currentQuestionIndex) {
          savedAnswers.push(customAnswer)
        } else {
          savedAnswers[currentQuestionIndex] = customAnswer
        }
      } else {
        if (savedAnswers.length === currentQuestionIndex) {
          savedAnswers.push(radioAnswer)
        } else {
          savedAnswers[currentQuestionIndex] = radioAnswer
        }
      }
    }
    setSavedAnswers(savedAnswers)
    setMultiselectAnswersSelected([])
    setInputAnswersFilled([])
    setRadioAnswer("")
  }

  const refreshOrSend = () => {
    if (isLastQuestion) {
      handleAnswers()
      callSaveSurveyApi()
    } else {
      const nextIndex = currentQuestionIndex + 1
      setCurrentQuestionIndex(nextIndex)
    }
  }

  // Array of question and answer, needed to save them all
  const questionsAndAnswers: QuestionAndAnswer[] = []

  const handleAnswers = () => {
    questions.forEach(q => {
      const index = questions.indexOf(q)
      if (savedAnswers[index] !== undefined && savedAnswers[index] !== "undefined") {
        questionsAndAnswers.push({ q: handleQuestionId(q.id) + q.question, a: [savedAnswers[index]] })
      }
    }
    )
  }

  async function callSaveSurveyApi() {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
      body: JSON.stringify(
        {
          type: "Habits",
          data: questionsAndAnswers,
        })
    })
    setIsLoading(false)
    if (response.ok) {
      navigate(`/platform`, { state: { idToken, accessToken, userId, productName, name } })
    } else {
      const responseJson = await response.json()
      console.log(responseJson)
    }
  }

  // Array of saved answers
  const [savedAnswers, setSavedAnswers] = useState<string[]>([])

  // Boolean checking if it's the last question of the survey
  const isLastQuestion = currentQuestionIndex === questions.length - 1

  // Boolean checking if the confirm button is disabled
  const confirmButtonDisabled = currentQuestion.type === NewQuestionType.Multiselect ? multiselectAnswersSelected.length === 0 : (currentQuestion.type === NewQuestionType.Radio ? radioAnswer === "" : false)

  function handleInputAnswers(index: number) {
    switch (index) {
      case 29:
        return question29InputAnswers
      case 30:
        return question30InputAnswers
      default:
        return [""]
    }
  }

  return (
    <div className="App Quiz relative">
      {
        currentQuestion.type !== NewQuestionType.BreakPage ?
          <div className={`survey-heading-container ${isLoading ? "visible_30_percent inactive" : ""}`}>
            <div className="survey-question-empty-space" />
            <div className="survey-bar-logo-container">
              <div className="survey-empty-bar">
                <div className={`survey-filled-bar ${currentQuestion.progressionBarWidth}`} />
              </div>
              <div className="quiz-q-new-holifya-full-logo">
                <HolifyaFullLogo />
              </div>
              <div className="quiz-q-new-holifya-partial-logo">
                <img src="/images/quizQ/holifya-spiral.svg" />
              </div>
            </div>
            <div className="survey-question-container">
              <div className="survey-question">
                {currentQuestion.question}
              </div>
              <div className="survey-question-title-two">
                {currentQuestion.comment}
              </div>
            </div>
          </div> : <div className="survey-b-new-buttons-row" />
      }
      <div className={`${isLoading ? "visible_30_percent inactive" : ""} ${currentQuestion.type !== NewQuestionType.BreakPage ? "survey-b-new-answers-container" : "survey-b-new-break-page-container"}`}>
        <div className={`${currentQuestion.type === NewQuestionType.BreakPage ? "survey-b-new-break-page-internal-container" : (currentQuestionIndex !== 33 ? "survey-b-new-answers-internal-container" : "survey-b-new-answers-internal-container-input")}`}>
          {
            currentQuestion.type === NewQuestionType.Radio ?
              currentQuestion.answers.map(answer => (
                <RadioAnswerCard
                  key={answer.text}
                  answer={answer}
                  isChecked={radioAnswer === answer.text}
                  onClick={onRadioCardClick}
                  isNowInput={answer.text === answerAltro}
                  onRadioInputChangeHandler={onNewInputChangeHandler}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.Multiselect ?
              currentQuestion.answers.map(answer => (
                <MultiSelectAnswerCard
                  key={answer.text}
                  answer={answer}
                  isChecked={multiselectAnswersSelected.includes(answer.text)}
                  onClick={onMultiSelectCardClick}
                  isNowInput={answer.text === answerAltro}
                  onMultiSelectInputChangeHandler={onNewInputChangeHandler}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.Input ?
              currentQuestion.answers.map(answer => (
                <InputAnswerCard
                  key={answer.text}
                  placeholder={answer.text}
                  index={currentQuestion.answers.indexOf(answer)}
                  onChange={onInputCardChange}
                  inputAnswers={handleInputAnswers(currentQuestion.id)}
                  icon={answer.icon}
                />
              )) : null
          }
          {
            currentQuestion.type === NewQuestionType.BreakPage ?
              <div className="survey-b-new-break-page-internal-element">
                <div className="">
                  <img src={currentQuestion.icon} />
                </div>
                <div className="vertical_flex_group align_items_center">
                  <div className="survey-b-break-page-section-number">
                    <span className="uppercase">Sezione {currentQuestion.comment}</span> <span className="s12 black_text">di 4</span>
                  </div>
                  <div className="survey-b-break-page-section-name">
                    {currentQuestion.question}
                  </div>
                </div>
              </div> : null
          }
        </div>
      </div>
      <div className={`survey-b-new-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
        <div className={`survey-b-new-internal-buttons-row`}>
          <div
            className="survey-b-new-back-button"
            onClick={onBackButtonClick}
          >
            <div className="survey-b-new-back-button-arrow">
              <img src="/images/back-arrow.svg" />
            </div>
            <div className="survey-b-new-back-button-text">
              Indietro
            </div>
          </div>
          <button
            className={`survey-b-new-confirm-button ${isLastQuestion ? "secondary_action_color_background" : "primary_action_color_background white_text"} ${confirmButtonDisabled ? "" : "pointer"}`}
            disabled={confirmButtonDisabled}
            onClick={onConfirmButtonClick}
          >
            {isLastQuestion ? "Concludi" : "Avanti"}
          </button>
        </div>
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default SurveyBNew
