import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Consultancy from "../../../components/Consultancy"
import Expert from "../../../components/Expert"
import HorizontalNavBar from "../../../components/HorizontalNavBar"
import LoadingSpinner from "../../../components/LoadingSpinner"
import NavBar from "../../../components/NavBar"
import UploadFileSuccessDialog from "../../../components/UploadFileSuccessDialog"
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO_FOCUS, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN, trackingStates } from "../../../utils/Constants"
import { eleonora, elvira } from "../../../utils/Experts"
import { handleDate, handleDaysDifference, handleEnergy, handleExpert, handleExtensionFromType, handleFolderFromChoice, handleHoursDifference, handleMinutesDifference, handleSleepQuality, handleWaterIntake } from "../../../utils/Functions"
import { AppointmentStatus } from "../../../utils/enums/AppointmentStatus"
import { ConsultancyStatus } from "../../../utils/enums/ConsultancyStatus"
import { ExamsAndTestNavigation } from "../../../utils/enums/ExamsAndTestNavigation"
import { ExpertRole } from "../../../utils/enums/ExpertRole"
import { Mood } from "../../../utils/enums/Mood"
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ"
import { ShopifyItemSku } from "../../../utils/enums/ShopifyItemType"
import TrackingStatus from "../../../utils/enums/TrackingStatus"
import { Appointment } from "../components/Appointment"
import MenuPopup from "../components/MenuPopup"
import MoodDialog from "../components/MoodDialog"
import "../css/Platform.css"
import UploadFileDialog from "../menu/examsAndTests/components/UploadFileDialog"
import ParameterWidget from "../plan/components/ParameterWidget"
import CheckupSurveyWidget from "../widgets/CheckupSurveyWidget"
import ExpertCommentsAndHintsWidget from "../widgets/ExpertCommentsAndHintsWidget"
import MoodWidget from "../widgets/MoodWidget"
import NextApointmentWidget from "../widgets/NextApointmentWidget"
import NextAppointmentWidget from "../widgets/NextAppointmentWidget"
import BookApointmentDialog from "./components/BookApointmentDialog"
import "./css/Dashboard.css"
import BookConsultancyWidget from "./widgets/BookConsultancyWidget"
import CollectingActionsWidget from "./widgets/CollectingActionsWidget"
import ConsultWidgetNoTest from "./widgets/ConsultWidgetNoTest"
import ConsultancyPathWidget from "./widgets/ConsultancyPathWidget"
import ExpertsWidget from "./widgets/ExpertsWidget"
import QuizQWidget from "./widgets/QuizQWidget"
import RewardPageWidget from "./widgets/RewardPageWidget"
import SurveyBWidget from "./widgets/SurveyBWidget"
import SwabPathWidget from "./widgets/SwabPathWidget"
import UpdateTrackingWidget from "./widgets/UpdateTrackingWidget"
import UploadExamWidget from "./widgets/UploadExamWidget"
import YourPlanWidget from "./widgets/YourPlanWidget"
import YourReportWidget from "./widgets/YourReportWidget"

const Dashboard = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callGetUserInfoApi() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
            callGetMedicalUserInfoApi(responseJson.email)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        callGetUserInfoApi()
        callGetTrackingByUserIdAPI()
        callGetUserEmrApi()
        callGetOrdersRetrievedByUserIdApi()
        callGetSpecialistApi()
        callGetSubscriptionsApi()
        callGetMostRecentPlanApi()
        setTimeout(() => {
            setIsLoading(false)
        }, 750)
    }, [])

    // *******************************************************************************************************************

    // This is the status of the tracking, determining the path
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>()
    // Tracking code to track the delivery of the test
    const [trackingCode, setTrackingCode] = useState("")

    const [orderId, setOrderId] = useState("")

    // This is the status of the consultancy
    const [consultancyStatus, setConsultancyStatus] = useState<ConsultancyStatus>(ConsultancyStatus.DNATest)

    // There two booleans, together, are in charge of displaying the survey B widget
    const [surveyBTaken, setSurveyBTaken] = useState(false)

    // This boolean opens a dialog useful to book the next apointment
    const [showBookApointmentDialog, setShowBookApointmentDialog] = useState(false)

    const [showUploadExamDialog, setShowUploadDialog] = useState(false)

    // Related to the mood dialog
    const [mood, setMood] = useState(Mood.None)
    const [moodLog, setMoodLog] = useState("")
    const [showMoodDialog, setShowMoodDialog] = useState(false)

    // Parameters retrieved from emr, to be displayed in the widgets
    const [weight, setWeight] = useState("")
    const [waterIntake, setWaterIntake] = useState("")
    const [energy, setEnergy] = useState("")
    const [waistLine, setWaistLine] = useState("")
    const [sleepQuality, setSleepQuality] = useState("")

    // The nutritionist coming from my medical user info
    const [nutritionist, setNutritionist] = useState<Expert>()
    const [medicoRefertante, setMedicoRefertante] = useState<Expert>()

    // Boolean saying if I have a booked apointment
    const [consultancyBooked, setConsultancyBooked] = useState(false)

    // The next consultancy and days remaining to it
    const [nextConsultancy, setNextConsultancy] = useState<Consultancy>()
    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [allAppointments, setAllAppointments] = useState<Appointment[]>([])

    // *******************************************************************************************************************
    // Upload file dialog

    // File uploaded from upload dialog
    const [uploadedFileName, setUploadedFileName] = useState("")
    const [uploadedFileSize, setUploadedFileSize] = useState("")
    const [uploadedFileExtension, setUploadedFileExtension] = useState("")
    const [uploadedFile, setUploadedFile] = useState<File>()

    // Custom filename for the uploaded file
    const [customFilename, setCustomFilename] = useState("")
    // Custom upload date
    const [customDate, setCustomDate] = useState("")

    // This boolean checks if a file has been uploaded
    const fileUploaded = uploadedFile !== undefined

    // Choice for the exam type, when uploading
    const [uploadDialogRadioChoice, setUploadDialogRadioChoice] = useState("")

    const onDismissUploadExamDialogClick = () => {
        setShowUploadDialog(false)
        setUploadDialogRadioChoice("")
    }

    // What happens when the file is uploaded
    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        const newName = files![0].name
        const newSize = files![0].size.toString()
        const newExtension = handleExtensionFromType(files![0].type)
        setUploadedFileName(newName)
        setUploadedFileSize(newSize)
        setUploadedFile(files![0])
        setUploadedFileExtension(newExtension)
    }

    // When the users removes the uploaded file from the dialog from the X button
    const onUploadedFileRemove = () => {
        setUploadedFile(undefined)
        setCustomFilename("")
        setCustomDate("")
    }

    // When the user clicks on undo from the upload dialog
    const onUndoUploadClick = () => {
        setShowUploadDialog(false)
        resetAnswers()
    }

    // When the user confirms the upload
    const onConfirmUploadButtonClick = () => {
        callGetUrlApi(handleFolderFromChoice(uploadDialogRadioChoice))
    }

    // Resets all the custom infos
    const resetAnswers = () => {
        setUploadedFile(undefined)
        setUploadDialogRadioChoice("")
        setCustomFilename("")
        setCustomDate("")
    }

    // Boolean handling the confirm upload button activation
    const uploadButtonDisabled = !(uploadDialogRadioChoice !== "" && customFilename !== "" && customDate !== "")

    // GET an url to call
    async function callGetUrlApi(type: string) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/exam-url/${type}/upload?filename=${customFilename}-${customDate}${uploadedFileExtension}`, requestOptionsGET)
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile!)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            // setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setShowUploadDialog(false)
                setShowUploadSuccessDialog(true)
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [showUploadSuccessDialog, setShowUploadSuccessDialog] = useState(false)

    // When the user dimisses the success dialog
    const onUploadSuccessDismissButtonClick = () => {
        setShowUploadSuccessDialog(false)
        resetAnswers()
    }

    // *******************************************************************************************************************

    // Actions to perform when clicking on collecting widget or swab path links
    const handleCollectingActionsWidgetButtonClick = () => {
        switch (trackingStatus) {
            case TrackingStatus.Delivered:
                return navigate(`/platform/exams-and-tests/collecting/tutorial`, { state: { userId, productName, accessToken, idToken, refreshToken } })
            case TrackingStatus.TestDone:
                return navigate(`/platform/exams-and-tests/collecting/register-swab-code`, { state: { userId, productName, accessToken, idToken, refreshToken } })
            case TrackingStatus.SampleRegistered:
                return navigate(`/platform/exams-and-tests/collecting/packing`, { state: { userId, productName, accessToken, idToken, refreshToken } })
            case TrackingStatus.PickupRequested:
                return navigate(`/platform/exams-and-tests/collecting/request-pickup`, { state: { userId, productName, accessToken, idToken, refreshToken } })
        }
    }

    // API for getting the tracking by the user id
    async function callGetTrackingByUserIdAPI() {
        setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseTracking.ok) {
            const responseTrackingJson = await responseTracking.json()
            if (responseTrackingJson.length !== 0) {
                setTrackingStatus(responseTrackingJson[0].tracking_status)
                setTrackingCode(responseTrackingJson[0].tracking_number_gone)
                setOrderId(responseTrackingJson[0].order_id)
            }
        } else if (responseTracking.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    // API for getting the tracking by the user id
    async function callUpdateTrackingByUserIdAPI() {
        setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    tracking_status: "delivered",
                })
        })
        setIsLoading(false)
        if (responseTracking.ok) {
            callGetTrackingByUserIdAPI()
        } else if (responseTracking.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    // GET User surveys taken by user_id
    async function callGetUserEmrApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            setQuizQdone(true)
            const responseJson = await response.json()
            var index = 0
            while (responseJson.surveys[index] !== undefined) {
                if (responseJson.surveys[index].type === "Habits") {
                    setSurveyBTaken(true)
                }
                index++
            }
            setWeight(responseJson.data.weight)
            setWaterIntake(handleWaterIntake(responseJson.data.water_intake))
            setEnergy(handleEnergy(responseJson.data.energy))
            setWaistLine(responseJson.data.waistline)
            setSleepQuality(handleSleepQuality(responseJson.data.sleep_quality))
            if (responseJson.mood !== null) {
                setMood(responseJson.mood.mood)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // UPDATE user emr by user_id
    async function callAPIUpdateUserEmr(moodLog: string) {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}/emr`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    mood_log: {
                        mood: mood,
                        log: moodLog,
                    }
                })
        })
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET User orders retrieved by user_id
    async function callGetOrdersRetrievedByUserIdApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length !== 0) {
                for (const order of responseJson) {
                    switch (order.items[0].type as ShopifyItemSku) {
                        case ShopifyItemSku.DnaTest:
                        case ShopifyItemSku.DnaTestOld: {
                            setUserHasTest(true)
                            break;
                        }
                        case ShopifyItemSku.ExpertConsult: {
                            setUserHasExpertConsult(true)
                            break;
                        }
                        case ShopifyItemSku.NutritionistConsult: {
                            setUserHasNutriConsult(true)
                            break;
                        }
                        case ShopifyItemSku.Membership:
                            break;
                    }
                    // if (order.items[0].type === ShopifyItemSku.ExpertConsult || order.items[0].type === ShopifyItemSku.NutritionistConsult) {
                    //     setUserHasConsult(true)
                    // } else if (order.items[0].type === ShopifyItemSku.DnaTest || order.items[0].type === ShopifyItemSku.DnaTestOld) {
                    //     setUserHasTest(true)
                    // }
                }
                setUserhasOrders(true)
            } else {
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User subscriptions by user_id
    async function callGetSubscriptionsApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.status === "active" || responseJson.status === "trial") {
                setUserhasSubs(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET nutritionist info by patient id
    async function callGetMedicalUserInfoApi(email: string) {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutritionist = await responseUserinfo.json()
            // const newExpert: Expert = {
            //     id: nutritionist.id,
            //     position: nutritionist.data.sex === "M" ? "Dott." : "Dott.ssa",
            //     name: nutritionist.data.name,
            //     familyName: nutritionist.data.family_name,
            //     role: handleExpertRole(nutritionist.role),
            //     skills: nutritionist.tags,
            //     summary: nutritionist.data.bio,
            //     avatar: nutritionist.calendly_data.calendly_user.avatar_url,
            //     schedulingUrl: nutritionist.calendly_data.calendly_user.scheduling_url,
            // }
            const newExpert = handleExpert(nutritionist)
            setNutritionist(newExpert)
            callAPIRetrieveBookingInfo(email, newExpert)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET specialist info by patient id
    async function callGetSpecialistApi() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${ExpertRole.Specialist}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const medico = await responseUserinfo.json()
            const medicoRefertante = medico[0]
            // const newExpert: Expert = {
            //     id: medicoRefertante.id,
            //     position: medicoRefertante.data.sex === "M" ? "Dott." : "Dott.ssa",
            //     name: medicoRefertante.data.name,
            //     familyName: medicoRefertante.data.family_name,
            //     role: handleExpertRole(medicoRefertante.role),
            //     skills: medicoRefertante.tags,
            //     summary: medicoRefertante.data.bio,
            //     avatar: medicoRefertante.calendly_data.calendly_user.avatar_url,
            //     schedulingUrl: medicoRefertante.calendly_data.calendly_user.scheduling_url,
            // }
            const newExpert = handleExpert(medicoRefertante)
            setMedicoRefertante(newExpert)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET booking info by email
    async function callAPIRetrieveBookingInfo(email: string, nutritionist: Expert) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const appointmentsArray: Appointment[] = []
            for (const appointment of responseJson) {
                appointmentsArray.push({
                    date: appointment.payload.scheduled_event.start_time,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? medicoRefertante : nutritionist,
                    status: appointment.payload.status
                })
            }
            setAppointments(appointmentsArray.filter(appointment => appointment.status === AppointmentStatus.Active))
            setAllAppointments(appointmentsArray)
            setConsultancyBooked(true)
            setConsultancyStatus(ConsultancyStatus.FirstConsultancyBooked)
            const index = 0
            const newConsultancyDay = responseJson[index].payload.scheduled_event.start_time.substring(0, 10)
            const newConsultancy: Consultancy = {
                scehduledDate: newConsultancyDay,
                scheduledHour: (responseJson[index].payload.scheduled_event.start_time as String).substring(11, 13),
                scheduledMinutes: (responseJson[index].payload.scheduled_event.start_time as String).substring(14, 16),
                connectUrl: responseJson[index].payload.scheduled_event.location.join_url,
                rescheduleUrl: responseJson[index].payload.reschedule_url,
                cancelUrl: responseJson[index].payload.cancel_url,
            }
            setNextConsultancy(newConsultancy)
        } else if (response.status === 401) {
            // call refreshToken
            navigate("/login")
        } else if (response.status === 404) {
            setConsultancyBooked(false)
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User plan by user_id
    async function callGetMostRecentPlanApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/user/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setPlanAvailable(true)
            callGetPlanPDFUrlApi(responseJson.id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User plan by user_id
    async function callGetPlanPDFUrlApi(planId: string) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/patient/${userId}/plan-url?filename=${planId}.pdf`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setCurrentPlanUrl(responseJson.url)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [userHasOrders, setUserhasOrders] = useState(false)
    const [userHasSubs, setUserhasSubs] = useState(false)
    const [userHasNutriConsult, setUserHasNutriConsult] = useState(false)
    const [userHasExpertConsult, setUserHasExpertConsult] = useState(false)
    const [userHasTest, setUserHasTest] = useState(false)

    const [quizQdone, setQuizQdone] = useState(false)

    const isUpdateTrackingButtonVisible = trackingStatus === TrackingStatus.Purchased

    const isTestTrackingVisible = trackingStatus !== undefined ? ((trackingStates.indexOf(trackingStatus) < trackingStates.indexOf(TrackingStatus.ReportGenerated) && userHasOrders) || (userHasOrders && userHasSubs && trackingStates.indexOf(trackingStatus) < trackingStates.indexOf(TrackingStatus.ReportGenerated))) : false
    const isAppointmentTrackingVisible = trackingStatus !== undefined ? ((trackingStates.indexOf(trackingStatus) >= trackingStates.indexOf(TrackingStatus.ReportGenerated) && (userHasSubs && userHasOrders))) : false
    const isAppointmentTrackingNoTestVisible = trackingStatus === undefined
    const isNextAppointmentWidgetVisible = appointments.length > 0 && appointments[appointments.length - 1].remainingMinutes >= -30
    const isBookAppointmentWidgetVisible = (!userHasOrders && userHasSubs && !consultancyBooked) || (trackingStates.indexOf(trackingStatus!!) >= trackingStates.indexOf(TrackingStatus.AnalysisDone) && !consultancyBooked && (userHasSubs || userHasNutriConsult)) || (!isNextAppointmentWidgetVisible && appointments.length > 0) || (userHasNutriConsult && appointments.length === 0)

    const isCollectingActionsWidgetVisible = trackingStatus === TrackingStatus.Delivered || trackingStatus === TrackingStatus.TestDone || trackingStatus === TrackingStatus.SampleRegistered || trackingStatus === TrackingStatus.PickupRequested
    const isQuizQWidgetVisible = !quizQdone
    const isRewardPageWidgetVisible = quizQdone && !userHasSubs
    const isSurveyBWidgetVisible = (userHasSubs && !surveyBTaken && quizQdone) || (userHasNutriConsult && !surveyBTaken)

    const handleAppointmentTracking = () => {
        if (appointments.length === 0) {
            return ConsultancyStatus.DNATest
        } else if (appointments.length === 1 && appointments[0].remainingMinutes >= -30) {
            return ConsultancyStatus.FirstConsultancyBooked
        } else if (appointments.length >= 1 && appointments[0].remainingMinutes < -30 && !planAvailable) {
            return ConsultancyStatus.FirstConsultancyDone
        } else if (appointments.length >= 1 && appointments[0].remainingMinutes < -30 && planAvailable) {
            return ConsultancyStatus.CustomisedNutritionPlan
        } else if (appointments.length === 2 && appointments[0].remainingMinutes < -30) {
            return ConsultancyStatus.SecondConsultancy
        } else {
            return ConsultancyStatus.SecondConsultancy
        }
    }

    const [planAvailable, setPlanAvailable] = useState(false)
    const [currentPlanUrl, setCurrentPlanUrl] = useState("")

    const onPlanWidgetClick = () => {
        window.open(currentPlanUrl, "_blank")
    }

    // *******************************************************************************************************************

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO_FOCUS}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsLogoClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => { navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => { navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => { navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-dashboard-full-container ${(focusOnMenu || showBookApointmentDialog || showMoodDialog || showUploadExamDialog || showUploadSuccessDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="dashboard-title-row">
                        <div className="dashboard-title">
                            Ciao {name}! {String.fromCodePoint(0x1F642)}
                        </div>
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container">
                            {
                                isUpdateTrackingButtonVisible ?
                                    <UpdateTrackingWidget
                                        onClick={callUpdateTrackingByUserIdAPI}
                                    /> : null
                            }
                            {
                                isTestTrackingVisible ?
                                    <SwabPathWidget
                                        trackingStatus={trackingStatus!!}
                                        trackingCode={trackingCode}
                                        onLinkClick={handleCollectingActionsWidgetButtonClick}
                                    /> : null
                            }
                            {
                                isAppointmentTrackingVisible ?
                                    <ConsultancyPathWidget
                                        consultancyStatus={handleAppointmentTracking()}
                                    /> : null
                            }
                            {
                                // (userHasSubs && !userHasOrders) ?
                                isAppointmentTrackingNoTestVisible ?
                                    <ConsultWidgetNoTest
                                        onBookClick={() => setShowBookApointmentDialog(true)}
                                        consultancyStatus={handleAppointmentTracking()}
                                    /> : null
                            }
                            {
                                isBookAppointmentWidgetVisible ?
                                    <div>
                                        <div className="height_35"></div>
                                        <BookConsultancyWidget
                                            name={nutritionist !== undefined ? nutritionist.name : "il tuo nutrizionista"}
                                            onClick={() => setShowBookApointmentDialog(true)}
                                            isFirst={appointments.length === 0}
                                            lastAppointmentDate={appointments.length !== 0 ? handleDate(appointments[appointments.length - 1].date) : ""}
                                        />
                                    </div> : null
                            }
                            {
                                isNextAppointmentWidgetVisible ?
                                    appointments.map(appointment => (
                                        appointment.remainingMinutes >= -30 ?
                                        <div key={appointments.indexOf(appointment)}>
                                            <div className="height_35" />
                                            <NextAppointmentWidget
                                                appointment={appointment}
                                                isUrgent={appointment.remainingDays <= 1}
                                            />
                                        </div>
                                        :null
                                    ))
                                    : null
                            }
                            {
                                consultancyStatus === 2 ?
                                    <div>
                                        <div className="height_35"></div>
                                        <NextApointmentWidget
                                            nutrizionista={eleonora}
                                            isUrgent={false}
                                            onEditClick={() => console.log("modifica")}
                                            onConnectClick={() => window.open("http://www.google.com", "_blank")}
                                            date={nextConsultancy !== undefined ? `${nextConsultancy.scehduledDate}` : "22 mar"}
                                            time={nextConsultancy !== undefined ? `${nextConsultancy.scheduledHour} ":" ${nextConsultancy.scheduledMinutes}` : "22 mar"}
                                            remainingDays={8}
                                        />
                                        <BookConsultancyWidget
                                            name={eleonora.name}
                                            onClick={() => setShowBookApointmentDialog(true)}
                                            isFirst={false}
                                            lastAppointmentDate={appointments !== undefined ? appointments[appointments.length - 1].date : ""}
                                        />
                                    </div> : null
                            }
                            {
                                consultancyStatus === 3 ?
                                    <div>
                                        <div className="height_35"></div>
                                        <CheckupSurveyWidget
                                            onClick={() => console.log("")}
                                        />
                                        <div className="height_35" />
                                        <MoodWidget
                                            yourFeelingsChoice={mood}
                                            onClick={
                                                mood === "" ?
                                                    (choice: Mood) => {
                                                        setMood(choice)
                                                        setShowMoodDialog(true)
                                                    } :
                                                    (choice: Mood) => { console.log(choice) }}
                                        />
                                        <div className="height_35" />
                                        <div className="horizontal_flex_group justify_content_space_between">
                                            <ExpertCommentsAndHintsWidget
                                                title="5 cose importanti da tenere a mente"
                                                author="Dal tuo nutrizionista"
                                                content={["Lorem ipsum dolor sit amet", "Consectetur adipiscing elit", "Condimentum eu augue ", "Eu condimentum ", "Aliquam vel enim id ligula sollicitudin rutrum"]}
                                            />
                                            <ExpertCommentsAndHintsWidget
                                                title="Il commento del piano"
                                                author="Dal tuo nutrizionista"
                                                content={["Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum eu augue eu condimentum. Aliquam vel enim id ligula sollicitudin rutrum. Cras nec tortor hendrerit, lobortis nulla eget, porttitor elit. Cras ullamcorper mauris sit amet ex blandit mattis. Suspendisse pretium porttitor sapien, ac tempus odio tristique vitae. Ut dapibus risus nec lacus viverra, ac euismod turpis feugiat. Pellentesque quis finibus ligula, sit amet pellentesque justo."]}
                                            />
                                        </div>
                                        <div className="height_35" />
                                        <div className="horizontal_flex_group wrap">
                                            <ParameterWidget
                                                name="Peso"
                                                image="/images/weight-icon.svg"
                                                value={weight + " kg"}
                                            />
                                            <ParameterWidget
                                                name="Acqua"
                                                image="/images/water-icon.svg"
                                                value={waterIntake}
                                            />
                                            <ParameterWidget
                                                name="Energia"
                                                image="/images/energy-icon.svg"
                                                value={energy}
                                            />
                                            <ParameterWidget
                                                name="Girovita"
                                                image="/images/girovita-icon.svg"
                                                value={waistLine + " cm"}
                                            />
                                            <ParameterWidget
                                                name="Sonno"
                                                image="/images/pillow-icon.svg"
                                                value={sleepQuality}
                                            />
                                        </div>
                                    </div> : null
                            }
                            {
                                isCollectingActionsWidgetVisible ?
                                    <div>
                                        <div className="height_35"></div>
                                        <CollectingActionsWidget
                                            trackingStatus={trackingStatus}
                                            onButtonClick={handleCollectingActionsWidgetButtonClick}
                                        />
                                    </div> : null
                            }
                            {
                                isQuizQWidgetVisible ?
                                    <div>
                                        <div className="height_35"></div>
                                        <QuizQWidget
                                            onClick={() => navigate("/quiz-q-home", { state: { name, idToken, accessToken, userId, productName, fromPlatform: true, fromWhere: NavigationToQuizQ.PlatformDashboard } })}
                                        />
                                    </div> : null
                            }
                            {
                                isRewardPageWidgetVisible ?
                                    <div>
                                        <div className="height_35"></div>
                                        <RewardPageWidget
                                            onClick={() => navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest: false, fromPlatform: true } })}
                                        />
                                    </div> : null
                            }
                            {
                                isSurveyBWidgetVisible ?
                                    <div>
                                        <div className="height_35" />
                                        <SurveyBWidget
                                            onClick={() => navigate("/survey-b-new", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                                            hasBigPriority={(trackingStates.indexOf(trackingStatus!!) >= trackingStates.indexOf(TrackingStatus.DeliveredToLaboratory) || (userHasSubs && !userHasOrders) || userHasNutriConsult)}
                                        />
                                    </div> :
                                    null
                            }
                            <div className="height_35" />
                            <div className={`${trackingStates.indexOf(trackingStatus!!) < trackingStates.indexOf(TrackingStatus.AnalysisDone) ? "dashboard-wrap-row-425" : "dashboard-wrap-row-924"}`}>
                                {trackingStates.indexOf(trackingStatus!!) >= trackingStates.indexOf(TrackingStatus.AnalysisDone) ? <YourReportWidget onClick={() => navigate(`/platform/exams-and-tests/${ExamsAndTestNavigation.Dna}`, { state: { userId, productName, accessToken, idToken, refreshToken } })} /> : null}
                                {
                                    planAvailable ?
                                        <YourPlanWidget
                                            onClick={onPlanWidgetClick}
                                        /> : null
                                }
                                <ExpertsWidget
                                    nutrionista={nutritionist}
                                    medicoRefertante={medicoRefertante !== undefined ? medicoRefertante : elvira}
                                />
                                <UploadExamWidget
                                    onUploadClick={() => setShowUploadDialog(true)}
                                />
                            </div>
                            <div className="height_35" />
                        </div>
                    </div>
                </div>
                {
                    showBookApointmentDialog ?
                        <BookApointmentDialog
                            onCloseClick={() => setShowBookApointmentDialog(false)}
                            email={email}
                            onBookClick={() => {
                                setShowBookApointmentDialog(false)
                                window.open(nutritionist?.schedulingUrl, "_blank")
                            }}
                        /> : null
                }
                {
                    showMoodDialog ?
                        <MoodDialog
                            mood={mood}
                            moodLog={moodLog}
                            onMoodLogChange={(e) => setMoodLog(e.target.value)}
                            onDismissClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }}
                            onDontSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }}
                            onSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr(moodLog)
                            }}
                        /> : null
                }
                {
                    showUploadExamDialog ?
                        <UploadFileDialog
                            fileUploaded={fileUploaded}
                            onDismissUploadDialogButtonClick={onDismissUploadExamDialogClick}
                            uploadDialogRadioChoice={uploadDialogRadioChoice}
                            setUploadDialogRadioChoice={setUploadDialogRadioChoice}
                            onFileInputChange={onFileInputChange}
                            uploadedFilename={uploadedFileName}
                            uploadedFileSize={uploadedFileSize}
                            uploadedFileExtension={uploadedFileExtension}
                            onUploadedFileRemove={onUploadedFileRemove}
                            onConfirmUploadButtonClick={onConfirmUploadButtonClick}
                            onUndoUploadClick={onUndoUploadClick}
                            uploadButtonDisabled={uploadButtonDisabled}
                            customFilename={customFilename}
                            setCustomFilename={(e) => setCustomFilename(e.target.value)}
                            customDate={customDate}
                            setCustomDate={(e) => setCustomDate(e.target.value)}
                        /> : null
                }
                {
                    showUploadSuccessDialog ?
                        <UploadFileSuccessDialog
                            onUploadSuccessDismissButtonClick={onUploadSuccessDismissButtonClick}
                            folder={uploadDialogRadioChoice}
                        />
                        : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO_FOCUS}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsLogoClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default Dashboard
