import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";

const SurveyASuccess = () => {

  const location = useLocation();
  const userId = location.state.userId;
  const idToken = location.state.idToken;
  const accessToken = location.state.accessToken;
  const productName = location.state.productName;
  const name = location.state.name;

  const navigate = useNavigate();

  useEffect(() => {
    {
        setTimeout(() => {
          navigate("/platform", { state: { name, idToken, accessToken, userId, productName } })}, 2000)
    }
}, [])

  return (
    <div className="App Quiz justify_content_center ">
      {/* <div className="s25 margin_bottom_20">Complimenti, hai compleatato il questionario A</div>
      <button
        className="margin_top_20 pointer width_328 height_55 br10 black_border s18 uppercase alert_color_background"
        onClick={() => navigate("/platform", { state: { name, idToken, accessToken, userId, productName } })}
      >
        Vai alla platform
      </button> */}
      <div className="vertical_flex_group white_background width_392 height_50_percent align_items_center justify_content_space_between">
        <div className="">
          <HolifyaFullLogo />
        </div>
        <div>
          <div className="s30 w700 lh38 self_center margin_top_50 margin_bottom_20 center">
            Perfetto!
          </div>
          <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">
            Stai per accedere alla tua area riservata!
          </div>
        </div>
        <div>
          <LoadingSpinner />
        </div>
      </div>
    </div>
  )
}

export default SurveyASuccess
