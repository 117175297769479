import { useLocation, useNavigate } from "react-router-dom"
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import AvatarIcon from "../../../components/AvatarIcon";
import "./css/SurveyBHome.css"

const SurveyBHome = () => {

    const location = useLocation();
    const idToken = location.state.idToken
    const userId = location.state.userId
    const productName = location.state.productName
    const name = location.state.name

    const navigate = useNavigate();

    const handleConfirm = () => {
        navigate(`/survey-b-new`, { state: { idToken, userId, productName, name } })
    }

    return (
        <div className="App Quiz justify_content_center">
            <div className="survey-b-home-external-container">
                <div className="survey-b-home-internal-container">
                    <div className="">
                        <HolifyaFullLogo />
                    </div>
                    <div className={`center`}>
                        <AvatarIcon />
                    </div>
                    <div className="survey-b-home-heading">
                        Iniziamo!
                    </div>
                    <div className="survey-b-home-copy">
                        Poche e rapide domande che aiuteranno i nostri professionisti a inquadrare al meglio la tua situazione e i tuoi obiettivi.
                    </div>
                    <button
                        className={`survey-b-home-confirm-button primary_action_color_background`}
                        onClick={handleConfirm}
                    >
                        Rispondi subito
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SurveyBHome
