import "./css/RewardPageSingleTest.css"

const RewardPageSingleTest = () => {
    return (
        <div className="reward-page-single-test-container">
            <div className="reward-page-single-test-internal-container">
                <div className="reward-page-single-test-copy">
                    Acquista il tuo report nutrigenetico completo. Predisposizioni genetiche e linee guida nutrizionali basate sul tuo DNA!
                </div>
                <div className="reward-page-single-test-image"/>
                <div className="reward-page-single-test-offers">
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Test del DNA (43 predisposizioni genetiche)</div>
                    </div>
                    <div className="horizontal_flex_group">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Linee guida genetiche</div>
                    </div>
                </div>
                <div className="reward-page-single-test-button-row">
                    <div className="margin_right_20 w400 s30 lh60">330€</div>
                    <button className="reward-page-purchase-button primary_action_color_background">Acquista</button>
                </div>
            </div>
        </div>
    )
}

export default RewardPageSingleTest
