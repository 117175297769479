import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import ExampleNumberAndLetters from "../../../../components/ExampleNumberAndLetters";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import SwabExampleDialog from "../../../../components/SwabExampleDialog";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";

const ExamsAndTestsCollectingRegisterSwabCode = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [orderId, setOrderId] = useState("")

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setTrackingStatus(responseJson[0].tracking_status)
            setOrderId(responseJson[0].order_id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // UPDATE track item
    async function callUpdateTrackerAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.SampleRegistered,
                }
            )
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log(responseJson)
            callFindTrackerByUserIdAPI()
        } else {
            console.log(responseJson)
        }
    }

    // UPDATE user informations
    async function callUpdateUserInformationsAPI(swabCode: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            }, body: JSON.stringify(
                {
                    swabcode: swabCode,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            setSwabCodeErrorText("Inserisci un codice corretto e disponibile")
        } else {
            callUpdateTrackerAPI()
            navigate("/platform/exams-and-tests/collecting/packing", { state: { userId, productName, accessToken, idToken, refreshToken, name } })
        }
    }

    const [showSwabDialog, setShowDialog] = useState(false)

    const [swabCode, setSwabCode] = useState("")

    const [swabCodeErrorText, setSwabCodeErrorText] = useState("")

    const [arrowIcon, setArrowIcon] = useState("/images/down-arrow-blue.svg")

    const [showExampleNumbersAndLetter, setShowExampleNumbersAndLetter] = useState(false)

    const onArrowClick = () => {
        if (arrowIcon === "/images/down-arrow-blue.svg") {
            setArrowIcon("/images/up-arrow-blue.svg")
            setShowExampleNumbersAndLetter(true)
        } else {
            setArrowIcon("/images/down-arrow-blue.svg")
            setShowExampleNumbersAndLetter(false)
        }
    }

    const swabCodeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSwabCode = e.target.value
        setSwabCode(newSwabCode)
        setSwabCodeErrorText("")
    }

    const isRegisterButtonDisabled = swabCode.length < 6

    const onInfoLogoClick = () => {
        setShowDialog(true)
    }

    const onInfoCloseClick = () => {
        setShowDialog(false)
    }

    const onSwabCodeRegisterClick = () => {
        callUpdateUserInformationsAPI(swabCode)
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-exams-and-tests-collecting-swab-register-full-container ${(focusOnMenu || showSwabDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="dna-test-register-swab-code-title-button-row">
                        <div className="w700 s30 lh40">
                            Registra il campione
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { userId, productName, accessToken, idToken, refreshToken, name, sampleState: TrackingStatus.TestDone } })}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className={`platform-final-element-container ${showExampleNumbersAndLetter ? "mobile-scroll-100" : ""}`}>
                            <div className={`dna-test-register-swab-code-content-container`}>
                                <div className="dna-test-regster-swab-code-upper-elements-container">
                                    <div className="vertical_flex_group">
                                        <div className="horizontal_flex_group align_items_center w400 s18 lh24">
                                            <div className="">Inserisci il tuo codice campione </div>
                                            <div
                                                onClick={onInfoLogoClick}
                                                className="pointer margin_left_10 lh0"
                                            >
                                                <img src="/images/info-logo.svg" />
                                            </div>
                                        </div>
                                        <div className="italic_text s14 lh40">
                                            Il tuo codice è unico e permette l'anonimizzazione del tuo campione durante la fase di logistica e analisi.
                                        </div>
                                    </div>
                                    <div className="horizontal_flex_group align_items_center">
                                        <div className="w400 s16 lh24">Presta attenzione ai <span className="w700 underline_text primary_action_color_text">caratteri più facilmente fraintendibili</span></div>
                                        <div
                                            className="pointer margin_left_10 lh0"
                                            onClick={onArrowClick}
                                        >
                                            <img src={arrowIcon} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    showExampleNumbersAndLetter ?
                                        <div className="dna-test-register-swab-code-example-numbers-and-letters">
                                            <ExampleNumberAndLetters image={"/images/number-0.svg"} text={"0 (numero zero)"} />
                                            <ExampleNumberAndLetters image={"/images/letter-O.svg"} text={"O (o maiuscola)"} />
                                            <ExampleNumberAndLetters image={"/images/number-1.svg"} text={"1 (uno numero)"} />
                                            <ExampleNumberAndLetters image={"/images/letter-l.svg"} text={"l (elle minuscola)"} />
                                            <ExampleNumberAndLetters image={"/images/letter-I.svg"} text={"I (i maiuscola)"} />
                                        </div> : <div className="width_100_percent height_5_percent"></div>
                                }
                                <div className="vertical_flex_group">
                                    <label className="s12 w600 uppercase lh14">Codice campione</label>
                                    <div className="dna-test-register-swab-code-input-container">
                                        <input
                                            className="input_field ls1"
                                            type="text"
                                            placeholder="Codice campione"
                                            value={swabCode}
                                            onChange={(e) => swabCodeInputHandler(e)}
                                        />
                                    </div>
                                    <label className="s12 w600 lh17 error_text">{swabCodeErrorText}</label>
                                </div>
                            </div>
                            <div className="dna-test-register-swab-code-buttons-container">
                                <button
                                    className={`dna-test-register-swab-code-back-button transparent_background s12 w700`}
                                    onClick={() => navigate("/platform/exams-and-tests/collecting/tutorial", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                                    type="button"
                                >
                                    <img src="/images/back-arrow.svg" />
                                    <div className="no_decoration_text">
                                        Indietro
                                    </div>
                                </button>
                                <button
                                    className={`dna-test-register-swab-code-register-button primary_action_color_background w700 s14 lh17 ${isRegisterButtonDisabled ? "" : "pointer"}`}
                                    onClick={onSwabCodeRegisterClick}
                                    disabled={isRegisterButtonDisabled}
                                >
                                    Registra
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showSwabDialog ?
                        <SwabExampleDialog
                            onInfoCloseClick={onInfoCloseClick}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingRegisterSwabCode
