import "./css/InputAnswerCard.css"

interface Props {
    placeholder: string,
    index: number,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void,
    inputAnswers: string[],
    icon?: string,
    showEmailDisclaimer?: boolean,
}

const InputAnswerCard: React.FC<Props> = ({
    placeholder,
    index,
    onChange,
    inputAnswers,
    icon,
    showEmailDisclaimer,
}) => {
    return (
        <div className="input-answer-card-icon-card-container">
            {
                icon !== undefined ?
                    <div>
                        <img src={icon} />
                    </div>
                    : null
            }
            <div className="input-answer-card-card-text-container">
                <div className={`${(inputAnswers[index] === "" || inputAnswers[index] === undefined) ? "input-answer-card-container" : "input-answer-card-filled-container"}`}>
                    <div className="input-answer-card-internal-container">
                        <input
                            className={`input-answer-card-new-input-field ls1`}
                            type="text"
                            name="choice"
                            placeholder={placeholder}
                            onChange={(e) => onChange(e, index)}
                            value={inputAnswers[index]}
                            autoComplete="off"
                            autoFocus={index === 0 ? true : false}
                        />
                    </div>
                </div>
                {
                    showEmailDisclaimer ?
                        <div className="input-answer-card-email-text">
                            Compilando questo campo e procedendo nella definizione dei tuoi obiettivi dai il consenso ad utilizzare la tua mail a scopi informativi e di marketing da parte di Holifya.
                        </div> : null
                }
            </div>
        </div>
    )
}

export default InputAnswerCard
