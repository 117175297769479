import Expert from "../components/Expert";
import Survey from "../components/Survey";
import { BucketFolder } from "./enums/BucketFolder";
import { ExpertRole } from "./enums/ExpertRole";
import { KeyParameter } from "./enums/KeyParameter";
import { QuizType } from "./enums/QuizType";
import { ShopifyItemName } from "./enums/ShopifyItemType";
import { EnergyAnswer, SleepAnswer, StressAnswer, TrainingAnswer, WaterAnswer } from "./enums/SurveysAnswers";

export const separateStrings = (s: string, separator: string) => {
    const arr = s.split(separator)
    return arr;
}

export const handleDate = (date: string) => {
    const day = date.substring(8, 10)
    const month = date.substring(5, 7)
    const year = date.substring(0, 4)
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
}

export const handleExtensionFromType = (type: string) => {
    switch (type) {
        case "application/pdf":
            return ".pdf"
        case "image/jpg":
            return ".jpg"
        case "image/jpeg":
            return ".jpeg"
        default:
            return "";
    }
}

export const handleFolderFromChoice = (choice: string) => {
    switch (choice) {
        case "Esami del sangue":
            return BucketFolder.BloodExams
        case "Altri esami":
            return BucketFolder.OtherExams
        case "Test del DNA":
            return BucketFolder.DnaExams
        default:
            return ""
    }
}

export const surveyName = (survey: string | undefined) => {
    switch (survey) {
        case QuizType.QuizQ:
            return "Welcome survey";
        case QuizType.SurveyA:
            return "Anamnesi clinica";
        case QuizType.SurveyB:
            return "Anamnesi alimentare";
        default:
            return "Wrong survey type"
    }
}

export const handleQuestionId = (id: number) => {
    if (id >= 10) {
        return id + ". "
    } else {
        return "0" + id + ". "
    }
}

export const handleExpertRole = (role: string) => {
    switch (role) {
        case ExpertRole.Nutritionist:
            return "Nutrizionista"
        case ExpertRole.Specialist:
            return "Medico Refertante"
        default:
            return ""
    }
}

export const handleMonth = (monthNumber: string) => {
    switch (monthNumber) {
        case "01":
            return "gen"
        case "02":
            return "feb"
        case "03":
            return "mar"
        case "04":
            return "apr"
        case "05":
            return "mag"
        case "06":
            return "giu"
        case "07":
            return "lug"
        case "08":
            return "ago"
        case "09":
            return "set"
        case "10":
            return "ott"
        case "11":
            return "nov"
        case "02":
            return "dic"
        default:
            return "error"
    }
}

export const handleDaySpan = (start: string, end: string) => {
    const startYear = start.substring(0, 4)
    const startMonth = handleStringToNumber(start.substring(5, 7))
    const startDay = handleStringToNumber(start.substring(8, 10))
    const endYear = end.substring(0, 4)
    const endMonth = handleStringToNumber(end.substring(5, 7))
    const endDay = handleStringToNumber(end.substring(8, 10))
    const leepYears = ["2024", "2028", "2032", "2036", "2040", "2044", "2048"]
    const januaryDays = 31
    const februaryDays = leepYears.some(year => startYear === year) ? 29 : 28
    const marchDays = 31
    const aprilDays = 30
    const mayDays = 31
    const juneDays = 30
    const julyDays = 31
    const augustDays = 31
    const septemberDays = 30
    const octoberDays = 31
    const novemberDays = 30
    const decemberDays = 31
    const monthDays = [
        januaryDays,
        januaryDays + februaryDays,
        januaryDays + februaryDays + marchDays,
        januaryDays + februaryDays + marchDays + aprilDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays + novemberDays,
        januaryDays + februaryDays + marchDays + aprilDays + mayDays + juneDays + julyDays + augustDays + septemberDays + octoberDays + novemberDays + decemberDays,
    ]
    if (startYear === endYear) {
        const startDays = monthDays[startMonth - 2] + startDay
        const endDays = monthDays[endMonth - 2] + endDay
        return endDays - startDays
    } else {
        return 0
    }
}

const handleStringToNumber = (number: string) => {
    switch (number) {
        case "01":
            return 1
        case "02":
            return 2
        case "03":
            return 3
        case "04":
            return 4
        case "05":
            return 5
        case "06":
            return 6
        case "07":
            return 7
        case "08":
            return 8
        case "09":
            return 9
        case "10":
            return 10
        case "11":
            return 11
        case "12":
            return 12
        case "13":
            return 13
        case "14":
            return 14
        case "15":
            return 15
        case "16":
            return 16
        case "17":
            return 17
        case "18":
            return 18
        case "19":
            return 19
        case "20":
            return 20
        case "21":
            return 21
        case "22":
            return 22
        case "23":
            return 23
        case "24":
            return 24
        case "25":
            return 25
        case "26":
            return 26
        case "27":
            return 27
        case "28":
            return 28
        case "29":
            return 29
        case "30":
            return 30
        case "31":
            return 31
        default:
            return 0
    }
}

export const handleWaterIntake = (waterIntake: WaterAnswer) => {
    switch (waterIntake) {
        case WaterAnswer.LessOneLiter:
            return "< 1 lt/g"
        case WaterAnswer.OneLiterOneLiterHalf:
            return "1-1,5 lt/g"
        case WaterAnswer.OneLiterHalfTwoLiters:
            return "1,5-2 lt/g"
        case WaterAnswer.MoreTwoLiters:
            return "> 2 lt/g"
    }
}

export const handleEnergy = (energy: EnergyAnswer) => {
    switch (energy) {
        case EnergyAnswer.Active:
            return "Energico"
        case EnergyAnswer.Medium:
            return "A tratti scarico"
        case EnergyAnswer.Low:
            return "Scarico"
    }
}

export const handleSleepQuality = (sleepQuality: SleepAnswer) => {
    switch (sleepQuality) {
        case SleepAnswer.Great:
            return "Ottimo"
        case SleepAnswer.Good:
            return "Regolare"
        case SleepAnswer.NotGood:
            return "Non ottimale"
        case SleepAnswer.Bad:
            return "Scarso"
    }
}

export const handleStressLevel = (stressLevel: StressAnswer) => {
    switch (stressLevel) {
        case StressAnswer.Stressed:
            return "Stressato"
        case StressAnswer.Medium:
            return "A tratti stressato"
        case StressAnswer.NoStress:
            return "Sereno"
    }
}

export const handleTraining = (training: TrainingAnswer) => {
    switch (training) {
        case TrainingAnswer.Everyday:
            return "Ogni giorno"
        case TrainingAnswer.Frequently:
            return "3-4 / settimana"
        case TrainingAnswer.Sometimes:
            return "1-2 / settimana"
        case TrainingAnswer.Never:
            return "Raramente / mai"
    }
}

/**
 * Returns an Expert object to be used in the platform
 * 
 * @param expert coming from get expert API
 */
export const handleExpert = (expert: any) => {
    const newExpert: Expert = {
        id: expert.id,
        position: expert.data.sex === "M" ? "Dott." : "Dott.ssa",
        name: expert.data.name,
        familyName: expert.data.family_name,
        role: handleExpertRole(expert.role),
        skills: expert.tags,
        summary: expert.data.bio,
        avatar: expert.calendly_data.calendly_user.avatar_url,
        schedulingUrl: expert.calendly_data.calendly_user.scheduling_url,
    }
    return newExpert
}

/**
 * Function giving the days from now to the input date
 * 
 * @param date 
 * @returns number of days rounded
 */
export const handleDaysDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
    return differenceInDays
}

/**
 * Function giving the hours from now to the input date
 * 
 * @param date 
 * @returns number of hours rounded
 */
export const handleHoursDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600))
    return differenceInDays
}

/**
 * Function giving the minutes from now to the input date
 * 
 * @param date 
 * @returns number of minutes rounded
 */
export const handleMinutesDifference = (date: string) => {
    const today = new Date()
    const futureDate = new Date(date)
    const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 60))
    return differenceInDays
}

/**
 * Function giving the day of the month of the input date
 * 
 * @param date 
 * @returns the number of the day of month
 */
export const handleDayOfMonth = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getDate()
    return day
}

/**
 * Function giving a 3 letters string of the input date month
 * 
 * @param date 
 * @returns the string representing the month
 */
export const handleMonthString = (date: string) => {
    const realDate = new Date(date)
    const day = realDate.getMonth() + 1
    switch (day) {
        case 1:
            return "gen"
        case 2:
            return "feb"
        case 3:
            return "mar"
        case 4:
            return "apr"
        case 5:
            return "mag"
        case 6:
            return "giu"
        case 7:
            return "lug"
        case 8:
            return "ago"
        case 9:
            return "set"
        case 10:
            return "ott"
        case 11:
            return "nov"
        case 12:
            return "dic"
        default:
            return ""
    }
}

/**
 * Function giving the hour of the input date
 * 
 * @param date 
 * @returns string representing the hout of the input
 */
export const handleHourAndMinutes = (date: string) => {
    const realDate = new Date(date)
    const hour = realDate.getHours()
    const minutes = realDate.getMinutes()
    return `${hour < 10 ? `0${hour.toString()}` : hour}:${minutes < 10 ? `0${minutes.toString()}` : minutes}`
}

export const handleParamenterName = (parameter: KeyParameter) => {
    switch (parameter) {
        case KeyParameter.Water:
            return "Acqua"
        case KeyParameter.Weight:
            return "Peso"
        case KeyParameter.Energy:
            return "Livello di Energia"
        case KeyParameter.Sleep:
            return "Qualità del sonno"
        case KeyParameter.WaistLine:
            return "Circ. vita"
        case KeyParameter.HipsLine:
            return "Circ. fianchi"
        case KeyParameter.ArmCircunference:
            return "Circ. braccio"
        case KeyParameter.Training:
            return "Allenamento"
        case KeyParameter.StressLevel:
            return "Livello di stress"
    }
}

export const handleParamenterImage = (parameter: KeyParameter, energy: string, waterIntake: string, sleepQuality: string, stressLevel: string, trainingAnswer: string) => {
    switch (parameter) {
        case KeyParameter.Energy:
            return handleEnergyImage(energy)
        case KeyParameter.Sleep:
            return handleSleepImage(sleepQuality)
        case KeyParameter.WaistLine:
            return "/images/keyParameters/girovita-icon.svg"
        case KeyParameter.Water:
            return handleWaterImage(waterIntake)
        case KeyParameter.Weight:
            return "/images/keyParameters/weight-icon.svg"
        case KeyParameter.HipsLine:
            return "/images/keyParameters/hips-icon.svg"
        case KeyParameter.ArmCircunference:
            return "/images/keyParameters/arm-icon.svg"
        case KeyParameter.Training:
            return handleTrainingImage(trainingAnswer)
        case KeyParameter.StressLevel:
            return handleStressImage(stressLevel)
    }
}

const handleEnergyImage = (energyAnswer: string) => {
    switch (energyAnswer) {
        case EnergyAnswer.Active:
            return "/images/keyParameters/energy-icon-1.svg"
        case EnergyAnswer.Medium:
            return "/images/keyParameters/energy-icon-2.svg"
        case EnergyAnswer.Low:
            return "/images/keyParameters/energy-icon-3.svg"
        default:
            return ""
    }
}

const handleSleepImage = (sleepAnswer: string) => {
    switch (sleepAnswer) {
        case SleepAnswer.Great:
        case SleepAnswer.Good:
            return "/images/keyParameters/sleep-icon-1.svg"
        case SleepAnswer.NotGood:
        case SleepAnswer.Bad:
            return "/images/keyParameters/sleep-icon-2.svg"
        default:
            return ""
    }
}

const handleWaterImage = (waterAnswer: string) => {
    switch (waterAnswer) {
        case WaterAnswer.LessOneLiter:
            return "/images/keyParameters/water-icon-1.svg"
        case WaterAnswer.OneLiterOneLiterHalf:
            return "/images/keyParameters/water-icon-2.svg"
        case WaterAnswer.OneLiterHalfTwoLiters:
            return "/images/keyParameters/water-icon-3.svg"
        case WaterAnswer.MoreTwoLiters:
            return "/images/keyParameters/water-icon-4.svg"
        default:
            return ""
    }
}

const handleStressImage = (stressAnswer: string) => {
    switch (stressAnswer) {
        case StressAnswer.NoStress:
            return "/images/keyParameters/stress-icon-1.svg"
        case StressAnswer.Medium:
            return "/images/keyParameters/stress-icon-2.svg"
        case StressAnswer.Stressed:
            return "/images/keyParameters/stress-icon-3.svg"
        default:
            return ""
    }
}

const handleTrainingImage = (trainingAnswer: string) => {
    switch (trainingAnswer) {
        case TrainingAnswer.Everyday:
            return "/images/keyParameters/training-icon-1.svg"
        case TrainingAnswer.Frequently:
            return "/images/keyParameters/training-icon-2.svg"
        case TrainingAnswer.Sometimes:
            return "/images/keyParameters/training-icon-3.svg"
        case TrainingAnswer.Never:
            return "/images/keyParameters/training-icon-4.svg"
        default:
            return ""
    }
}

// export const handleParameterValue = (parameter: KeyParameter) => {
//     switch (parameter) {
//         case KeyParameter.Energy:
//             return energy
//         case KeyParameter.Sleep:
//             return sleep
//         case KeyParameter.WaistLine:
//             return waistline + " cm"
//         case KeyParameter.Water:
//             return waterIntake
//         case KeyParameter.Weight:
//             return weight + " kg"
//         case KeyParameter.HipsLine:
//             return ""
//         case KeyParameter.ArmCircunference:
//             return ""
//         case KeyParameter.Training:
//             return ""
//         case KeyParameter.StressLevel:
//             return ""
//     }
// }

export const handleSubscriptionType = (name: ShopifyItemName) => {
    switch (name) {
        case ShopifyItemName.OneMonthSub:
        case ShopifyItemName.OneMonthBundle:
            return "diet_subscription"
        case ShopifyItemName.ThreeMonthsSub:
        case ShopifyItemName.ThreeMonthsBundle:
            return "3_months_subscription"
        case ShopifyItemName.SixMonthsSub:
        case ShopifyItemName.SixMonthsBundle:
            return "6_months_subscription"
        default:
            return ""
    }
}

export const handleSurveyOrderPosition = (survey: Survey) => {
    switch (survey.type) {
        case QuizType.QuizQ:
            return 1
        case QuizType.SurveyA:
            return 2
        case QuizType.SurveyB:
            return 3
        default:
            return 0
    }
}