export enum ShopifyItemSku {
    ExpertConsult = "Expert-consult",
    NutritionistConsult = "Nutritionist-consult",
    DnaTest = "0171002",
    DnaTestOld = "0171003",
    Membership = "",
}

export enum ShopifyItemName {
    ExpertConsult = "Consulto con il Medico",
    NutritionistConsult = "Consulto con il Nutrizionista",
    DnaTestOld = "DNA Complete",
    DnaTest= "DNA Test",
    OneMonthSub = "Membership 1 mese",
    ThreeMonthsSub = "Membership 3 mesi",
    SixMonthsSub = "Membership 6 mesi",
    OneMonthBundle = "Membership 1 mese + DNA Test",
    ThreeMonthsBundle = "Membership 3 mesi + DNA Test",
    SixMonthsBundle = "Membership 6 mesi + DNA Test",
}