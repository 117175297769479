import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import "./css/QuizQNewRewardPage.css"

const QuizQNewRewardPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const name = location.state.name
    const familyName = location.state.familyName
    const email = location.state.email
    const tempId = location.state.tempId
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const bundleType = location.state.bundleType

    useEffect(() => {
        console.log(location.state)
        console.log(`quiz reward page: ${doesUserWantTheTest}`)
    }, [])

    return (
        <div className="App Quiz justify_content_center">
            <div className="quiz-q-new-reward-page-container">
                <div>
                    <img src="/images/quizQ/reward-page-icon.svg" />
                </div>
                <div className="quiz-q-new-reward-page-text-box">
                    <div className="quiz-q-new-reward-page-heading">
                        Scopri il tuo Nutrizionista esperto Holifya e il protocollo Holifya adatto a te
                    </div>
                    <div className="height_20"/>
                    <div className="quiz-q-new-reward-page-copy">
                        Hai già fatto tutto, ti basterà creare la tua password!
                    </div>
                </div>
                <button
                    onClick={() => navigate(`/email-registration`, { state: { newsletterAccepted: false, name, familyName, email, tempId, doesUserWantTheTest, bundleType } })}
                    className="quiz-q-new-reward-page-button primary_action_color_background"
                >
                    Completa la creazione del tuo account
                </button>
            </div>
        </div>
    )
}

export default QuizQNewRewardPage
