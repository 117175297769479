import Expert from "../../../components/Expert"
import RewardPageExpertCard from "./RewardPageExpertCard"
import "./css/RewardPageBundleProduct.css"

interface Props {
    expert: Expert,
    goal: string,
    onInfoClicked: () => void,
}

const RewardPageBundleProduct: React.FC<Props> = ({
    expert,
    goal,
    onInfoClicked,
}) => {
    return (
        <div className="reward-page-bundle-product-container">
            <div className="reward-page-bundle-product-offers-container">
                <div className="height_90_percent vertical_flex_group justify_content_space_between">
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Test del DNA (43 predisposizioni genetiche)</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Nutrizionista selezionato per te</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Definizione obiettivi</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Autodiagnosi</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Definizione preferenze alimentari</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Condividi i tuoi referti</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">1° appuntamento con il tuo Nutrizionista</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Piano alimentare</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Linee guida genetiche</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Personalizzazione su preferenze, obiettivi, autodiagnosi</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Appuntamento ricorrente (mensile) con il tuo Nutrizionista</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Monitoraggio parametri in piattaforma</div>
                    </div>
                    <div className="horizontal_flex_group margin_left_20">
                        <img src="/images/marked-icon.svg" />
                        <div className="margin_left_10 w400 s16 lh34">Aggiornamento piano alimentare (mensile)</div>
                    </div>
                </div>
            </div>
            <div className="reward-page-bundle-product-expert-area-container">
                <div className="reward-page-bundle-product-expert-area-internal-container">
                    <div className="w700 s16 lh38">
                        Il tuo Nutrizionista dedicato
                    </div>
                    <RewardPageExpertCard
                        expert={expert}
                    />
                    <div className="reward-page-bundle-product-goal-external-container">
                        <div className="margin_top_10 w700 s16 lh38">Il tuo protocollo</div>
                        <div className="reward-page-bundle-product-goal-internal-container">
                            <div className="margin_left_10">
                                <img src="images/dna-test-color-icon.svg" />
                            </div>
                            <div className="margin_left_10 w700 s15 lh20">{goal}</div>
                        </div>
                    </div>
                    <div className="reward-page-bundle-product-price-purchase-row">
                        <div className="horizontal_flex_group align_items_center height_55">
                            <div className="horizontal_flex_group justify_content_center w400 s30 lh60 margin_right_20">250€ + 59€/<span className="s15">mese</span></div>
                            <div
                                className="pointer lh0"
                                onClick={onInfoClicked}
                            >
                                <img src="/images/info-big-logo.svg" />
                            </div>
                        </div>
                        <button className="reward-page-purchase-button primary_action_color_background">
                            Acquista
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardPageBundleProduct
