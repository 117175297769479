import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";

const ExamsAndTestsCollectingTutorial = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
            setSwabCode(responseJson.swabcode)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // The swab code associated with the user
    const [swabCode, setSwabCode] = useState<string | undefined>(undefined)

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [orderId, setOrderId] = useState("")

    const section1 = useRef<null | HTMLDivElement>(null);
    const section2 = useRef<null | HTMLDivElement>(null);

    const onReadInstructionsClick = () => {
        section2.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const onWatchVideoTutorialClick = () => {
        section1.current?.scrollIntoView({ behavior: 'smooth' })
    }

    // When the user confirms the sample collection
    const onSampleCollectionClick = () => {
        callUpdateTrackerAPI()
        if (swabCode !== undefined && swabCode !== null && swabCode !== "") {
            navigate("/platform/exams-and-tests/collecting/packing", { state: { userId, productName, accessToken, idToken, refreshToken, name } })
        } else {
            navigate("/platform/exams-and-tests/collecting/register-swab-code", { state: { userId, productName, accessToken, idToken, refreshToken, name } })
        }
    }

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setTrackingStatus(responseJson[0].tracking_status)
            setOrderId(responseJson[0].order_id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // UPDATE track item
    async function callUpdateTrackerAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.TestDone,
                }
            )
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log("ok")
            console.log(responseJson)
        } else {
            console.log("error")
            console.log(responseJson)
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-exams-and-tests-collecting-tutorial-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="dna-test-tutorial-title-button-row">
                        <div className="w700 s30 lh40">
                            Effettua l'autoprelievo il test del DNA!
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { userId, productName, accessToken, idToken, refreshToken, name, sampleState: TrackingStatus.Purchased } })}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div ref={section1} className="platform-final-element-container">
                            <div className="dna-test-tutorial-section-1-copy-video-container">
                                <div className="vertical_flex_group justify_content_space_evenly height_25_percent width_100_percent">
                                    <div className="400 s18 lh24">
                                        Puoi seguire le istruzioni cartacee presenti nel kit che hai ricevuto, seguire il video tutorial di autoprelievo o leggerle qui sotto.
                                    </div>
                                    <div className="400 s20 lh24">
                                        Video tutorial: <span className="w700">Autoprelievo</span>
                                    </div>
                                </div>
                                <div className="width_100_percent height_75_percent black_background">
                                </div>
                            </div>
                            <div className="dna-test-tutorial-section-1-buttons-container">
                                <button
                                    className="dna-test-tutorial-section-1-button ternary_action_color_background white_text w700 s14 lh17 pointer"
                                    onClick={onReadInstructionsClick}
                                >
                                    Leggi le istruzioni testuali
                                </button>
                                <button
                                    className="dna-test-tutorial-section-1-button primary_action_color_background white_text w700 s14 lh17 pointer"
                                    onClick={onSampleCollectionClick}
                                >
                                    Autoprelievo effettuato! avanti
                                </button>
                            </div>

                        </div>
                        <div
                            ref={section2}
                            className="platform-final-element-container mobile-scroll-100"
                        >
                            <div className="vertical_flex_group justify_content_space_between height_80_percent width_100_percent">
                                <div className="w400 s18 lh24">
                                    Prima di effettuare il test assicurati che nei 60 minuti precedenti al prelievo tu non abbia: mangiato, masticato gomme, fumato, lavato I denti, bevuto alcun tipo di liquido eccetto acqua. Estrai lo swab dalla provetta tirando il tappo.
                                </div>
                                <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                    <img src="/images/tutorial-1.svg" height="140px" width="140px" />
                                    <div className="margin_left_20 w400 s18 lh24">
                                        <span className="w700 s30 lh24">1</span> Inserire lo swab nel cavo orale, porlo sulla lingua e inumidirlo di saliva.
                                    </div>
                                </div>
                                <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                    <img src="/images/tutorial-2.svg" height="140px" width="140px" />
                                    <div className="margin_left_20 w400 s18 lh24">
                                        <span className="w700 s30 lh24">2</span> Chiudere la bocca e tenere lo swab in posizione per 10 secondi.
                                    </div>
                                </div>
                                <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                    <img src="/images/tutorial-3.svg" height="140px" width="140px" />
                                    <div className="margin_left_20 w400 s18 lh24">
                                        <span className="w700 s30 lh24">3</span> Con l’indice e il medio, premere lo swab contro l’interno della guancia e strofinare energicamente 10 volte vicino alla parete gengivale.
                                    </div>
                                </div>
                                <div className="horizontal_flex_group align_items_center width_100_percent height_20_percent">
                                    <img src="/images/tutorial-4.svg" height="140px" width="140px" />
                                    <div className="margin_left_20 w400 s18 lh24">
                                        <span className="w700 s30 lh24">4</span> Ripetere il passaggio sul lato opposto del cavo orale.
                                    </div>
                                </div>
                            </div>
                            <div className="dna-test-tutorial-section-2-buttons-container">
                                <button
                                    className="dna-test-tutorial-section-1-button ternary_action_color_background white_text w700 s14 lh17 pointer"
                                    onClick={onWatchVideoTutorialClick}
                                >
                                    Guarda il video tutorial
                                </button>
                                <button
                                    className="dna-test-tutorial-section-1-button primary_action_color_background white_text w700 s14 lh17 pointer"
                                    onClick={onSampleCollectionClick}
                                >
                                    Autoprelievo effettuato! avanti
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingTutorial
