import MenuPopupCategory from "../../../components/MenuPopupCategory"
import "./css/MenuPopup.css"

interface Props {
    email: string,
    name: string,
    familyName: string,
    onPersonalDataClick: () => void,
    onExamsAndTestClick: () => void,
    onMyAnswersClick: () => void,
    onMyApointmentsClick: () => void,
    onPaymentMethodClick: () => void,
    onMyPurchasesClick: () => void,
    onFrequentQuestionsClick: () => void,
    onLogoutClick: () => void,
    onBackgroundClick: () => void,
}

const MenuPopup: React.FC<Props> = ({
    email,
    name,
    familyName,
    onPersonalDataClick,
    onExamsAndTestClick,
    onMyAnswersClick,
    onMyApointmentsClick,
    onPaymentMethodClick,
    onMyPurchasesClick,
    onFrequentQuestionsClick,
    onLogoutClick,
    onBackgroundClick,
}) => {
    return (
        <div className="menu-view absolute">
            <div className="menu-popup">
                <div className="vertical_flex_group vertical-scroll-group-100 justify_content_space_evenly margin-left-15-percent">
                    <div className="margin_top_60 margin_bottom_20">
                        <div className="w700 s30 lh20 margin_bottom_10 horizontal_flex_group justify_content_space_between align_items_center">
                            <div>{name} {familyName}</div>
                            <div
                                className="menu-popup-x-button"
                                onClick={onBackgroundClick}
                            >
                                <img src="/images/x.svg"/>
                            </div>
                        </div>
                        <div className="w400 s16 lh20 margin_top_10">{email}</div>
                    </div>
                    <div className="margin_bottom_20">
                        <div className="menu-popup-title-text">Menu</div>
                        <MenuPopupCategory image={"/images/personal-data-icon.svg"} text="I miei dati personali" onClick={onPersonalDataClick} />
                        <MenuPopupCategory image={"/images/exams-test-icon.svg"} text="Esami e test" onClick={onExamsAndTestClick} />
                        <MenuPopupCategory image={"/images/my-answers-icon.svg"} text="Le mie risposte" onClick={onMyAnswersClick} />
                        <MenuPopupCategory image={"/images/my-apointments-icon.svg"} text="I miei appuntamenti" onClick={onMyApointmentsClick} />
                        <MenuPopupCategory image={"/images/payment-method-icon.svg"} text="Metodo di pagamento" onClick={onPaymentMethodClick} />
                        <MenuPopupCategory image={"/images/purchases-icon.svg"} text="Acquisti" onClick={onMyPurchasesClick} />
                    </div>
                    <div className="margin_bottom_20">
                        <div className="menu-popup-title-text">Assistenza</div>
                        <MenuPopupCategory image={"/images/frequent-questions-icon.svg"} text="Domande frequenti" onClick={onFrequentQuestionsClick}/>
                        <MenuPopupCategory image={"/images/write-to-us-icon.svg"} text="Scrivici"/>
                    </div>
                    <div className="margin_bottom_20">
                        <div className="menu-popup-title-text">Note legali</div>
                        <MenuPopupCategory image={"/images/t&c-pp-icon.svg"} text="Termini e condizioni"/>
                        <MenuPopupCategory image={"/images/t&c-pp-icon.svg"} text="Privacy policy"/>
                    </div>
                    <div>
                        <MenuPopupCategory image={"/images/exit-icon.svg"} text="Esci" onClick={onLogoutClick} />
                    </div>
                </div>
            </div>
            <div
                className="menu-popup-blank-screen"
                onClick={onBackgroundClick}
            >
            </div>
        </div>
    )
}

export default MenuPopup
