import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { BASE_URL, PHARMACIES_CONFIRMATION_NUMBER } from "../../../utils/Constants";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import { NavigationToRewardPage } from "../../../utils/enums/NavigationToRewardPage";
import { QuizType } from "../../../utils/enums/QuizType";
import { ShopifyItemName, ShopifyItemSku } from "../../../utils/enums/ShopifyItemType";

const LoginSuccess = () => {

    const location = useLocation();
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const refreshToken = location.state.refreshToken
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const bundleType = location.state.bundleType

    const [getErrorText, setGetErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    async function callAPI() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptions)
        const responseQuizTaken = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.QuizQ}`, requestOptions)
        const responseSurveyATaken = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.SurveyA}`, requestOptions)
        const responseOrderRetrieved = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptions)
        const responseSubsRetrieved = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptions)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            const name = responseJson.data.name
            const swabcode = responseJson.swabcode
            const responseOrderRetrievedJson = await responseOrderRetrieved.json()
            // Se non ho ancora risposto al quiz q
            if (responseQuizTaken.status === 404) {
                // Se c'è almeno un prodotto salvato
                if (responseOrderRetrievedJson.length >= 1) {
                    const productName = responseOrderRetrievedJson[0].items[0].name
                    const productSku = responseOrderRetrievedJson[0].items[0].type
                    const confirmationNumber = responseOrderRetrievedJson[0].confirmation_number
                    // Se il prodotto salvato non è un test da farmacia o un test classico e se lo swabcode non è valorizzato si va a fare il quiz q, altrimenti si va alla platform
                    if (confirmationNumber !== PHARMACIES_CONFIRMATION_NUMBER && (swabcode === undefined || swabcode === "" || swabcode === null) && !(productSku === ShopifyItemSku.DnaTest && productName === ShopifyItemName.DnaTestOld) && productName && productSku !== ShopifyItemSku.DnaTestOld && productSku !== ShopifyItemSku.ExpertConsult) {
                        navigate(`/quiz-q-home`, { state: { username: name, idToken, accessToken, userId, productName, fromWhere: NavigationToQuizQ.Login } })
                    } else {
                        navigate(`/platform`, { state: { name, idToken, accessToken, userId, productName, refreshToken } })
                    }
                } else {
                    navigate(`/redeem-page`, { state: { username: name, idToken, accessToken, userId } })
                }
            } else if (responseQuizTaken.ok) {
                const responseSubsRetrievedJson = await responseSubsRetrieved.json()
                if (responseOrderRetrievedJson.length >= 1 || responseSubsRetrievedJson.status === "active" || responseSubsRetrievedJson.status === "trial") {
                    const productName = responseOrderRetrievedJson.length !== 0 ? responseOrderRetrievedJson[0].items[0].name : ""
                    const productSku = responseOrderRetrievedJson.length !== 0 ? responseOrderRetrievedJson[0].items[0].type : ""
                    if (((productSku === ShopifyItemSku.ExpertConsult || productSku === ShopifyItemSku.NutritionistConsult) || responseSubsRetrievedJson.status === "active" || responseSubsRetrievedJson.status === "trial") && !responseSurveyATaken.ok) {
                        if (productName === ShopifyItemName.NutritionistConsult) {
                            return navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, fromWhere: NavigationToRewardPage.Login } })
                        } else {
                            navigate("/survey-a-home", { state: { userId, idToken, accessToken, productName, name } })
                        }
                    } else {
                        navigate(`/platform`, { state: { name, idToken, accessToken, userId, productName, refreshToken } })
                    }
                } else {
                    // navigate(`/quiz-q-success`, { state: { isAfterPurchase: false, name, idToken, accessToken, userId, productName: "", doesUserWantTheTest, bundleType } })
                    return navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, fromWhere: NavigationToRewardPage.OnboardingNoPurchase } })
                }
            } else {
                console.log(responseQuizTaken)
            }
        } else {
            setGetErrorText("Errore di recupero dati")
        }
    }

    useEffect(() => {
        callAPI();
    }, [])

    const navigate = useNavigate();

    return (
        <div className="App justify_content_center">
            <div className="vertical_flex_group white_background width_392 margin_50">
                <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20 center">Accesso in corso</div>
                <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">Stai per essere reindirizzato alla tua Area Riservata</div>
                {
                    isLoading ?
                        <LoadingSpinner /> : null
                }
                {
                    getErrorText !== "" ?
                        <button
                            className="width_328 height_55 uppercase pointer w700 s14 br5 white_background black_border margin_top_10 self_center margin_bottom_10"
                            onClick={() => { navigate("/login") }}
                        >
                            Torna alla login page
                        </button> : null
                }
                <label className="s12 w600 lh17 error_text margin_top_10 center">{getErrorText}</label>
            </div>
        </div>
    )
}

export default LoginSuccess
