import { useLocation, useNavigate, useParams } from "react-router-dom"
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/Constants";

const RegistrationSuccess = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const email = location.state.email
    const password = location.state.password
    const tempId = location.state.tempId
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const bundleType = location.state.bundleType

    useEffect(() => {
        callLoginAPI()
    }, [])

    const [isLoading, setIsLoading] = useState(false)

    // LOGIN Api
    async function callLoginAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                    password: password,
                })
        })
        setIsLoading(false)
        if (!response.ok) {
        } else {
            // Se la chimata va a buon fine si va alla login success page
            const result = await response.json();
            const idToken = result.id_token
            const accessToken = result.access_token
            const userId = result.id
            const refreshToken = result.refresh_token
            console.log(tempId)
            if (tempId === undefined) {
                navigate(`/login-success`, { state: { idToken, accessToken, userId, refreshToken, doesUserWantTheTest } })
            } else {
                navigate(`/save-quiz-q-page`, { state: { idToken, accessToken, userId, refreshToken, tempId, doesUserWantTheTest, bundleType } })
            }
        }
    }

    return (
        <div className={`App justify_content_center ${isLoading ? "visible_30_percent inactive" : ""}`}>
            <div className="vertical_flex_group white_background width_392">
                <div className="s30 w700 lh38 self_center margin_top_20 margin_bottom_20 center">Account creato correttamente</div>
                <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">Stai per essere reindirizzato alla tua Area Personale</div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default RegistrationSuccess
