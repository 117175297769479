import Expert from "../../../components/Expert"
import { handleMonth } from "../../../utils/Functions"
import "./css/NextApointmentWidget.css"

interface Props {
  nutrizionista: Expert,
  isUrgent: boolean,
  onEditClick: () => void,
  onConnectClick: () => void,
  date: string,
  time: string,
  remainingDays: number,
}

const NextApointmentWidget: React.FC<Props> = ({
  nutrizionista,
  isUrgent,
  onEditClick,
  onConnectClick,
  date,
  time,
  remainingDays,
}) => {

  const handleCorrectHour = (currentHour: string) => {
    const currentHourNumber = parseInt(currentHour.substring(0, 2))
    const newHourNumber = currentHourNumber + 2
    const newHourString = newHourNumber.toString().length === 1 ? `0${newHourNumber.toString()}` : newHourNumber.toString().length
    const realHour = newHourString + currentHour.substring(2, currentHour.length)
    return realHour
  }

  return (
    <div className={`${!isUrgent ? "next-apointment-widget-container" : "next-apointment-widget-container-priority"}`}>
      <div className="next-apointment-widget-internal-container">
        <div>
          <div className="next-apointment-widget-heading">Videoconsulto | <span className={`${!isUrgent ? "experts-blue-text" : "red-text"}`}>{remainingDays === 1 ? `Manca 1 giorno` : `Mancano ${remainingDays} giorni`}</span></div>
          <div className={`next-apointment-widget-date`}>{date.substring(8, 10) + " " + handleMonth(date.substring(5, 7))} <span className="w400">{handleCorrectHour(time)}</span></div>
        </div>
        <div className="horizontal_flex_group justify_content_space_between align_items_end desktop">
          <div className="">
            <div className="next-apointment-widget-expert-position">{nutrizionista.position}</div>
            <div className="next-apointment-widget-expert-name">{nutrizionista.name} {nutrizionista.familyName}</div>
            <div className="next-apointment-widget-expert-role">{nutrizionista.role}</div>
          </div>
          <div className="next-apointment-widget-buttons-row">
            <button
              className={`next-apointment-widget-edit-button ${!isUrgent ? "pointer" : ""}`}
              disabled={isUrgent}
              onClick={onEditClick}
            >
              Modifica
            </button>
            <button
              className="next-apointment-widget-connect-button primary_action_color_background"
              onClick={onConnectClick}
            >
              Collegati
            </button>
          </div>
        </div>
        <div className="horizontal_flex_group justify_content_space_between align_items_end mobile-flex">
          <div className="vertical_flex_group">
            <div className="next-apointment-widget-expert-position">
              {nutrizionista.position}
            </div>
            <div className="next-apointment-widget-expert-name">
              {nutrizionista.name} {nutrizionista.familyName}
            </div>
          </div>
          <div className="next-apointment-widget-expert-role">
            {nutrizionista.role}
          </div>
        </div>
        <div className="mobile-flex justify_content_center">
          <div className="next-apointment-widget-buttons-group-mobile">
            <button
              className={`next-apointment-widget-button-mobile ternary_action_color_background`}
              disabled={isUrgent}
              onClick={onEditClick}
            >
                Modifica
              </button>
            <button
              className="next-apointment-widget-button-mobile primary_action_color_background"
              onClick={onConnectClick}
              >
                Collegati
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NextApointmentWidget
