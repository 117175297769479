import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/Constants";
import LoadingSpinner from "../../../components/LoadingSpinner";
import QuestionAndAnswer from "../../../components/QuestionAndAnswer";

const SaveQuizQPage = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const refreshToken = location.state.refreshToken
    const tempId = location.state.tempId
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const bundleType = location.state.bundleType

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        callGetTempQuizApi()
    }, [])

    async function callGetTempQuizApi() {
        setIsLoading(true)
        if (tempId !== "") {
            const responseTempQuiz = await fetch(`${BASE_URL}/api/pre-register-survey/${tempId}`, requestOptions)
            if (responseTempQuiz.ok) {
                const responseJson = await responseTempQuiz.json()
                const qAndA: QuestionAndAnswer = responseJson.data
                callSaveQuizQApi(qAndA)
            }
        }
    }

    async function callSaveQuizQApi(qAndAs: QuestionAndAnswer) {
        console.log(qAndAs)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    type: "Quiz",
                    data: qAndAs,
                })
        })
        if (response.ok) {
            navigate(`/login-success`, { state: { idToken, accessToken, userId, refreshToken, doesUserWantTheTest, bundleType } })
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    return (
        <div className="App justify_content_center relative">
            <div className={`vertical_flex_group white_background width_392 margin_50 ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20 center">Accesso in corso</div>
                <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center self_center">Stai per essere reindirizzato alla tua Area Riservata</div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default SaveQuizQPage
