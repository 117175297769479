import HolifyaProduct from "../components/HolifyaProduct";
import TrackingStatus from "./enums/TrackingStatus";

// export const BASE_URL = "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://ayvk7yg741.execute-api.eu-central-1.amazonaws.com";
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "pk_test_51OvcMNGSmREaeHuCNLRUpxY0PRR4TIuTINaLa8ygKp0bH2lpcazLgZ5FLy7XgVnCiETX4YS5cTGGeXyAtDy0Sxlr00qbGg5iYH";

export const PUBLIC_BUCKET = "frontendstack-frontenddeploymentstaticobjectbucket-pcr1urhast1r"

export const PHARMACIES_CONFIRMATION_NUMBER = "FARMA6794LH"

export const NUTRITIONIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto"
export const SPECIALIST_APPOINTMENT_LINK = "https://www.holifya.com/pages/consulto-nutrigenetico"

export const trackingStates = [
    TrackingStatus.Purchased,
    TrackingStatus.Delivered,
    TrackingStatus.TestDone,
    TrackingStatus.SampleRegistered,
    TrackingStatus.PickupRequested,
    TrackingStatus.Departed,
    TrackingStatus.DeliveredToLaboratory,
    TrackingStatus.StartingToExtract,
    TrackingStatus.StartingAnalysis,
    TrackingStatus.AnalysisDone,
    TrackingStatus.ReportGenerated,
]

export const holifyaDNATest: HolifyaProduct = {
    value: "test",
    name: "Test del DNA Holifya",
    bundleContents: [
        "1 test del DNA Holifya",
        "Accesso alla piattaforma Holifya con possibilità di acquisto consulti e abbonamenti",
    ],
    icon: "/images/product-choice-dna-test.svg",
}

export const holifyaNutritionistConsultancy: HolifyaProduct = {
    value: "nutritionist-consultancy",
    name: "Consulto Nutrizionista",
    bundleContents: [
        "1 consulto con specialista",
        "Accesso alla piattaforma Holifya",
    ],
    icon: "/images/product-choice-consult.svg",
}

export const holifyaSpecialistConsultancy: HolifyaProduct = {
    value: "spaceilist-consultancy",
    name: "Consulto Medico genetista",
    bundleContents: [
        "1 consulto con specialista",
        "Accesso alla piattaforma Holifya",
    ],
    icon: "/images/product-choice-consult.svg",
}

export const holifyaOneMonthSub: HolifyaProduct = {
    value: "one-month-sub",
    name: "Membership 1 mese",
    bundleContents: [
        "1 consulto con il tuo nutrizionista",
        "1 mese di piano nutrizionale personalizzato",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaOneMonthBundle: HolifyaProduct = {
    value: "one-month-bundle",
    name: "Membership 1 mese + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "1 consulto con il tuo nutrizionista",
        "1 mese di piano nutrizionale personalizzato",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const holifyaThreeMonthsSub: HolifyaProduct = {
    value: "three-months-sub",
    name: "Membership 3 mesi",
    bundleContents: [
        "3 consulti con il tuo nutrizionista",
        "3 mesi di piano nutrizionale personalizzato",
        "3 aggiornamenti del piano",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaThreeMonthsBundle: HolifyaProduct = {
    value: "three-months-bundle",
    name: "Membership 3 mesi + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "3 consulti con il tuo nutrizionista",
        "3 mesi di piano nutrizionale personalizzato (3 aggiornamenti)",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const holifyaSixMonthsSub: HolifyaProduct = {
    value: "six-months-sub",
    name: "Membership 6 mesi",
    bundleContents: [
        "6 consulti con il tuo nutrizionista",
        "6 mesi di piano nutrizionale personalizzato",
        "6 aggiornamenti del piano",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-test-sub-icon.svg",
}

export const holifyaSixMonthsBundle: HolifyaProduct = {
    value: "six-months-bundle",
    name: "Membership 6 mesi + DNA Test",
    bundleContents: [
        "1 test del DNA Holifya",
        "6 consulti con il tuo nutrizionista",
        "6 mesi di piano nutrizionale personalizzato (6 aggiornamenti)",
        "Accesso alla piattaforma Holifya ",
    ],
    icon: "/images/bundle-sub-test-icon.svg",
}

export const uploadedExamsCriticalNumber = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40]

export const HOME_LOGO = "/images/home-logo.svg"
export const HOME_LOGO_FOCUS = "/images/home-logo-focus.svg"
export const PLAN_LOGO = "/images/plan-logo.svg"
export const PLAN_LOGO_FOCUS = "/images/plan-logo-focus.svg"
export const EXPERTS_LOGO = "/images/experts-logo.svg"
export const EXPERTS_LOGO_FOCUS = "/images/experts-logo-focus.svg"
export const MENU_LOGO = "/images/menu-logo.svg"
export const MENU_LOGO_FOCUS = "/images/menu-logo-x.svg"

export const RELATIVE_PATH_TO_PLATFORM_HOME = "/platform/home"
export const RELATIVE_PATH_TO_PLATFORM = "/platform"
export const RELATIVE_PATH_TO_PLATFORM_PLAN = "/platform/plan"
export const RELATIVE_PATH_TO_PLATFORM_EXPERTS = "/platform/experts"
