import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Expert from "../../components/Expert";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BASE_URL } from "../../utils/Constants";
import { handleExpertRole } from "../../utils/Functions";
import { BundleType, CheckoutBundleType, CheckoutMode } from "../../utils/enums/Checkout";
import { ExpertRole } from "../../utils/enums/ExpertRole";
import { NavigationToRewardPage } from "../../utils/enums/NavigationToRewardPage";
import ExpertDetailsDialog from "../platform/experts/components/ExpertDetailsDialog";
import GoalInfoDialog from "./components/GoalInfoDialog";
import RewardPageExpertCard from "./components/RewardPageExpertCard";
import "./css/RewardPageNew.css";

const RewardPageNew = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const name = location.state.name
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const doesUserWantTheTest = location.state.doesUserWantTheTest
    const fromPlatform = location.state.fromPlatform
    const bundleType: BundleType = location.state.bundleType
    const fromWhere: NavigationToRewardPage = location.state.fromWhere

    const [isLoading, setIsLoading] = useState(false)
    const [goal, setGoal] = useState("")

    const [showInfoDialog, setShowInfoDialog] = useState(false)
    const [showExpertInfoDialog, setShowExpertInfoDialog] = useState(false)

    const onInfoButtonClicked = () => {
        setShowInfoDialog(true)
    }

    const onInfoDismissClicked = () => {
        setShowInfoDialog(false)
    }

    const onExpertInfoButtonClicked = () => {
        setShowExpertInfoDialog(true)
    }

    const onExpertInfoDismissClicked = () => {
        setShowExpertInfoDialog(false)
    }

    useEffect(() => {
        callAPIUserEmr()
        // callAPIGetMedicalUsersList(ExpertRole.Nutritionist)
        callGetMedicalUserInfoApi()
        // TODO callAPIGetMedicalUsersList(ExpertRole.Specialist)
        callAGetOrdersApi()
    }, [])

    const [ordersAvailable, setOrdersAvailable] = useState(false)

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET user emr by user_id
    async function callAPIUserEmr() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptions)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setGoal(responseJson.data.avatar)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const [nutritionist, setNutritionist] = useState<Expert>()

    // GET medical user info by patient
    async function callGetMedicalUserInfoApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptions)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutri = await responseUserinfo.json()
            const newExpert: Expert = {
                id: nutri.id,
                position: nutri.data.sex === "M" ? "Dott." : "Dott.ssa",
                name: nutri.data.name,
                familyName: nutri.data.family_name,
                role: handleExpertRole(nutri.role),
                skills: nutri.tags,
                summary: nutri.data.bio,
                avatar: nutri.calendly_data.calendly_user.avatar_url,
            }
            setOldNutritionist(newExpert)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
            callAPIGetMedicalUsersList(ExpertRole.Nutritionist)
        }
    }

    // GET medical user list
    async function callAPIGetMedicalUsersList(role: string) {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/role/${role}`, requestOptions)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            setIsLoading(true)
            const responseJson = await responseUserinfo.json()
            const nutriNumber = responseJson.length
            const index = Math.round(Math.random() * (nutriNumber - 1))
            const nutritionist = responseJson[index]
            const newExpert: Expert = {
                id: nutritionist.id,
                position: nutritionist.data.sex === "M" ? "Dott." : "Dott.ssa",
                name: nutritionist.data.name,
                familyName: nutritionist.data.family_name,
                role: handleExpertRole(nutritionist.role),
                skills: nutritionist.tags,
                summary: nutritionist.data.bio,
                avatar: nutritionist.calendly_data.calendly_user.avatar_url,
            }
            setNutritionist(newExpert)
            callAPIAssignMedicalUserToPatient(newExpert)
            setIsLoading(false)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const [oldNutritionist, setOldNutritionist] = useState<Expert>({
        name: "old",
        position: "Nutrizionista",
        familyName: "nutri",
        role: "Dott.",
        skills: [""],
        summary: "Prova a fare un refresh della pagina",
    })


    // ASSIGN specialist to a user
    async function callAPIAssignMedicalUserToPatient(myNutri: Expert) {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/assign/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    patient_id: userId,
                    medicaluser_id: myNutri.id,
                })
        })
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    async function callAGetOrdersApi() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptions)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length > 0) {
                setOrdersAvailable(true)
            }
        }
    }

    const handleConfirmButtonClick = () => {
        switch (fromWhere) {
            case NavigationToRewardPage.OnboardingAfterPurchaseNutritionistConsult:
            case NavigationToRewardPage.Login:
                return navigate(`/survey-a-home`, { state: { userId, idToken, accessToken, name } })
            case NavigationToRewardPage.OnboardingNoPurchase:
                return navigate(`choose-product`, { state: { quizAlreadyTaken: "true", name, idToken, accessToken, userId, doesUserWantTheTest, nutritionist, fromPlatform } })
            default:
                handleGoToChooseProduct()
        }
    }

    const handleGoToChooseProduct = () => {
        // callAPIAssignMedicalUserToPatient()
        if (bundleType === undefined) {
            navigate(`choose-product`, { state: { quizAlreadyTaken: "true", name, idToken, accessToken, userId, doesUserWantTheTest, nutritionist, fromPlatform } })
        } else {
            switch (bundleType) {
                case BundleType.DietBundle:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.DietBundle, nutritionist } })
                    break;
                case BundleType.ThreeMonthsBundle:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.ThreeMonthsBundle, nutritionist } })
                    break;
                case BundleType.SixMonthsBundle:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.SixMonthsBundle, nutritionist } })
                    break;
                case BundleType.DietSubscription:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription, nutritionist } })
                    break;
                case BundleType.ThreeMonthsSubscription:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription, nutritionist } })
                    break;
                case BundleType.SixMonthsSubscription:
                    navigate("/reward-page-new/choose-product/checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription, nutritionist } })
                    break;
            }
        }
    }

    const handleGoDirectToCheckout = () => {

    }

    return (
        <div className="App Quiz justify_content_center relative">
            <div className={`reward-page-new-internal-container ${(showInfoDialog || showExpertInfoDialog) ? "visible_30_percent inactive" : ""} ${isLoading ? "visible-0-percent inactive" : ""}`}>
                <div className="reward-page-new-logo">
                    <HolifyaFullLogo />
                </div>
                <div className="reward-page-new-content">
                    <div className="reward-page-new-copy">
                        Grazie per aver risposto al questionario, <span className="w700">{name}</span>! 🙏<br></br>
                        Sulla base delle tue risposte abbiamo selezionato il professionista migliore e il tuo piano! <span className="pointer" onClick={() => setShowInfoDialog(true)}><img src="/images/info-logo.svg" /></span>
                    </div>
                    <div className="reward-page-new-expert-area">
                        <div className="reward-page-new-expert-heading">
                            Il tuo Nutrizionista
                        </div>
                        <RewardPageExpertCard
                            expert={nutritionist !== undefined ? nutritionist : oldNutritionist}
                            onInfoClick={onExpertInfoButtonClicked}
                        />
                    </div>
                    <div className="reward-page-new-your-goal-area">
                        <div className="reward-page-new-your-goal-heading">
                            Il tuo piano <span className="pointer" onClick={onInfoButtonClicked}><img src="/images/info-logo.svg" /></span>
                        </div>
                        <div className="reward-page-new-your-goal-container">
                            <div className="reward-page-new-your-goal-text">{goal}</div>
                        </div>
                    </div>
                </div>
                <div className="reward-page-new-button-container">
                    <button
                        className="reward-page-new-continue-button primary_action_color_background"
                        onClick={handleConfirmButtonClick}
                    >
                        Continua
                    </button>
                </div>
            </div>
            {
                showInfoDialog ?
                    <GoalInfoDialog
                        onCloseClick={onInfoDismissClicked}
                    /> : null
            }
            {
                showExpertInfoDialog ?
                    <ExpertDetailsDialog
                        onCloseClick={onExpertInfoDismissClicked}
                        expert={nutritionist !== undefined ? nutritionist : oldNutritionist}
                    /> : null
            }
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default RewardPageNew
