import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import CloseButton from "../../components/CloseButton";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import { CheckoutBundleType, CheckoutMode } from "../../utils/enums/Checkout";
import { NewProductCard, NewProductCardMobile, NewProductCardNoBundle, NewProductCardNoBundleMobile, NewProductCardNoTest, NewProductCardNoTestMobile, ProductChoice } from "./components/NewProductCard";
import "./css/RewardPageNewChooseProduct.css";

const RewardPageNewChooseProduct = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const name = location.state.name
  const idToken = location.state.idToken
  const accessToken = location.state.accessToken
  const userId = location.state.userId
  const nutritionist = location.state.nutritionist
  const fromPlatform = location.state.fromPlatform

  const [withTest, setWithTest] = useState<boolean>(fromPlatform ? false : location.state.doesUserWantTheTest)

  const section1 = useRef<null | HTMLDivElement>(null);
  const section2 = useRef<null | HTMLDivElement>(null);

  const onNoPlanClicked = () => {
    section2.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onWithPlanClicked = () => {
    section1.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onToInsertCodeClicked = () => {
    navigate(`/insert-product-code`, { state: { quizAlreadyTaken: "false", name, idToken, accessToken, userId } })
    navigate(`/insert-product-code`, { state: { quizAlreadyTaken: "true", name, idToken, accessToken, userId, fromWhere: "reward-page-new-choose-product", doesTheUserWantTheTest: withTest } })
  }

  const [toggleLogo, setToggleLogo] = useState(withTest ? "/images/toggle-yes.svg" : "/images/toggle-no.svg")

  const onToggleClick = () => {
    switch (toggleLogo) {
      case "/images/toggle-yes.svg":
        setToggleLogo("/images/toggle-no.svg")
        setWithTest(false)
        break;
      case "/images/toggle-no.svg":
        setToggleLogo("/images/toggle-yes.svg")
        setWithTest(true)
        break;
      default:
        console.log("error")
    }
  }

  const [isFirstBundleDivOpened, setIsFirstBundleDivOpened] = useState(false)
  const [isSecondBundleDivOpened, setIsSecondBundleDivOpened] = useState(false)

  const [isSubNoBundleDivOpened, setIsSubNoBundleDivOpened] = useState(false)

  const [isOnlyTestDivOpened, setIsOnlyTestDivOpened] = useState(false)

  const onFirstBundleArrowClick = () => {
    setIsFirstBundleDivOpened(!isFirstBundleDivOpened)
  }

  const onSecondBundleArrowClick = () => {
    setIsSecondBundleDivOpened(!isSecondBundleDivOpened)
  }

  const onSubNoBundleArrowClick = () => {
    setIsSubNoBundleDivOpened(!isSubNoBundleDivOpened)
  }

  const onOnlyTestArrowClick = () => {
    setIsOnlyTestDivOpened(!isOnlyTestDivOpened)
  }

  useEffect(() => {
  }, [])

  return (
    <div className="App Quiz">
      <div className="reward-page-new-choose-product-container">
        <div
          className="reward-page-new-choose-product-section-1-container"
          ref={section1}
        >
          <div className="reward-page-new-choose-product-section-1-internal-container">
            <div className="reward-page-new-choose-product-top-row">
              <BackButton
                onClick={() => navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest: withTest, nutritionist, fromPlatform } })}
              />
              <div className="reward-page-new-choose-product-full-logo">
                <HolifyaFullLogo />
              </div>
              <div className="reward-page-new-choose-product-simple-logo">
                <img src="/images/quizQ/holifya-spiral.svg" />
              </div>
              <div className={`${fromPlatform ? "" : "invisible"}`}>
                <CloseButton
                  onClick={() => navigate("/platform", { state: { userId, productName: "", accessToken, idToken, refreshToken: "", name } })}
                />
              </div>
            </div>
            <div className="reward-page-new-choose-product-copy">
              Seleziona la durata del tuo percorso
            </div>
            {!fromPlatform ?
              <div className="reward-page-new-choose-product-toggle-text">
                Acquista insieme ad un test del DNA<span className="pointer lh0 margin_left_20" onClick={onToggleClick}><img src={toggleLogo} /></span>
              </div> : null
            }
            {
              withTest ?
                <div className="reward-page-new-choose-product-products-container">
                  <div className="reward-page-new-choose-product-products-row">
                    {/* <div className="reward-page-new-choose-product-products-row-internal"> */}
                    <NewProductCard
                      product={ProductChoice.OneDiet}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.DietBundle, nutritionist } })}
                    />
                    <NewProductCard
                      product={ProductChoice.ThreeMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.ThreeMonthsBundle, nutritionist } })}
                    />
                    <NewProductCard
                      product={ProductChoice.SixMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.SixMonthsBundle, nutritionist } })}
                    />
                  </div>
                  <div className="reward-page-new-choose-product-products-row-mobile">
                  <NewProductCardMobile
                      product={ProductChoice.OneDiet}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.DietBundle, nutritionist } })}
                      isFirstDivOpened={isFirstBundleDivOpened}
                      isSecondDivOpened={isSecondBundleDivOpened}
                      onFirstArrowClick={onFirstBundleArrowClick}
                      onSecondArrowClick={onSecondBundleArrowClick}
                    />
                    <NewProductCardMobile
                      product={ProductChoice.ThreeMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.ThreeMonthsBundle, nutritionist } })}
                      isFirstDivOpened={isFirstBundleDivOpened}
                      isSecondDivOpened={isSecondBundleDivOpened}
                      onFirstArrowClick={onFirstBundleArrowClick}
                      onSecondArrowClick={onSecondBundleArrowClick}
                    />
                    <NewProductCardMobile
                      product={ProductChoice.SixMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionBundle, bundleType: CheckoutBundleType.SixMonthsBundle, nutritionist } })}
                      isFirstDivOpened={isFirstBundleDivOpened}
                      isSecondDivOpened={isSecondBundleDivOpened}
                      onFirstArrowClick={onFirstBundleArrowClick}
                      onSecondArrowClick={onSecondBundleArrowClick}
                    />
                  </div>
                  {/* </div> */}
                </div> :
                <div className="reward-page-new-choose-product-products-no-test-container">
                  <div className="reward-page-new-choose-product-products-no-test-products-row">
                    <NewProductCardNoTest
                      product={ProductChoice.OneDiet}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription, nutritionist } })}
                    />
                    <NewProductCardNoTest
                      product={ProductChoice.ThreeMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription, nutritionist } })}
                    />
                    <NewProductCardNoTest
                      product={ProductChoice.SixMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription, nutritionist } })}
                    />
                  </div>
                  <div className="reward-page-new-choose-product-products-no-test-products-row-mobile">
                    <NewProductCardNoTestMobile
                      product={ProductChoice.OneDiet}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.DietSubscription, nutritionist } })}
                      isSubNoBundleDivOpened={isSubNoBundleDivOpened}
                      onSubNoBundleArrowClick={onSubNoBundleArrowClick}
                    />
                    <NewProductCardNoTestMobile
                      product={ProductChoice.ThreeMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.ThreeMonthsSubscription, nutritionist } })}
                      isSubNoBundleDivOpened={isSubNoBundleDivOpened}
                      onSubNoBundleArrowClick={onSubNoBundleArrowClick}
                    />
                    <NewProductCardNoTestMobile
                      product={ProductChoice.SixMonthsPlan}
                      onClick={() => navigate("checkout", { state: { idToken, userId, mode: CheckoutMode.SubscriptionOnly, bundleType: CheckoutBundleType.SixMonthsSubscription, nutritionist } })}
                      isSubNoBundleDivOpened={isSubNoBundleDivOpened}
                      onSubNoBundleArrowClick={onSubNoBundleArrowClick}
                    />
                  </div>
                </div>
            }
            <div className="reward-page-new-buttons-row">
              <button
                className="reward-page-i-dont-care-button"
                onClick={onNoPlanClicked}
              >
                Non mi interessa un percorso
              </button>
              {
                !fromPlatform ?
                  <button
                    className="reward-page-to-insert-code-button"
                    onClick={onToInsertCodeClicked}
                  >
                    Ho già un codice acquisto
                  </button> : null
              }
            </div>
          </div>
          <div className="reward-page-choose-product-relative-container">
            {/* <BackButton
              onClick={() => navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest: withTest, nutritionist, fromPlatform } })}
            /> */}
            {
              // fromPlatform ?
              //   <CloseButton
              //     onClick={() => navigate("/platform", { state: { userId, productName: "", accessToken, idToken, refreshToken: "", name } })}
              //   /> : null
            }
          </div>
          {/* {
            fromPlatform ?
              <div className="reward-page-new-absolute-view">
                <CloseButton
                  onClick={() => navigate("/platform", { state: { userId, productName: "", accessToken, idToken, refreshToken: "", name } })}
                  noFlexEnd={true}
                />
              </div> : null
          } */}
        </div>
        <div
          className="reward-page-new-choose-product-section-2-container"
          ref={section2}
        >
          <div className="reward-page-new-choose-product-section-2-internal-container">
            <div className="reward-page-new-choose-product-full-logo">
              <HolifyaFullLogo />
            </div>
            <div className="reward-page-new-choose-product-simple-logo">
              <img src="/images/quizQ/holifya-spiral.svg" />
            </div>
            <div className="reward-page-new-choose-product-products-no-bundle-container">
              <div className="reward-page-new-section-2-choose-products-product-row">
                <NewProductCardNoBundle
                  product={ProductChoice.OneDiet}
                  onClick={() => window.open("https://www.holifya.com/pages/test-dna", "_blank")}
                />
                <NewProductCardNoBundle
                  product={ProductChoice.ThreeMonthsPlan}
                  onClick={() => window.open("https://www.holifya.com/pages/consulto", "_blank")}
                />
                <NewProductCardNoBundle
                  product={ProductChoice.SixMonthsPlan}
                  onClick={() => window.open("https://www.holifya.com/pages/anti-aging", "_blank")}
                />
              </div>
              <div className="reward-page-new-section-2-choose-products-product-row-mobile">
                <NewProductCardNoBundleMobile
                  product={ProductChoice.OneDiet}
                  onClick={() => window.open("https://www.holifya.com/pages/test-dna", "_blank")}
                  isOnlyTestDivOpened={isOnlyTestDivOpened}
                  onOnlyTestArrowClick={onOnlyTestArrowClick}
                />
                <NewProductCardNoBundleMobile
                  product={ProductChoice.ThreeMonthsPlan}
                  onClick={() => window.open("https://www.holifya.com/pages/consulto", "_blank")}
                  isOnlyTestDivOpened={isOnlyTestDivOpened}
                  onOnlyTestArrowClick={onOnlyTestArrowClick}
                />
                <NewProductCardNoBundleMobile
                  product={ProductChoice.SixMonthsPlan}
                  onClick={() => window.open("https://www.holifya.com/pages/anti-aging", "_blank")}
                  isOnlyTestDivOpened={isOnlyTestDivOpened}
                  onOnlyTestArrowClick={onOnlyTestArrowClick}
                />
              </div>
            </div>
            <div className="reward-page-new-section-2-buttons-row">
              <div className="reward-page-new-section-2-bottom-buttons-row">
                <button
                  className="reward-page-buy-plan-button secondary_action_color_background"
                  onClick={onWithPlanClicked}
                >
                  Acquista un percorso
                </button>
                {
                  !fromPlatform ?
                    <button
                      className="reward-page-to-insert-code-button"
                      onClick={onToInsertCodeClicked}
                    >
                      Ho già un codice acquisto
                    </button> : null
                }
              </div>
            </div>
          </div>
          <div className="reward-page-choose-product-relative-container">
            <BackButton
              onClick={() => navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest: withTest, fromPlatform } })}
            />
            {
              fromPlatform ?
                <CloseButton
                  onClick={() => navigate("/platform", { state: { userId, productName: "", accessToken, idToken, refreshToken: "", name } })}
                /> : null
            }
          </div>
          {/* {
            fromPlatform ?
              <div className="reward-page-new-absolute-view">
                <CloseButton
                  onClick={() => navigate("/platform", { state: { userId, productName: "", accessToken, idToken, refreshToken: "", name } })}
                  noFlexEnd={true}
                />
              </div> : null
          } */}
        </div>
      </div>
    </div>
  )
}

export default RewardPageNewChooseProduct
