import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BASE_URL } from "../../utils/Constants";
import { NavigationToQuizQ } from "../../utils/enums/NavigationToQuizQ";
import { QuizType } from "../../utils/enums/QuizType";
import { ShopifyItemName, ShopifyItemSku } from "../../utils/enums/ShopifyItemType";
import "./css/ProductChoiceSuccess.css";

const ProductChoiceSuccess = () => {

    const location = useLocation();
    const redeemedProductName = location.state.redeemedProductName
    const username = location.state.username
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    const [productSku, setProductSku] = useState("")

    const [quizQTaken, setQuizQTaken] = useState(false)

    async function callGetOrdersByUserIdApi() {
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptions)
        if (response.ok) {
            const responseJson = await response.json()
            const responseProductSku = responseJson[0].items[0].type
            setProductSku(responseProductSku)
            handleNavigation(responseProductSku)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    async function callRetrieveQuizQApi() {
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/survey/${QuizType.QuizQ}`, requestOptions)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            setQuizQTaken(true)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const handleNavigation = (sku: ShopifyItemSku) => {
        switch (sku) {
            case ShopifyItemSku.DnaTest: {
                setTimeout(() => {
                    setIsLoading(false)
                    redeemedProductName === ShopifyItemName.DnaTest || redeemedProductName === ShopifyItemName.DnaTestOld ?
                    navigate(`/platform`, { state: { name: username, idToken, accessToken, userId, productName: redeemedProductName } }):
                    navigate(`/quiz-q-home`, { state: { username, idToken, accessToken, userId, productName: redeemedProductName, fromWhere: NavigationToQuizQ.RedeemSuccess } })
                }, 3000)
                break;
            }
            case ShopifyItemSku.DnaTestOld:
            case ShopifyItemSku.ExpertConsult: {
                setTimeout(() => {
                    setIsLoading(false)
                    navigate(`/platform`, { state: { name: username, idToken, accessToken, userId, productName: redeemedProductName } })
                }, 3000)
                break;
            }
            case ShopifyItemSku.NutritionistConsult:
            case ShopifyItemSku.Membership: {
                !quizQTaken ?
                    setTimeout(() => {
                        setIsLoading(false)
                        navigate(`/quiz-q-home`, { state: { username, idToken, accessToken, userId, productName: redeemedProductName, fromWhere: NavigationToQuizQ.RedeemSuccess } })
                    }, 3000)
                    :
                    console.log("al quiz a")
                // navigate(`/quiz-q-success`, { state: { isAfterPurchase: true, name, idToken, accessToken, userId, productName: redeemedProductName } }) 
            }
        }
    }

    useEffect(() => {
        // (redeemedProductName === ShopifyItemName.DnaTest || redeemedProductName === ShopifyItemName.ExpertConsult) ?
        //     setTimeout(
        //         () => {
        //             navigate(`/platform`, { state: { name, idToken, accessToken, userId, productName: redeemedProductName } })
        //         }, 3000
        //     )
        //     :
        //     setTimeout(
        //         () => {
        //             quizAlreadyTaken === "true" ?
        //                 navigate(`/quiz-q-success`, { state: { isAfterPurchase: true, name, idToken, accessToken, userId, productName: redeemedProductName } }) :
        //                 redeemedProductName === ShopifyItemSku.NutritionistConsult ?
        //                     navigate(`/quiz-q-home`, { state: { isAfterPurchase: true, name, idToken, accessToken, userId, productName: redeemedProductName } })
        //                     : navigate(`/quiz-q-success`, { state: { isAfterPurchase: true, name, idToken, accessToken, userId, productName: redeemedProductName } })
        //         }, 3000
        //     )
        setIsLoading(true)
        callGetOrdersByUserIdApi()
        callRetrieveQuizQApi()
    }, [])

    return (
        <div className="App Quiz justify_content_center">
            <div className={`product-choice-success-container`}>
                <HolifyaFullLogo />
                <div className="product-choice-success-copy">
                    <div className="w700">
                        Complimenti!
                    </div>
                    <div>
                        Il tuo acquisto é un<br /><span className="w700 primary_action_color_text">{redeemedProductName}</span>!
                    </div>
                </div>
                <div className="height_35" />
                <div className="height_35" />
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default ProductChoiceSuccess
