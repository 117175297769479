import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalLogoText from "../../../../components/HorizontalLogoText";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import ShopifyItem from "../../../../components/ShopifyItem";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import MenuPopup from "../../components/MenuPopup";
import "./css/MyPurchasesRedeem.css";

const MyPurchasesRedeem = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
    }, [])

    // *******************************************************************************************************************

    const [confirmationNumber, setConfirmationNumber] = useState("")
    const [errorText, setErrorText] = useState("")

    const submitButtonDisabled = confirmationNumber.length < 6

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        callGetOrderByConfirmationCodeAPI()
    }

    // GET orders by confirmation_number
    async function callGetOrderByConfirmationCodeAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/find/${confirmationNumber}`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            if (responseJson.items.length === 1) {
                const item = responseJson.items[0]
                const shopifyItem: ShopifyItem = {
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    type: item.type,
                    confirmationNumber: responseJson.confirmation_number,
                    shopifyId: responseJson.shopify_id,
                    createdAt: responseJson.created_at,
                }
                await callSaveOrderByUserIdAPI(shopifyItem);
            } else {
                var itemIndex = 0
                const shopifyItems: ShopifyItem[] = []
                while (responseJson.items[itemIndex] !== undefined) {
                    shopifyItems.push(
                        {
                            id: responseJson.items[itemIndex].id,
                            name: responseJson.items[itemIndex].name,
                            price: responseJson.items[itemIndex].price,
                            type: responseJson.items[itemIndex].type,
                            confirmationNumber: responseJson.confirmation_number,
                            shopifyId: responseJson.shopify_id,
                            createdAt: responseJson.created_at,
                        }
                    )
                    itemIndex = itemIndex + 1
                }
                navigate("/platform/my-purchases/redeem/choose", { state: { productName, userId, idToken, accessToken, refreshToken, shopifyItems } })
            }
        } else if (response.status === 401) {
            setErrorText("Errore di recupero dati (token)")
        } else if (response.status === 400) {
            setErrorText("Ordine già riscattato")
        } else if (response.status === 404) {
            setErrorText("Ordine non trovato")
        } else {
            setErrorText("Errore recupero dati")
        }
    }

    // SAVE order by user_id
    async function callSaveOrderByUserIdAPI(shopifyItem: ShopifyItem) {
        console.log(shopifyItem.confirmationNumber)
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    shopify_id: shopifyItem.shopifyId,
                    confirmation_number: shopifyItem.confirmationNumber,
                    items: [
                        {
                            id: shopifyItem.id,
                            name: shopifyItem.name,
                            price: shopifyItem.price,
                            type: shopifyItem.type,
                        }
                    ],
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })
        }
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => { navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => { navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => { navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-my-purchases-redeem-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_89_percent horizontal_flex_group justify_content_space_between align_items_start">
                        <div className="width_100_percent">
                            <HorizontalLogoText
                                image={"/images/redeem-purchase-big-icon.svg"}
                                text="Riscatta un nuovo acquisto"
                                isInOwnView={true}
                            />
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                        />
                    </div>
                    <div className="width_100_percent height_90_percent">
                        <div className="vertical-scroll-group-100 width_100_percent">
                            <div className="platform-final-element-container">
                                <div className="my-purchases-redeem-copy-container s18 lh24 margin-bottom-4-percent">
                                    Al momento dell'acquisto ti abbiamo inviato una mail contenente un <span className="w700">codice acquisto</span>.
                                    <br></br>Copialo e incollalo qui sotto per sbloccare la tua esperienza Holifya!
                                </div>
                                <form
                                    className="my-purchases-redeem-form-container"
                                    onSubmit={onSubmit}
                                >
                                    <div className="vertical_flex_group margin_bottom_10">
                                        <label className="s12 w600 uppercase">Codice acquisto</label>
                                        <div className="vertical_flex_group black_border_bottom align_items_end">
                                            <input
                                                className="my-purchases-redeem-input-field ls1"
                                                type="text"
                                                placeholder="000000"
                                                onChange={(e) => {
                                                    setConfirmationNumber(e.target.value)
                                                    setErrorText("")
                                                }}
                                                value={confirmationNumber}
                                            />
                                        </div>
                                        <label className="s12 w600 lh17 error_text margin_top_10">{errorText}</label>
                                    </div>
                                    <button
                                        className={`width_100_percent height_55 uppercase w700 s14 br5 blue_background no_border white_text center margin_top_10 ${!submitButtonDisabled ? "pointer" : ""}`}
                                        disabled={submitButtonDisabled}
                                    >
                                        Conferma codice
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyPurchasesRedeem
