import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import { BASE_URL } from "../../../utils/Constants"

const GoogleLogin = () => {

    useEffect(() => {
        console.log(myParam)
    }, [])

    const location = useLocation();
    const navigate = useNavigate();

    // Get a specific query parameter
    const myParam = new URLSearchParams(location.search).get('code');

    async function callAPI() {
        const response = await fetch(`${BASE_URL}/auth/google/callback?code=${myParam}`, {
            method: 'GET',
        })
        const responseJson = await response.json()
        const idToken = responseJson.id_token
            const accessToken = responseJson.access_token
            const userId = responseJson.id
            const refreshToken = responseJson.refresh_token
            navigate(`/login-success`, { state: { idToken, accessToken, userId, refreshToken } })
    }

    return (
        <div className="App">
            <div className="vertical_flex_group justify_content_center align_items_center width_100_percent height_100_percent">
                <button
                    className="width_30_percent height_20_percent br5 primary_action_color_background uppercase no_border white_text pointer"
                    onClick={callAPI}
                >
                    Login with google
                </button>
            </div>
        </div>
    )
}

export default GoogleLogin
