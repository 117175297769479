import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import HolifyaProduct from "../../components/HolifyaProduct";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShopifyItem from "../../components/ShopifyItem";
import { BASE_URL, holifyaDNATest, holifyaNutritionistConsultancy, holifyaOneMonthBundle, holifyaOneMonthSub, holifyaSixMonthsBundle, holifyaSixMonthsSub, holifyaSpecialistConsultancy, holifyaThreeMonthsBundle, holifyaThreeMonthsSub } from "../../utils/Constants";
import { ShopifyItemName, ShopifyItemSku } from "../../utils/enums/ShopifyItemType";
import ChooseProductCard from "./components/ChooseProductCard";
import InfoDialog from "./components/InfoDialog";
import "./css/ChooseProduct.css";

const ChooseProduct = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state.username
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const shopifyItems: ShopifyItem[] = location.state.shopifyItems

    // Product cards to display and choose
    const products: HolifyaProduct[] = []

    for (const item of shopifyItems) {
        switch (item.type) {
            case ShopifyItemSku.DnaTest: {
                switch (item.name) {
                    case ShopifyItemName.DnaTestOld:
                    case ShopifyItemName.DnaTest:
                        products.push(holifyaDNATest)
                        break;
                    case ShopifyItemName.OneMonthBundle:
                        products.push(holifyaOneMonthBundle)
                        break;
                    case ShopifyItemName.ThreeMonthsBundle:
                        products.push(holifyaThreeMonthsBundle)
                        break;
                    case ShopifyItemName.SixMonthsBundle:
                        products.push(holifyaSixMonthsBundle)
                        break;
                }
                break;
            }
            case ShopifyItemSku.NutritionistConsult:
                products.push(holifyaNutritionistConsultancy)
                break;
            case ShopifyItemSku.ExpertConsult:
                products.push(holifyaSpecialistConsultancy)
                break;
            case ShopifyItemSku.Membership: {
                switch (item.name) {
                    case ShopifyItemName.OneMonthSub:
                        products.push(holifyaOneMonthSub)
                        break;
                    case ShopifyItemName.ThreeMonthsSub:
                        products.push(holifyaThreeMonthsSub)
                        break;
                    case ShopifyItemName.SixMonthsSub:
                        products.push(holifyaSixMonthsSub)
                        break;
                }
            }
        }
    }

    useEffect(() => {
    }, [])

    // When user clicks on a card
    const handleCardClick = (product: string) => {
        setChoice(product)
    }

    // When the user submits the form
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        var index = -1
        products.map(product => {
            if (product.name === choice) {
                index = products.indexOf(product)
            }
        })
        const realItem = shopifyItems[index]
        callSaveProductApi(realItem);
    }

    async function callSaveProductApi(shopifyItem: ShopifyItem) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    shopify_id: shopifyItem.shopifyId,
                    confirmation_number: shopifyItem.confirmationNumber,
                    items: [
                        {
                            id: shopifyItem.id,
                            name: shopifyItem.name,
                            price: shopifyItem.price,
                            type: shopifyItem.type,
                        }
                    ]
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            navigate(`/product-choice-success`, { state: { redeemedProductName: shopifyItem.name, username, idToken, accessToken, userId } })
        }
    }

    const [choice, setChoice] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    const [showInfoDialog, setShowInfoDialog] = useState(false)

    // *****************************************************************************************************************

    return (
        <div className="App Quiz justify_content_center relative">
            <div className={`choose-product-container ${(showInfoDialog || isLoading) ? "visible_30_percent inactive" : ""}`}>
                <div className="choose-product-upper-element-container">
                    <div>
                        <HolifyaFullLogo />
                    </div>
                    <div className="choose-product-upper-element-heading">
                        È il momento di scegliere!
                    </div>
                    <div className="choose-product-upper-element-copy">
                        <span className="w700">WOW!!</span> All'interno del tuo codice acquisto esistono più prodotti! Scegli quale vuoi associare al tuo profilo. <span className="pointer" onClick={() => setShowInfoDialog(true)}><img src="/images/info-logo.svg" /></span>
                    </div>
                </div>
                <form
                    className="choose-product-form-container"
                    onSubmit={handleSubmit}
                >
                    <div className={`choose-product-cards-container`}>
                        <div className="choose-product-cards-internal-container">
                            {
                                products.map(product => (
                                    <ChooseProductCard
                                        key={product.name}
                                        product={product}
                                        onCardClick={handleCardClick}
                                        choice={choice}
                                        icon={product.icon}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div className="choose-product-button-container">
                        <button
                            className={`choose-product-button primary_action_color_background ${(choice !== null) ? "pointer" : ""}`}
                            disabled={choice === null}
                        >
                            Conferma Associazione
                        </button>
                    </div>
                </form>
            </div>
            {
                showInfoDialog ?
                    <InfoDialog
                        onCloseClick={() => setShowInfoDialog(false)}
                    /> : null
            }
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default ChooseProduct
