import { useLocation, useNavigate, useParams } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import { FormEvent, LegacyRef, useRef, useState } from "react";
import { BASE_URL } from "../../../utils/Constants";
import "./css/NewPassword.css"

const NewPassword = () => {
    const location = useLocation();
    const email = location.state.email
    const code = location.state.code

    const [typePassword, setTypePassword] = useState('password');
    const [iconPassword, setIconPassword] = useState("/images/show-password-disabled.svg");
    const [typePasswordConfirm, setTypePasswordConfirm] = useState('password');
    const [iconPasswordConfirm, setIconPasswordConfirm] = useState("/images/show-password-disabled.svg");

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [passwordConfirmErrorText, setPasswordConfirmErrorText] = useState("")

    const toggleShowPasswordSpanRef: LegacyRef<HTMLSpanElement> = useRef<HTMLSpanElement>(null);
    const toggleShowPasswordConfirmSpanRef: LegacyRef<HTMLSpanElement> = useRef<HTMLSpanElement>(null);

    const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
    // regex /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
    const symbols = [".", ",", ":", ";", "-", "_", "?", "!", "$", "€", "£", "&", "%", "/", "(", ")", "=", "'", "*", "\""]
    const lettersUppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

    const isPasswordAcceptable = (newPassword: string) => newPassword.length >= 8 && numbers.some(el => newPassword.includes(el)) && symbols.some(el => newPassword.includes(el)) && lettersUppercase.some(el => newPassword.includes(el))

    const passwordInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextPassword = e.target.value;

        if (isPasswordAcceptable(password) && !isPasswordAcceptable(nextPassword)) {
            if (iconPasswordConfirm === "/images/show-password.svg") {
                setIconPasswordConfirm("/images/show-password-disabled.svg")
            } else if (iconPasswordConfirm === "/images/hide-password.svg") {
                setIconPasswordConfirm("/images/hide-password-disabled.svg")
            }
        } else if (!isPasswordAcceptable(password) && isPasswordAcceptable(nextPassword)) {
            if (passwordConfirm !== "") {
                if (iconPasswordConfirm === "/images/show-password-disabled.svg") {
                    setIconPasswordConfirm("/images/show-password.svg")
                } else if (iconPasswordConfirm === "/images/hide-password-disabled.svg") {
                    setIconPasswordConfirm("/images/hide-password.svg")
                }
            }
        }

        if (nextPassword !== "") {
            toggleShowPasswordSpanRef.current?.classList.add('pointer')
            if (typePassword !== "text") {
                setIconPassword("/images/show-password.svg")
            }
        } else {
            toggleShowPasswordSpanRef.current?.classList.remove('pointer')
        }
        if (isPasswordAcceptable(nextPassword)) {
            setPasswordErrorText("")
        } else {
            setPasswordErrorText("La password non rispetta i criteri di validità")
        }
        setPasswordConfirmErrorText("")
    }

    const passwordConfirmInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextPasswordConfirm = e.target.value
        if (isPasswordAcceptable(password)) {
            if (nextPasswordConfirm !== "") {
                toggleShowPasswordConfirmSpanRef.current?.classList.add('pointer')
                if (typePasswordConfirm !== "text") {
                    setIconPasswordConfirm("/images/show-password.svg")
                } else {
                    setIconPasswordConfirm("/images/hide-password.svg")
                }
            } else {
                toggleShowPasswordConfirmSpanRef.current?.classList.remove('pointer')
                if (typePasswordConfirm !== "text") {
                    setIconPasswordConfirm("/images/show-password-disabled.svg")
                } else {
                    setIconPasswordConfirm("/images/hide-password-disabled.svg")
                }
            }
        }
    }

    const handleTogglePassword = () => {
        if (iconPassword !== "/images/show-password-disabled.svg") {
            if (typePassword === 'password') {
                setIconPassword("/images/hide-password.svg")
                setTypePassword('text')
            } else {
                setIconPassword("/images/show-password.svg")
                setTypePassword("password")
            }
        }
    }

    const handleTogglePasswordConfirm = () => {
        if (iconPasswordConfirm !== "/images/show-password-disabled.svg") {
            if (typePasswordConfirm === 'password') {
                setIconPasswordConfirm("/images/hide-password.svg")
                setTypePasswordConfirm('text')
            } else {
                setIconPasswordConfirm("/images/show-password.svg")
                setTypePasswordConfirm("password")
            }
        }
    }

    const handlePasswordConfirmBlur = () => {
        if (passwordConfirm !== password) {
            setPasswordConfirmErrorText("Le password non corrispondono")
        } else {
            setPasswordConfirmErrorText("")
        }
    }

    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        callAPI();
    }

    async function callAPI() {
        const response = await fetch(`${BASE_URL}/auth/confirm-forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    code: code,
                    password: password,
                })
        })
        if (!response.ok) {
            console.log(response)
        } else {
            navigate(`/change-password-success`, {state: {email, password}})
        }
    }

    const onBackButtonClick = () => {
        navigate(`/forgot-password-insert-code`, { state: { email } })
    }

    return (
        <div className="App">
            <div className='new-password-buttons-row'>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className="vertical_flex_group justify_content_center height_80_percent">
                <div className="vertical_flex_group white_background width_392">
                    <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20">Nuova Password</div>
                    <div className="s18 w400 lh26 margin_top_20 margin_bottom_10 center width_328 self_center">
                        Inserisci e conferma una nuova password per il tuo account Holifya.
                    </div>
                    <div className="s14 w600 lh22 margin_top_10 margin_bottom_20 center width_328 self_center blue_text">
                        Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                    </div>
                    <form
                        className="width_328 self_center"
                        onSubmit={handleSubmit}
                    >
                        <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                            <label className="s12 w600 uppercase">Nuova Password</label>
                            <div className="horizontal_flex_group black_border_bottom align_items_end margin_bottom_10">
                                <input
                                    className="ls1 new-password-input-field s16 w400"
                                    type={typePassword}
                                    placeholder="Nuova password"
                                    onChange={
                                        e => {
                                            setPassword(e.target.value);
                                            passwordInputChangeHandler(e);
                                        }
                                    }
                                />
                                <span
                                    ref={toggleShowPasswordSpanRef}
                                    className="lh0 self_center"
                                    onClick={handleTogglePassword}>
                                    <img src={iconPassword}></img>
                                </span>
                            </div>
                            <label className="s12 w600 lh17 error_text">{passwordErrorText}</label>
                        </div>
                        <div className="vertical_flex_group margin_top_20 margin_bottom_10">
                            <label className="s12 w600 uppercase">Conferma Password</label>
                            <div className={`horizontal_flex_group align_items_end margin_bottom_10 ${isPasswordAcceptable(password) ? "black_border_bottom" : "light_gray_border_bottom"}`}>
                                <input
                                    className={`ls1 new-password-input-field s16 w400 ${isPasswordAcceptable(password) ? "black_text" : "light_gray_text"}`}
                                    type={typePasswordConfirm}
                                    placeholder="Conferma password"
                                    onChange={
                                        e => {
                                            setPasswordConfirm(e.target.value);
                                            passwordConfirmInputChangeHandler(e);
                                        }
                                    }
                                    onBlur={handlePasswordConfirmBlur}
                                    disabled={!isPasswordAcceptable(password)}
                                />
                                <span
                                    ref={toggleShowPasswordConfirmSpanRef}
                                    className="lh0 self_center"
                                    onClick={handleTogglePasswordConfirm}>
                                    <img src={iconPasswordConfirm}></img>
                                </span>
                            </div>
                            <label className="s12 w600 lh17 error_text">{passwordConfirmErrorText}</label>
                        </div>
                        <button
                            className={`width_328 height_55 uppercase w700 s14 br5 blue_background no_border white_text margin_top_10 margin_bottom_20 center ${(passwordConfirm !== "" && password === passwordConfirm) ? "pointer" : ""}`}
                            disabled={!(passwordConfirm !== "" && password === passwordConfirm)}
                        >
                            Conferma Password
                        </button>
                    </form>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'></div>
        </div>
    )
}

export default NewPassword
