import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import RadioAnswerCardPickup from "../../../../components/RadioAnswerCardPickup";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import TrackingStatus from "../../../../utils/enums/TrackingStatus";
import MenuPopup from "../../components/MenuPopup";
import "./css/ExamsAndTestsCollectingRequestPickup.css";

const ExamsAndTestsCollectingRequestPickup = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callFindTrackerByUserIdAPI()
    }, [])

    // *******************************************************************************************************************

    // This state determines the position on the progression bar, and also the direction of the links
    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)
    const [orderId, setOrderId] = useState("")

    // GET tracking by user ID
    async function callFindTrackerByUserIdAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            setTrackingStatus(responseJson[0].tracking_status)
            setOrderId(responseJson[0].order_id)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [pickupChoice, setPickupChoice] = useState("")

    // UPDATE track item
    async function callUpdateTrackerAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    tracking_status: TrackingStatus.PickupRequested,
                }
            )
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log(responseJson)
        } else {
            console.log(responseJson)
        }
    }

    const onGoToTNTWebsiteClick = () => {
        switch (pickupChoice) {
            case "Ritiro a domicilio":
                console.log("manda mail")
                window.open("https://www.tnt.it/booking/Booking.do", "_blank", "noreferrer")
                break;
            case "Consegna in FedEx TNT Point":
                console.log("manda mail")
                window.open("https://www.tnt.it/contents/trova-fedex.html", "_blank", "noreferrer")
                break;
            default:
                break;
        }
        callUpdateTrackerAPI()
        navigate("/platform/exams-and-tests/collecting", { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-exams-and-tests-collecting-request-pickup-full-container ${(focusOnMenu) ? "visible_30_percent inactive" : ""}`}>
                    <div className="exams-and-tests-request-pickup-title-button-row">
                        <div className="w700 s30 lh40">
                            Richiedi il ritiro
                        </div>
                        <CloseButton
                            noFlexEnd={true}
                            onClick={() => navigate("/platform/exams-and-tests/collecting", { state: { userId, productName, accessToken, idToken, refreshToken, name, sampleState: TrackingStatus.SampleRegistered } })}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        <div className="platform-final-element-container mobile-scroll-100">
                            <div className={`vertical_flex_group height_80_percent w400 s18 lh24`}>
                                <div className="">
                                    <div className="w700">
                                        Inserisci la lettera di vettura nella tasca trasparente posizionata sul lato della busta del corriere.
                                    </div>
                                    <div>
                                        Ora tutto è pronto! Ti basta selezionare uno dei due metodi di ritiro qui sotto.
                                    </div>
                                </div>
                                <div className="height_35" />
                                <div className="w700">
                                    Ritiro a domicilio
                                </div>
                                <div>
                                    Se qui sotto selezioni “Ritiro a domicilio” verrai reindirizzato sul sito di FedEx TNT per inserire i tuoi dati di ritiro:
                                    <ul>
                                        <li>seleziona l'opzione <span className="w700">“Mittente convenzionato”</span> e lascia la spunta su <span className="w700">“Merce destinata in Italia”</span> e prosegui;</li>
                                        <li>inserisci i tuoi dati relativi a dove ritirare il tuo campione e prosegui;</li>
                                        <li>inserisci le tue preferenze su quando ritirare il tuo campione e prosegui;</li>
                                        <li>inserisci la tua mail e prosegui;</li>
                                        <li>inserisci le dimensioni e il peso (c.ca 0,5 kg e 15cm x 15cm x 5cm) e prosegui;</li>
                                        <li>controlla e conferma.</li>
                                    </ul>
                                    Il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, verrà ritirato secondo le istruzioni da te fornite e consegnato in pochi giorni al nostro laboratorio.
                                </div>
                                <div className="height_35" />
                                <div className="w700">
                                    Consegna in FedEx TNT Point
                                </div>
                                <div>
                                    Se qui sotto selezioni “Consegna in FedEx TNT Point”, dopo la conferma verrai reindirizzato al sito di FedEx TNT per trovare il FedEx TNT Point più vicino a te.
                                    Porta il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, al FedEx TNT Point che preferisci. La lettera di vettura allegata permetterà al tuo campione di arrivare in pochi giorni al nostro laboratorio.
                                </div>
                                <div className="height_35" />
                                <div className="height_35" />
                                <div className="w700 italic_text">
                                    Cos'è una lettera di vettura?
                                </div>
                                <div className="italic_text">
                                    La lettera di vettura è un foglio allegato ad ogni Kit Holifya, che troverete all'interno del pacchetto di spedizione.
                                </div>
                                <div className="italic_text">
                                    La lettera di vettura è essenziale per rispedire il campione al nostro laboratorio e andrà consegnata assieme alla busta TNT.
                                </div>
                                <div className="dna-test-pickup-request-radio-choices-container">
                                    <RadioAnswerCardPickup
                                        answer={"Ritiro a domicilio"}
                                        onCardClick={() => setPickupChoice("Ritiro a domicilio")}
                                        choice={pickupChoice}
                                        displayEmoji={false}
                                    />
                                    <RadioAnswerCardPickup
                                        answer={"Consegna in FedEx TNT Point"}
                                        onCardClick={() => setPickupChoice("Consegna in FedEx TNT Point")}
                                        choice={pickupChoice}
                                        displayEmoji={false}
                                    />
                                </div>
                                {
                                    pickupChoice !== "" ?
                                        <div className="vertical_flex_group justify_content_center height_35_percent width_100_percent">
                                            <div className="dna-test-picup-request-alert-box yellow-alert-background">
                                                <div className="horizontal_flex_group align_items_center margin-top-1-percent">
                                                    <div className="lh0 margin-left-2-percent">
                                                        <img src="/images/alert-icon.svg" />
                                                    </div>
                                                    <div className="w600 s20 lh26 margin-left-2-percent">
                                                        Attenzione
                                                    </div>
                                                </div>
                                                {pickupChoice === "Ritiro a domicilio" ?
                                                    <div className="w400 s18 lh24 margin-left-2-percent margin-right-2-percent margin-bottom-1-percent">
                                                        Non verrai addebitato di alcun importo per il ritiro del campione. <br></br>
                                                        è importante che venga selezionata l'opzione <span className="w700">“Mittente convenzionato”</span> e che venga lasciata la spunta su <span className="w700">“Merce destinata in Italia”</span>.
                                                    </div>
                                                    : <div className="w400 s18 lh24 margin-left-2-percent margin-right-2-percent margin-bottom-1-percent"><span className="w700">La lettera di vettura</span> in tuo possesso è valida anche per far recuperare il tuo campione in un Fedex TNT Point.<br></br>
                                                        <span className="w700">Ricordati di allegarla alla busta contenente il campione</span> prima di consegnarla al al Fedex TNT Point più comodo per te. <br></br>
                                                        Confermando sarai reindirizzato sulla pagina del fornitore di logistica per trovare il punto di raccolta più vicino.</div>}
                                            </div>
                                        </div> : null
                                }
                            </div>
                            <div className="exams-and-tests-reques-pickup-buttons-container">
                                <button
                                    className={`dna-test-pickup-request-back-button transparent_background s12 w700`}
                                    onClick={() => navigate("/platform/exams-and-tests/collecting/packing", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                                    type="button"
                                >
                                    <img src="/images/back-arrow.svg" />
                                    <div className="no_decoration_text">
                                        Indietro
                                    </div>
                                </button>
                                <button
                                    className={`dna-test-pickup-request-confirm-button tnt-color-background w700 s14 lh17`}
                                    onClick={onGoToTNTWebsiteClick}
                                    disabled={pickupChoice === ""}
                                >
                                    Conferma
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default ExamsAndTestsCollectingRequestPickup
