import { useLocation, useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../components/HolifyaFullLogo";
import { NavigationToQuizQ } from "../../utils/enums/NavigationToQuizQ";
import "./css/RedeemPage.css";

const RedeemPage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const username = location.state.username
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId

    const handleAlreadyPurchased = () => {
        navigate(`/insert-product-code`, { state: { quizAlreadyTaken: "false", username, idToken, accessToken, userId, fromWhere: "redeem-page" } })
    }

    const handleNoPurchasesYet = () => {
        navigate(`/quiz-q-home`, { state: { username, idToken, accessToken, userId, productName: "", fromWhere: NavigationToQuizQ.NoPurchase } })
    }

    return (
        <div className="App Quiz relative">
            <div className="vertical_flex_group redeem-page-container justify_content_center absolute center-div">
                <div className="vertical_flex_group justify_content_center align_items_center height_20_percent">
                    <HolifyaFullLogo />
                </div>
                <div className="vertical_flex_group justify_content_center align_items_center height_20_percent width_100_percent s25 lh35">
                    <div>Ciao <span className="w700">{username}</span>! {String.fromCodePoint(0x1F44B)}</div>
                    <div>Felici di vederti qui!</div>
                </div>
                <div className="height_60_percent redeem-page-card-container">
                    <div
                        className="vertical_flex_group justify_content_flex_end redeem-page-card-width height_212 br20 drop_shadow gray_border pointer swab_choice_background"
                        onClick={handleAlreadyPurchased}
                    >
                        <div className="width_45_percent w600 s18 lh26 margin-left-5-percent margin-bottom-5-percent">
                            Ho già effettuato acquisti nello store Holifya!
                        </div>
                    </div>
                    <div
                        className="vertical_flex_group justify_content_flex_end redeem-page-card-width height_212 br20 drop_shadow gray_border pointer swab_choice_background"
                        onClick={handleNoPurchasesYet}
                    >
                        <div className="width_45_percent w600 s18 lh26 margin-left-5-percent margin-bottom-5-percent">
                            Non ho ancora effettuato acquisti nello store Holifya.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedeemPage