import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ExampleNumberAndLetters from "../../components/ExampleNumberAndLetters"
import HolifyaFullLogo from "../../components/HolifyaFullLogo"
import LoadingSpinner from "../../components/LoadingSpinner"
import ShopifyItem from "../../components/ShopifyItem"
import SwabExampleDialog from "../../components/SwabExampleDialog"
import { BASE_URL } from "../../utils/Constants"
import "./css/PharmaciesRegisterSwabCode.css"

const PharmaciesRegisterSwabCode = () => {

  // Navigation parameters
  const navigate = useNavigate()
  const location = useLocation();
  const name = location.state.name
  const idToken = location.state.idToken
  const accessToken = location.state.accessToken
  const userId = location.state.userId
  const shopifyItem = location.state.shopifyItem

  // Booleans to show alternative screens
  const [isLoading, setIsLoading] = useState(false)
  const [showExampleNumbersAndLetter, setShowExampleNumbersAndLetter] = useState(false)

  const [showSwabExample, setShowSwabExample] = useState(false)

  // Swab code and its error
  const [swabcode, setSwabcode] = useState("")
  const [swabcodeErrorText, setSwabcodeErrorText] = useState("")

  // Arrow button to display the helping div
  const [arrowIcon, setArrowIcon] = useState("/images/down-arrow-blue.svg")
  const onArrowClick = () => {
    if (arrowIcon === "/images/down-arrow-blue.svg") {
      setArrowIcon("/images/up-arrow-blue.svg")
      setShowExampleNumbersAndLetter(true)
    } else {
      setArrowIcon("/images/down-arrow-blue.svg")
      setShowExampleNumbersAndLetter(false)
    }
  }

  // When the user click on the info logo
  const onInfoLogoClick = () => {
    setShowSwabExample(true)
  }

  const onInfoCloseClick = () => {
    setShowSwabExample(false)
  }

  // Boolean to determine whether the submit button is disabled
  const isSubmitButtonDisabled = swabcode.length < 12
  // When the user clicks the submit button
  const onSubmitClick = () => {
    callUpdateUserInformationsAPI(swabcode)
  }

  async function callPostAPI(shopifyItem: ShopifyItem) {
    setIsLoading(true)
    console.log(shopifyItem)
    const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
      body: JSON.stringify(
        {
          shopify_id: shopifyItem.shopifyId,
          confirmation_number: shopifyItem.confirmationNumber,
          items: [
            {
              id: shopifyItem.id,
              name: shopifyItem.name,
              price: shopifyItem.price,
              type: shopifyItem.type,
            }
          ],
        })
    })
    setIsLoading(false)
    if (!response.ok) {
      const responseJson = await response.json()
      console.log(responseJson)
    } else {
      // callAPIAssignMedicalUserToPatient()
      navigate(`/platform`, { state: { name, idToken, accessToken, userId, productName: shopifyItem.name } })
    }
  }

  // UPDATE user informations
  async function callUpdateUserInformationsAPI(swabCode: string) {
    setIsLoading(true)
    const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
      body: JSON.stringify(
        {
          swabcode: swabCode,
        })
    })
    setIsLoading(false)
    if (!response.ok) {
      const responseJson = await response.json()
      console.log(responseJson)
      setSwabcodeErrorText("Inserisci un codice corretto e disponibile")
    } else {
      callPostAPI(shopifyItem)
    }
  }

  useEffect(() => {

  }, [])

  return (
    <div className="App Quiz justify_content_center relative">
      <div className={`pharmacies-register-swab-code-container ${(isLoading || showSwabExample) ? "visible_30_percent inactive" : ""}`}>
        <HolifyaFullLogo />
        <div className="horizontal_flex_group justify_content_center align_items_center margin_top_20 w700 s25 lh35">
          <div className="">Registra il tuo campione </div>
          <div
            onClick={onInfoLogoClick}
            className="pointer margin_left_10 lh0"
          >
            <img src="/images/info-logo.svg" />
          </div>
        </div>
        <div className="pharmacies-register-upper-element-container">
          <div>Inserisci il tup codice unico di 12 cifre.</div>
          <div className="horizontal_flex_group align_items_center">
            <div className="w400 s16 lh24 center">Presta attenzione ai <span className="w700 underline_text primary_action_color_text">caratteri più facilmente fraintendibili</span></div>
            <div
              className="pointer margin_left_10 lh0"
              onClick={onArrowClick}
            >
              <img src={arrowIcon} />
            </div>
          </div>
        </div>
        {
          showExampleNumbersAndLetter ?
            <div className="pharmacies-register-swab-code-example-numbers-and-letters">
              <ExampleNumberAndLetters image={"/images/number-0.svg"} text={"0 (numero zero)"} />
              <ExampleNumberAndLetters image={"/images/letter-O.svg"} text={"O (o maiuscola)"} />
              <ExampleNumberAndLetters image={"/images/number-1.svg"} text={"1 (uno numero)"} />
              <ExampleNumberAndLetters image={"/images/letter-l.svg"} text={"l (elle minuscola)"} />
              <ExampleNumberAndLetters image={"/images/letter-I.svg"} text={"I (i maiuscola)"} />
            </div> : <div className="width_100_percent height_5_percent"></div>
        }
        <div className="pharmacies-register-swab-code-form-container">
          <label className="s12 w600 uppercase lh14">Codice campione</label>
          <div className="pharmacies-register-swab-code-input-container">
            <input
              className="pharmacies-register-swab-code-input-field ls1"
              type="text"
              placeholder="Codice campione"
              value={swabcode}
              onChange={(e) => setSwabcode(e.target.value)}
            />
          </div>
          <label className="s12 w600 lh17 error_text">{swabcodeErrorText}</label>
          <button
            className={`pharmacies-register-swab-code-confirm-button primary_action_color_background ${!isSubmitButtonDisabled ? "pointer" : ""}`}
            disabled={isSubmitButtonDisabled}
            onClick={onSubmitClick}
          >
            Conferma codice
          </button>
        </div>
      </div>
      {
        isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
      }
      {
        showSwabExample ? <SwabExampleDialog
          onInfoCloseClick={onInfoCloseClick}
        /> : null
      }
    </div>
  )
}

export default PharmaciesRegisterSwabCode
