import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import { useState } from "react"
import LogoButton from "../../../components/LogoButton"
import Divider from "../../../components/Divider"
import "./css/CreateAccount.css"

const CreateAccount = () => {

    const location = useLocation();

    const [newsletterAccepted, setNewsletterAccepted] = useState(location.state.newsletterAccepted)

    const navigate = useNavigate();

    const onBackButtonClick = () => {
        navigate("/login")
    }

    const onCreateAccountClick = () => {
        navigate("/login")
    }

    return (
        <div className="App">
            <div className='create-account-buttons-row'>
                <BackButton onClick={onBackButtonClick} />
                <button
                    className='small-button s12 w700 no_border secondary_action_color_background'
                    onClick={onCreateAccountClick}
                >
                    Accedi
                </button>
            </div>
            <div className="vertical_flex_group justify_content_center height_80_percent">
                <div className="vertical_flex_group white_background width_392">
                    <div className="s30 w700 margin_bottom_20 lh24 self_center">Crea il tuo account</div>
                    <div className="width_328 w400 s14 center margin_top_20 margin_bottom_20 self_center ln22">
                        Creando un account, dichiari di aver letto e compreso le{" "}
                        <a
                            className=""
                            href={"/assets/personal-data-treatment.pdf"}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={
                                {
                                    textDecoration: "underline",
                                    color: "black",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                        >
                            Informazioni relative al trattamento dei dati personali
                        </a>.
                    </div>
                    <div className="w400 s14 width_328 left horizontal_flex_group self_center margin_top_20 margin_bottom_20 align_items_start">
                        <input
                            className="pointer"
                            type="checkbox"
                            checked={newsletterAccepted}
                            onChange={() => { setNewsletterAccepted(!newsletterAccepted) }}
                        />
                        <div>
                            <div
                                className="horizontal_flex_group justify_content_center align_items_center create-account-checkbox-container"
                                onClick={() => { setNewsletterAccepted(!newsletterAccepted) }}
                            >
                                <div className={`create-account-checkbox-internal-element ${newsletterAccepted ? "" : "invisible"}`}>

                                </div>
                            </div>
                        </div>
                        <span className="margin_left_10">
                            Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                            <a
                                href={"/assets/newsletter.pdf"}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={
                                    {
                                        textDecoration: "underline",
                                        color: "black",
                                        fontWeight: "600",
                                        cursor: "pointer",
                                    }}
                            >
                                Informativa relativa alla newsletter
                            </a>
                        </span>.
                    </div>
                    <div className="width_328 self_center">
                        <button
                            className="black_border pointer transparent_background br5 w700 s14 width_328 height_55 margin_top_20 margin_bottom_20"
                            onClick={() => { navigate(`/email-registration`, { state: { newsletterAccepted } }) }}
                        >
                            Registrati con la tua e-mail
                        </button>
                    </div>
                    <Divider text="o"></Divider>
                    <div className="width_328 self_center">
                        <LogoButton children={"Registrati con Google"} image="/images/google-logo.svg" onClick={() => { navigate("/google-login") }} />
                    </ div>
                    <div className="width_328 self_center margin_bottom_20">
                        <LogoButton children={"Registrati con Apple"} image="/images/apple-logo.svg" onClick={() => { navigate("/apple-login") }} />
                    </ div>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'></div>
        </div>
    )
}

export default CreateAccount
