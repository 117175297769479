import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseButton from "../../../../components/CloseButton";
import HorizontalNavBar from "../../../../components/HorizontalNavBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import Survey from "../../../../components/Survey";
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../../utils/Constants";
import { handleSurveyOrderPosition, surveyName } from "../../../../utils/Functions";
import MenuPopup from "../../components/MenuPopup";
import MyAnswersSingleAnswerCard from "./components/MyAnswersSingleAnswerCard";
import "./css/MyAnswersSurveyType.css";

const MyAnswersSurveyType = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        callAPIUserInfo()
        callAPIAllSurveysRecovery();
    }, [])

    // *******************************************************************************************************************

    // Surveys already answered by the user
    const [surveyToModify, setSurveyToModify] = useState<Survey>()

    const [answerChanged, setAnswerChanged] = useState(location.state.answerChanged)
    var questionIndex = location.state.index
    var newAnswer = location.state.correctAnswer

    // GET all surveys survey by user_id
    async function callAPIAllSurveysRecovery() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/emr/${userId}/surveys`, requestOptionsGET)
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            const surveys: Survey[] = []
            for (const survey of responseJson) {
                surveys.push(
                    {
                        type: survey.type,
                        qAndAs: survey.data,
                        createdAt: survey.created_at,
                        orderPosition: handleSurveyOrderPosition(survey)
                    }
                )
            }
            // If there is a new answer, I update the full survey
            if (questionIndex !== undefined && newAnswer !== undefined) {
                surveys.filter(survey => survey.type === surveyType)[0].qAndAs[questionIndex].a = [newAnswer]
            }
            setSurveyToModify(surveys.filter(survey => survey.type === surveyType)[0])
        } else if (response.status === 401) {
            navigate("/login")
            // call refreshToken
            // call again callAPISurveyRecovery
        } else {
            // console.log(responseQuizTaken)
        }
    }

    async function callAPISaveSurveyByType() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/users/${userId}/survey`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    type: surveyType,
                    data: surveyToModify?.qAndAs,
                })
        })
        setIsLoading(false)
        const responseJson = await response.json()
        if (response.ok) {
            console.log(responseJson)
            // setConfirmEditAnswersSubmitButtonDisabled(true)
            setAnswerChanged(false)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const { surveyType } = useParams()

    const onConfirmUpdateSurveyClick = () => {
        callAPISaveSurveyByType()
    }

    const onEditAnswerClick = (surveyType: string, index: number, correctAnswer: string) => {
        navigate(`/platform/my-answers/${surveyType}/edit-single-question`, { state: { productName, userId, idToken, accessToken, refreshToken, surveyType, index, correctAnswer } })
    }

    return (
        <div className="App Quiz justify_content_center">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container ${isLoading ? "visible_30_percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => { navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => { navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => { navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-my-answers-survey-type-full-container ${focusOnMenu ? "visible_30_percent inactive" : ""}`}>
                    <div className="my-answers-survey-type-list-header">
                        <div className="my-answers-survey-type-heading-row">
                            <div className="my-answers-survey-type-heading">
                                {surveyName(surveyType)}
                            </div>
                            <CloseButton
                                onClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                                noFlexEnd={true}
                            />
                        </div>
                        <div className="my-answers-survey-type-header-copy">
                            Dopo aver modificato una risposta devi confermare le modifiche in fondo alla pagina.
                        </div>
                    </div>
                    <div className="my-answers-survey-type-answers-container">
                        <div className="my-answers-survey-type-answer-internal-container">
                            <div className="platform-final-element-container">
                                {
                                    surveyToModify?.qAndAs.map(qAndA => (
                                        <MyAnswersSingleAnswerCard
                                            key={surveyToModify.qAndAs.indexOf(qAndA)}
                                            question={qAndA.q}
                                            answer={qAndA.a[0]}
                                            onEditClick={() => onEditAnswerClick(surveyType!, surveyToModify.qAndAs.indexOf(qAndA), qAndA.a[0])}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="my-answers-survey-type-button-row">
                        <button
                            className={`my-answers-survey-type-confirm-button primary_action_color_background ${answerChanged ? "pointer" : ""}`}
                            onClick={onConfirmUpdateSurveyClick}
                            disabled={!answerChanged}
                        >
                            Conferma
                        </button>
                    </div>
                </div>
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}

export default MyAnswersSurveyType
