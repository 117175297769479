import CloseButton from "../../../components/CloseButton"
import "./css/GoalInfoDialog.css"

interface Props {
    onCloseClick: () => void,
}

const GoalInfoDialog: React.FC<Props> = ({
    onCloseClick,
}) => {
    return (
        <div className="goal-info-dialog-container">
            <div className="goal-info-dialog-internal-container">
                <div className="goal-info-dialog-title-button-row">
                    <div className="info-dialog-title">
                        Il tuo piano
                    </div>
                    <CloseButton
                        onClick={onCloseClick}
                    />
                </div>
                <div className="goal-info-dialog-copy">
                    Il piano ti è stato assegnato sulla base delle risposte che hai fornito.
                    Il Nutrizionista potrà valutare assieme a te di modificarlo a seguito del
                    vostro primo videoconsulto.
                </div>
            </div>
        </div>
    )
}

export default GoalInfoDialog
