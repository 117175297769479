import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from 'react-gtm-module';
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import App from "./App";

const tagManagerArgs = {
    gtmId: 'GTM-P9DJPS6B'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
