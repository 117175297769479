import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import { BASE_URL } from "../../../utils/Constants";
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ";
import "./css/QuizQHomePage.css";

const QuizQHomePage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const [username, setUsername] = useState("")
    const [idToken, setIdToken] = useState("")
    const [accessToken, setAccessToken] = useState("")
    const [userId, setUserId] = useState("")
    const [productName, setProductName] = useState("")
    const [fromWhere, setFromWhere] = useState("")

    const onBackClick = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.Website:
                window.open("http://holifya.com", "_self")
                break;
            case NavigationToQuizQ.NoPurchase:
                navigate("/redeem-page", { state: { username, idToken, accessToken, userId } })
                break;
            case NavigationToQuizQ.PlatformDashboard:
            case NavigationToQuizQ.PlatformExperts:
            case NavigationToQuizQ.PlatformPlan:
                navigate("/platform", { state: { userId, productName, accessToken, idToken, refreshToken: "", name: username } })
                break;
            default:
        }

    }

    const handleConfirm = () => {
        if (location.state === null) {
            navigate(`/quiz-q-new`)
        } else {
            navigate(`/quiz-q-new`, { state: { name: username, idToken, accessToken, userId, productName, fromWhere } })
        }
    }

    const [isLoading, setIsLoading] = useState(false)

    const [ordersAvailable, setOrdersAvailable] = useState(false)

    async function callGetOrdersByUserIdApi(tempUserId: string, tempIdToken: string) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${tempUserId}`, {
            method: 'GET',
            headers: {
                'Authorization': tempIdToken!
            }
        })
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.length > 0) {
                setOrdersAvailable(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        if (location.state !== null) {
            setUsername(location.state.username)
            setIdToken(location.state.idToken)
            setAccessToken(location.state.accessToken)
            setUserId(location.state.userId)
            setProductName(location.state.productName)
            setFromWhere(location.state.fromWhere)
            callGetOrdersByUserIdApi(location.state.userId, location.state.idToken)
        } else {
            setFromWhere(NavigationToQuizQ.Website)
        }
    }, [])

    const isBackButtonVisible = () => {
        switch (fromWhere) {
            case NavigationToQuizQ.NoPurchase:
            case NavigationToQuizQ.Website:
            case NavigationToQuizQ.PlatformDashboard:
            case NavigationToQuizQ.PlatformExperts:
            case NavigationToQuizQ.PlatformPlan:
                return true
            case NavigationToQuizQ.Login:
            case NavigationToQuizQ.RedeemSuccess:
            default:
                return false
        }
    }

    return (
        <div className="App Quiz justify_content_space_between">
            <div className={`quiz-q-home-header ${!isBackButtonVisible() ? "invisible" : ""}`}>
                <BackButton
                    onClick={onBackClick}
                />
            </div>
            <div className="quiz-q-home-container">
                <div className="">
                    <HolifyaFullLogo />
                </div>
                <div>
                    <img src="/images/quiz-q-home.svg" />
                </div>
                {ordersAvailable ?
                    <div className="quiz-q-home-after-purchase-text">
                        <div className="quiz-q-home-after-purchase-heading">
                            Iniziamo!
                        </div>
                        <div className="quiz-q-home-after-purchase-copy">
                            Poche e rapide domande che ti aiuteranno a individuare il migliore professionista per le tue esigenze e la soluzione Holifya più adatta a te!
                        </div>
                    </div> :
                    <div className="quiz-q-home-no-purchase-text">
                        <div className="quiz-q-home-no-purchase-heading">
                            Ciao <span className="w700">{username}</span>! {String.fromCodePoint(0x1F44B)}
                        </div>
                        <div className="quiz-q-home-no-purchase-copy">
                            Vuoi rispondere a poche e semplici domande per capire se Holifya è la scelta giusta per te?
                        </div>
                    </div>
                }
                <button
                    className={`quiz-q-home-confirm-button primary_action_color_background`}
                    onClick={handleConfirm}
                >
                    Rispondi subito
                </button>
            </div>
            <div className="quiz-q-home-footer" />
        </div>
    )
}

export default QuizQHomePage
