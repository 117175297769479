interface Props {
    text: string;
}

const Divider: React.FC<Props> = ({
    text
}) => {
    return (
        <div className="width_328 self_center">
            <div className='horizontal_flex_group space_between align_items_center'>
                <div className="light_black_background width_45_percent height_1"></div>
                <span className="dark_gray_text">{text}</span>
                <div className="light_black_background width_45_percent height_1"></div>
            </div>
        </div>
    )
}

export default Divider
