import React from "react"
import "./css/NewProductCard.css"

interface Props {
    product: ProductChoice,
    onClick: () => void,
}

export enum ProductChoice {
    OneDiet = "one-diet",
    ThreeMonthsPlan = "three-months-plan",
    SixMonthsPlan = "six-months-plan",
}

const handleTagTextSub = (product: ProductChoice) => {
    switch (product) {
        case ProductChoice.OneDiet:
            return "1 mese di Holifya"
        case ProductChoice.ThreeMonthsPlan:
            return "3 mesi di Holifya"
        case ProductChoice.SixMonthsPlan:
            return "6 mesi di Holifya"
    }
}

const handleTagTextTest = (product: ProductChoice) => {
    switch (product) {
        case ProductChoice.OneDiet:
            return "DNA Test Complete"
        case ProductChoice.ThreeMonthsPlan:
            return "Videoconsulto Nutrizionista"
        case ProductChoice.SixMonthsPlan:
            return "Videoconsulto genetico"
    }
}

const handleTestPrice = (product: ProductChoice) => {
    switch (product) {
        case ProductChoice.OneDiet:
            return <div className="new-product-card-bundle-price-row">
                <div>330€</div>
            </div>
        case ProductChoice.ThreeMonthsPlan:
            return <div className="new-product-card-bundle-price-row">
                <div>250€</div>
                <div className="new-product-card-bundle-old-price">330€</div>
            </div>
        case ProductChoice.SixMonthsPlan:
            return <div className="new-product-card-bundle-price-row">
                <div>200€</div>
                <div className="new-product-card-bundle-old-price">330€</div>
            </div>
    }
}

const handleBundleCopy = (product: ProductChoice) => {
    switch (product) {
        case ProductChoice.OneDiet:
            return <div>
                <div className="new-product-card-bundle-price">Oggi paghi <span className="s28">419€</span></div>
                <div>Se vuoi continuare, dopo 1 mese di percorso: 89€ / mese</div>
            </div>
        case ProductChoice.ThreeMonthsPlan:
            return <div>
                <div className="new-product-card-bundle-price">Oggi paghi <span className="s28">487€</span></div>
                <div>Se vuoi continuare, dopo 3 mesi di percorso: 79€ / mese</div>
            </div>
        case ProductChoice.SixMonthsPlan:
            return <div>
                <div className="new-product-card-bundle-price">Oggi paghi <span className="s28">608€</span></div>
                <div>Se vuoi continuare, dopo 6 mesi di percorso: 68€ / mese</div>
            </div>
    }
}

export const NewProductCard: React.FC<Props> = ({
    product,
    onClick,
}) => {

    const handleCardCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span><span className="w700">Percorso di 1 mese per iniziare a personalizzare la tua alimentazione.</span> Puoi decidere di rinnovare a questa tariffa mensilmente, oppure accedere a un piano di 3 o 6 mesi.</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span><span className="w700">Percorso di 3 mesi per raggiungere i tuoi obiettivi principali.</span> Dal termine del terzo mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 79 € mensilmente.</span>
            case ProductChoice.SixMonthsPlan:
                return <span><span className="w700">Percorso di 6 mesi per raggiungere i tuoi obiettivi principali e tuoi obiettivi a lungo termine.</span> Dal termine del sesto mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 68 € mensilmente.</span>
        }
    }

    const handlePrice = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span>89€</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>237€</span>
            case ProductChoice.SixMonthsPlan:
                return <span>408€</span>
        }
    }

    const handlePricePerMonth = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>79€ / mese</span>
            case ProductChoice.SixMonthsPlan:
                return <span>68€ / mese</span>
        }
    }

    const handlePriceCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return "Se vuoi continuare, dopo 1 mese di percorso: 89€ / mese"
            case ProductChoice.ThreeMonthsPlan:
                return "Se vuoi continuare, dopo 3 mesi di percorso: 79€ / mese"
            case ProductChoice.SixMonthsPlan:
                return "Se vuoi continuare, dopo 6 mesi di percorso: 68€ / mese"
        }
    }

    const handleFirstCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10"><span className="w700 margin_right_10">1x</span> Videoconsulto con Nutrizionista</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">3x</span> Videoconsulti con Nutrizionista</span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">6x</span> Videoconsulti con Nutrizionista</span>
        }
    }

    const handleSecondCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(1 mese di percorso)</span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(3 mesi di percorso)</span></span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(6 mesi di percorso)</span></span>
        }
    }

    return (
        <div className="new-product-card-external-container">
            <div className="new-product-card-no-test-external-container">
                <div className={`new-product-card-no-test-most-selected-text ${product !== ProductChoice.ThreeMonthsPlan ? "invisible" : ""}`}>
                    Il più selezionato
                </div>
                <div className="height_20" />
                <div className="new-product-card-section-1-no-test-container">
                    <div className="new-product-card-no-test-internal-container">
                        <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-product-tag-blue" : "new-product-card-product-tag"}`}>
                            {handleTagTextSub(product)}
                        </div>
                        <div className="new-product-card-copy">
                            {handleCardCopy()}
                        </div>
                        <div className={`new-product-card-price-container`}>
                            <div className="new-product-card-price">
                                {handlePrice()}
                            </div>
                            <div className="new-product-card-price-per-month">
                                {handlePricePerMonth()}
                            </div>
                        </div>
                        <div className="new-product-card-no-text-buttons-container">
                            <div className="new-product-card-product-category">
                                {handleFirstCategory()}
                            </div>
                            <div className="new-product-card-product-category">
                                {handleSecondCategory()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="height_20" />
                <div className="new-product-card-plus">+</div>
                <div className="height_20" />
                <div className="new-product-card-no-bundle-container">
                    <div className="new-product-card-no-bundle-internal-container">
                        <div className="vertical_flex_group align_items_center">
                            <div className={`new-product-card-product-tag`}>
                                DNA Test Complete
                            </div>
                        </div>
                        <div className="new-product-card-price">
                            {handleTestPrice(product)}
                        </div>
                        <div className="new-product-card-no-bundle-single-item-container">
                            <div className="new-product-card-no-bundle-single-item-internal-container">
                                <div><span className="w700">2000</span> Polimorfismi genetici analizzati</div>
                                <div><span className="w700">150+</span> Geni interpretati</div>
                                <div><span className="w700">43</span> Predisposizioni individuate</div>
                                <div><span className="w700">5</span> Aree della salute</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="height_20" />
                <div className="new-product-card-bundle-copy">
                    {handleBundleCopy(product)}
                </div>
                {/* <div className="new-product-card-proce-copy">
                {handlePriceCopy()}
            </div> */}
                <div className="height_20" />
                <button
                    className="new-product-card-button primary_action_color_background"
                    onClick={onClick}
                >
                    Acquista
                </button>
            </div>
            {/* <div className="new-product-card-container">
                <div className="new-product-card-internal-container">
                    <div className={`new-product-card-most-purchased ${product !== ProductChoice.ThreeMonthsPlan ? "invisible" : ""}`}>Il più acquistato</div>
                    <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-product-tag-blue" : "new-product-card-product-tag"}`}>
                        {handleTagText()}
                    </div>
                    <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-price-blue" : "new-product-card-price"}`}>
                        {handlePrice()}
                    </div>
                    <div className="new-product-card-buttons-container">
                        <div className="new-product-card-product-category">
                            {handleFirstCategory()}
                        </div>
                        <div className="new-product-card-product-category">
                            {handleSecondCategory()}
                        </div>
                        <div className="new-product-card-product-category">
                            {handleThirdCategory()}
                        </div>
                    </div>
                    <button
                        className="new-product-card-button primary_action_color_background"
                        onClick={onClick}
                    >
                        Acquista
                    </button>
                </div>
            </div> */}
        </div>
    )
}

interface MobileProps {
    product: ProductChoice,
    onClick: () => void,
    isFirstDivOpened: boolean,
    isSecondDivOpened: boolean,
    onFirstArrowClick: () => void,
    onSecondArrowClick: () => void,
}

export const NewProductCardMobile: React.FC<MobileProps> = ({
    product,
    onClick,
    isFirstDivOpened,
    isSecondDivOpened,
    onFirstArrowClick,
    onSecondArrowClick
}) => {
    const handleCardCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span><span className="w700">Percorso di 1 mese per iniziare a personalizzare la tua alimentazione.</span> Puoi decidere di rinnovare a questa tariffa mensilmente, oppure accedere a un piano di 3 o 6 mesi.</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span><span className="w700">Percorso di 3 mesi per raggiungere i tuoi obiettivi principali.</span> Dal termine del terzo mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 79 € al mese.</span>
            case ProductChoice.SixMonthsPlan:
                return <span><span className="w700">Percorso di 6 mesi per raggiungere i tuoi obiettivi a lungo termine.</span> Dal termine del sesto mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 68 € al mese.</span>
        }
    }

    const handlePrice = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span>89€</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>237€</span>
            case ProductChoice.SixMonthsPlan:
                return <span>408€</span>
        }
    }

    const handlePricePerMonth = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="invisible">89</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>79€ / mese</span>
            case ProductChoice.SixMonthsPlan:
                return <span>68€ / mese</span>
        }
    }

    const handleFirstCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10"><span className="w700 margin_right_10">1x</span> Videoconsulto con Nutrizionista</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">3x</span> Videoconsulti con Nutrizionista</span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">6x</span> Videoconsulti con Nutrizionista</span>
        }
    }

    const handleSecondCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(1 mese di percorso)</span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(3 mesi di percorso)</span></span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(6 mesi di percorso)</span></span>
        }
    }

    return (
        <div className="new-product-card-mobile-external-container">
            <div className={`new-product-card-mobile-most-selected ${product !== ProductChoice.ThreeMonthsPlan ? "invisible" : ""}`}>
                Il più selezionato
            </div>
            <div className={`${product === ProductChoice.ThreeMonthsPlan ? (isFirstDivOpened ? "new-product-card-mobile-sub-container-most-selected-bigger" : "new-product-card-mobile-sub-container-most-selected") : (isFirstDivOpened ? "new-product-card-mobile-sub-container-bigger" : "new-product-card-mobile-sub-container")}`}>
                <div className="new-product-card-mobile-sub-internal-container">
                    <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-mobile-sub-tag-blue" : "new-product-card-mobile-sub-tag"}`}>
                        {handleTagTextSub(product)}
                    </div>
                    <div className="new-product-card-mobile-sub-copy">
                        {handleCardCopy()}
                    </div>
                    <div className="new-product-card-mobile-sub-price-container">
                        <div className="new-product-card-mobile-sub-price">
                            {handlePrice()}
                        </div>
                        <div className="new-product-card-mobile-sub-price-per-month">
                            {handlePricePerMonth()}
                        </div>
                    </div>
                    {
                        isFirstDivOpened ?
                            <div className="new-product-card-mobile-bundle-contents-container">
                                <div className="new-product-card-mobile-product-category">
                                    {handleFirstCategory()}
                                </div>
                                <div className="new-product-card-mobile-product-category">
                                    {handleSecondCategory()}
                                </div>
                            </div> : null
                    }
                    <div onClick={onFirstArrowClick}>
                        <img src={isFirstDivOpened ? "/images/black-arrow-up.svg" : "/images/black-arrow-down.svg"} />
                    </div>
                </div>
            </div>
            <div className="new-product-card-mobile-plus">
                +
            </div>
            <div className={`${product === ProductChoice.ThreeMonthsPlan ? (isSecondDivOpened ? "new-product-card-mobile-test-container-most-selected-bigger" : "new-product-card-mobile-test-container-most-selected") : (isSecondDivOpened ? "new-product-card-mobile-test-container-bigger" : "new-product-card-mobile-test-container")}`}>
                <div className="new-product-card-mobile-test-internal-container">
                    <div className="new-product-card-mobile-sub-tag">
                        DNA Test Complete
                    </div>
                    <div className="new-product-card-mobile-test-price">
                        {handleTestPrice(product)}
                    </div>
                    {
                        isSecondDivOpened ?
                            <div className="new-product-card-mobile-test-single-item-container">
                                <div className="new-product-card-mobile-test-single-item-internal-container">
                                    <div><span className="w700">2000</span> Polimorfismi genetici analizzati</div>
                                    <div><span className="w700">150+</span> Geni interpretati</div>
                                    <div><span className="w700">43</span> Predisposizioni individuate</div>
                                    <div><span className="w700">5</span> Aree della salute</div>
                                </div>
                            </div> : null
                    }
                    <div
                        className="self_center"
                        onClick={onSecondArrowClick}
                    >
                        <img src={isSecondDivOpened ? "/images/black-arrow-up.svg" : "/images/black-arrow-down.svg"} />
                    </div>
                </div>
            </div>
            <div className="height_20" />
            <div className="new-product-card-mobile-bundle-copy">
                {handleBundleCopy(product)}
            </div>
            <div className="height_20" />
            <button
                className="new-product-card-mobile-purchase-button primary_action_color_background"
                onClick={onClick}
            >
                Acquista
            </button>
        </div>
    )
}

interface MobileNoBundleProps {
    product: ProductChoice,
    onClick: () => void,
    isSubNoBundleDivOpened: boolean,
    onSubNoBundleArrowClick: () => void,
}

export const NewProductCardNoTestMobile: React.FC<MobileNoBundleProps> = ({
    product,
    onClick,
    isSubNoBundleDivOpened,
    onSubNoBundleArrowClick,
}) => {
    const handleCardCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span><span className="w700">Percorso di 1 mese per iniziare a personalizzare la tua alimentazione.</span> Puoi decidere di rinnovare a questa tariffa mensilmente, oppure accedere a un piano di 3 o 6 mesi.</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span><span className="w700">Percorso di 3 mesi per raggiungere i tuoi obiettivi principali.</span> Dal termine del terzo mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 79 € al mese.</span>
            case ProductChoice.SixMonthsPlan:
                return <span><span className="w700">Percorso di 6 mesi per raggiungere i tuoi obiettivi a lungo termine.</span> Dal termine del sesto mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 68 € al mese.</span>
        }
    }

    const handlePrice = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span>89€</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>237€</span>
            case ProductChoice.SixMonthsPlan:
                return <span>408€</span>
        }
    }

    const handlePricePerMonth = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="invisible">89</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>79€ / mese</span>
            case ProductChoice.SixMonthsPlan:
                return <span>68€ / mese</span>
        }
    }

    const handlePriceCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return "Se vuoi continuare, dopo 1 mese di percorso: 89€ / mese"
            case ProductChoice.ThreeMonthsPlan:
                return "Se vuoi continuare, dopo 3 mesi di percorso: 79€ / mese"
            case ProductChoice.SixMonthsPlan:
                return "Se vuoi continuare, dopo 6 mesi di percorso: 68€ / mese"
        }
    }

    const handleFirstCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10"><span className="w700 margin_right_10">1x</span> Videoconsulto con Nutrizionista</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">3x</span> Videoconsulti con Nutrizionista</span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">6x</span> Videoconsulti con Nutrizionista</span>
        }
    }

    const handleSecondCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(1 mese di percorso)</span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(3 mesi di percorso)</span></span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale <span className="w700">(6 mesi di percorso)</span></span>
        }
    }

    return (
        <div className="new-product-card-mobile-external-container">
            <div className={`new-product-card-mobile-most-selected ${product !== ProductChoice.ThreeMonthsPlan ? "invisible" : ""}`}>
                Il più selezionato
            </div>
            <div className={`${product === ProductChoice.ThreeMonthsPlan ? (isSubNoBundleDivOpened ? "new-product-card-mobile-sub-container-most-selected-bigger" : "new-product-card-mobile-sub-container-most-selected") : (isSubNoBundleDivOpened ? "new-product-card-mobile-sub-container-bigger" : "new-product-card-mobile-sub-container")}`}>
                <div className="new-product-card-mobile-sub-internal-container">
                    <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-mobile-sub-tag-blue" : "new-product-card-mobile-sub-tag"}`}>
                        {handleTagTextSub(product)}
                    </div>
                    <div className="new-product-card-mobile-sub-copy">
                        {handleCardCopy()}
                    </div>
                    <div className="new-product-card-mobile-sub-price-container">
                        <div className="new-product-card-mobile-sub-price">
                            {handlePrice()}
                        </div>
                        <div className="new-product-card-mobile-sub-price-per-month">
                            {handlePricePerMonth()}
                        </div>
                    </div>
                    {
                        isSubNoBundleDivOpened ?
                            <div className="new-product-card-mobile-bundle-contents-container">
                                <div className="new-product-card-mobile-product-category">
                                    {handleFirstCategory()}
                                </div>
                                <div className="new-product-card-mobile-product-category">
                                    {handleSecondCategory()}
                                </div>
                            </div> : null
                    }
                    <div
                        onClick={onSubNoBundleArrowClick}
                    >
                        <img src={isSubNoBundleDivOpened ? "/images/black-arrow-up.svg" : "/images/black-arrow-down.svg"} />
                    </div>
                </div>
            </div>
            <div className="new-product-card-mobile-price-copy">
                {handlePriceCopy()}
            </div>
            <button
                className="new-product-card-mobile-purchase-button primary_action_color_background"
                onClick={onClick}
            >
                Acquista
            </button>
        </div>
    )
}

export const NewProductCardNoTest: React.FC<Props> = ({
    product,
    onClick,
}) => {

    const handleTagText = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return "1 mese di Holifya"
            case ProductChoice.ThreeMonthsPlan:
                return "3 mesi di Holifya"
            case ProductChoice.SixMonthsPlan:
                return "6 mesi di Holifya"
        }
    }

    const handleCardCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span><span className="w700">Percorso di 1 mese per iniziare a personalizzare la tua alimentazione.</span> Puoi decidere di rinnovare a questa tariffa mensilmente, oppure accedere a un piano di 3 o 6 mesi.</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span><span className="w700">Percorso di 3 mesi per raggiungere i tuoi obiettivi principali.</span> Dal termine del terzo mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 79 € mensilmente.</span>
            case ProductChoice.SixMonthsPlan:
                return <span><span className="w700">Percorso di 6 mesi per raggiungere i tuoi obiettivi principali e tuoi obiettivi a lungo termine.</span> Dal termine del sesto mese potrai rinnovare beneficiando di questa tariffa agevolata e pagare 68 € mensilmente.</span>
        }
    }

    const handlePrice = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span>89€</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>237€</span>
            case ProductChoice.SixMonthsPlan:
                return <span>408€</span>
        }
    }

    const handlePricePerMonth = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>79€ / mese</span>
            case ProductChoice.SixMonthsPlan:
                return <span>68€ / mese</span>
        }
    }

    const handleFirstCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10"><span className="w700 margin_right_10">1x</span> Videoconsulto con Nutrizionista</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">3x</span> Videoconsulti con Nutrizionista</span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10"><span className="w700 margin_right_10">6x</span> Videoconsulti con Nutrizionista</span>
        }
    }

    const handleSecondCategory = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span className="margin_left_10">Piano nutrizionale (<span className="w700">1 mese di percorso</span>)</span>
            case ProductChoice.ThreeMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale (<span className="w700">3 mesi di percorso</span>)</span>
            case ProductChoice.SixMonthsPlan:
                return <span className="margin_left_10">Piano nutrizionale (<span className="w700">6 mesi di percorso</span>)</span>
        }
    }

    const handlePriceCopy = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return "Se vuoi continuare, dopo 1 mese di percorso: 89€ / mese"
            case ProductChoice.ThreeMonthsPlan:
                return "Se vuoi continuare, dopo 3 mesi di percorso: 79€ / mese"
            case ProductChoice.SixMonthsPlan:
                return "Se vuoi continuare, dopo 6 mesi di percorso: 68€ / mese"
        }
    }

    return (
        <div className="new-product-card-no-test-external-container">
            <div className={`new-product-card-no-test-most-selected-text ${product !== ProductChoice.ThreeMonthsPlan ? "invisible" : ""}`}>
                Il più selezionato
            </div>
            <div className="height_20" />
            <div className="new-product-card-no-test-container">
                <div className="new-product-card-no-test-internal-container">
                    <div className={`${product === ProductChoice.ThreeMonthsPlan ? "new-product-card-product-tag-blue" : "new-product-card-product-tag"}`}>
                        {handleTagText()}
                    </div>
                    <div className="new-product-card-copy">
                        {handleCardCopy()}
                    </div>
                    <div className={`new-product-card-price-container`}>
                        <div className="new-product-card-price">
                            {handlePrice()}
                        </div>
                        <div className="new-product-card-price-per-month">
                            {handlePricePerMonth()}
                        </div>
                    </div>
                    <div className="new-product-card-no-text-buttons-container">
                        <div className="new-product-card-product-category">
                            {handleFirstCategory()}
                        </div>
                        <div className="new-product-card-product-category">
                            {handleSecondCategory()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-product-card-price-copy">
                {handlePriceCopy()}
            </div>
            <button
                className="new-product-card-button primary_action_color_background"
                onClick={onClick}
            >
                Acquista
            </button>
        </div>
    )
}

export const NewProductCardNoBundle: React.FC<Props> = ({
    product,
    onClick,
}) => {

    const handleTagText = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return "DNA Test Complete"
            case ProductChoice.ThreeMonthsPlan:
                return "Videoconsulto Nutrizionista"
            case ProductChoice.SixMonthsPlan:
                return "Videoconsulto genetico"
        }
    }

    const handlePrice = () => {
        switch (product) {
            case ProductChoice.OneDiet:
                return <span>330<span className="s20">€</span></span>
            case ProductChoice.ThreeMonthsPlan:
                return <span>89<span className="s20">€</span></span>
            case ProductChoice.SixMonthsPlan:
                return <span>99<span className="s20">€</span></span>
        }
    }

    return (
        <div className="new-product-card-no-bundle-external-container">
            <div className="new-product-card-no-bundle-container">
                <div className="new-product-card-no-bundle-internal-container">
                    <div className="vertical_flex_group align_items_center">
                        <div className={`${product === ProductChoice.OneDiet ? "new-product-card-product-tag" : "new-product-card-product-tag-bigger"}`}>
                            {handleTagText()}
                        </div>
                    </div>
                    <div className="new-product-card-price">
                        {handlePrice()}
                    </div>
                    {
                        product === ProductChoice.OneDiet ?
                            <div className="new-product-card-no-bundle-single-item-container">
                                <div className="new-product-card-no-bundle-single-item-internal-container">
                                    <div><span className="w700">2000</span> Polimorfismi genetici analizzati</div>
                                    <div><span className="w700">150+</span> Geni interpretati</div>
                                    <div><span className="w700">43</span> Predisposizioni individuate</div>
                                    <div><span className="w700">5</span> Aree della salute</div>
                                </div>
                            </div> : null
                    }
                    {
                        product === ProductChoice.ThreeMonthsPlan ?
                            <div className="new-product-card-no-bundle-double-items-container">
                                <div className="new-product-card-no-bundle-double-items-single-element-container">
                                    <div className="new-product-card-no-bundle-double-items-single-element-internal-container">
                                        <span className="w700 margin_right_20">1x</span> Videoconsulto con Nutrizionista
                                    </div>
                                </div>
                                <div className="new-product-card-no-bundle-double-items-single-element-container">
                                    <div className="new-product-card-no-bundle-double-items-single-element-internal-container">
                                        <span className="w700 margin_right_20">1x</span> Dieta (non DNA based)
                                    </div>
                                </div>
                            </div> : null
                    }
                    {
                        product === ProductChoice.SixMonthsPlan ?
                            <div className="new-product-card-no-bundle-single-item-container">
                                <div className="new-product-card-no-bundle-single-item-internal-container">
                                    <div><span className="w700">1x</span> Videoconsulto genetico</div>
                                    <div>
                                        Parla con l'esperto in nutrigenetica per ricevere una spiegazione dettagliata sui risultati del tuo report nutrigenetico.
                                    </div>
                                </div>
                            </div> : null
                    }
                </div>
            </div>
            <button
                className="new-product-card-button primary_action_color_background"
                onClick={onClick}
            >
                Approfondisci
            </button>
        </div>
    )
}

interface MobileOnlyTestProps {
    product: ProductChoice,
    onClick: () => void,
    isOnlyTestDivOpened: boolean,
    onOnlyTestArrowClick: () => void,
}

export const NewProductCardNoBundleMobile: React.FC<MobileOnlyTestProps> = ({
    product,
    onClick,
    isOnlyTestDivOpened,
    onOnlyTestArrowClick,
}) => {
    return (
        <div>
            <div className={`${product === ProductChoice.ThreeMonthsPlan ? (isOnlyTestDivOpened ? "new-product-card-mobile-test-container-most-selected-bigger" : "new-product-card-mobile-test-container-most-selected") : (isOnlyTestDivOpened ? "new-product-card-mobile-test-container-bigger" : "new-product-card-mobile-test-container")}`}>
                <div className="new-product-card-mobile-test-internal-container">
                    <div className="new-product-card-mobile-sub-tag">
                        DNA Test Complete
                    </div>
                    <div className="new-product-card-mobile-test-price">
                        {handleTestPrice(product)}
                    </div>
                    {
                        isOnlyTestDivOpened ?
                            <div className="new-product-card-mobile-test-single-item-container">
                                <div className="new-product-card-mobile-test-single-item-internal-container">
                                    <div><span className="w700">2000</span> Polimorfismi genetici analizzati</div>
                                    <div><span className="w700">150+</span> Geni interpretati</div>
                                    <div><span className="w700">43</span> Predisposizioni individuate</div>
                                    <div><span className="w700">5</span> Aree della salute</div>
                                </div>
                            </div> : null
                    }
                    <div
                        className="self_center"
                        onClick={onOnlyTestArrowClick}
                    >
                        <img src={isOnlyTestDivOpened ? "/images/black-arrow-up.svg" : "/images/black-arrow-down.svg"} />
                    </div>
                </div>
            </div>
            <div className="height_20" />
            <button
                className="new-product-card-mobile-purchase-button primary_action_color_background"
                onClick={onClick}
            >
                Approfondisci
            </button>
        </div>
    )
}
