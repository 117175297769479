import { FormEvent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { BASE_URL } from "../../../utils/Constants"
import "./css/ForgotPasswordInsertCode.css"

const ForgotPasswordInsertCode = () => {

    const location = useLocation();
    const email = location.state.email

    const [code, setCode] = useState("")
    const [codeErrorText, setCodeErrorText] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)
                navigate(`/new-password`, { state: { email, code } })
            }, 1000)
    }

    const handleResendCode = (e: FormEvent) => {
        callAPI();
    }

    async function callAPI() {
        const response = await fetch(`${BASE_URL}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                })
        })
        if (!response.ok) {
        } else {
            navigate(`/forgot-password-insert-code`, { state: { email } })
        }
    }

    const onBackButtonClick = () => {
        navigate("/forgot-password")
    }

    return (
        <div className="App relative">
            <div className={`forgot-password-insert-code-buttons-row ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`vertical_flex_group justify_content_center height_80_percent ${isLoading ? "visible_30_percent inactive" : ""}`}>
                <div className="vertical_flex_group white_background width_392">
                    <div className="s30 w700 lh24 self_center margin_top_20 margin_bottom_20">Inserisci codice</div>
                    <div className="s18 w400 lh26 margin_top_20 margin_bottom_20 center width_328 self_center">
                        Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. <br></br>Inseriscilo qui per reimpostare una nuova password.
                    </div>
                    <form
                        className="vertical_flex_group margin_top_20 margin_bottom_10 width_328 self_center"
                        onSubmit={handleSubmit}
                    >
                        <div className="vertical_flex_group margin_bottom_10">
                            <label className="s12 w600 uppercase">Codice recupero password</label>
                            <div className="vertical_flex_group black_border_bottom align_items_end">
                                <input
                                    className="input_field ls1"
                                    type="text"
                                    placeholder="000000"
                                    onChange={
                                        e => {
                                            setCode(e.target.value);
                                        }
                                    }
                                />
                            </div>
                            <label className="s12 w600 lh17 error_text margin_top_10">{codeErrorText}</label>
                        </div>
                        <button
                            className={`width_328 height_55 uppercase w700 s14 br5 blue_background no_border white_text center margin_top_10 ${code.length === 6 ? "pointer" : ""}`}
                            disabled={!(code.length === 6)}
                        >
                            Conferma codice
                        </button>
                    </form>
                    <div className="width_328 self_center margin_top_10 margin_bottom_10">
                        <button
                            className="height_55 pointer uppercase w700 s12 br5 width_328 resend_code_button_background no_border s14"
                            onClick={handleResendCode}
                        >
                            Invia nuovo codice
                        </button>
                    </div>
                </div>
            </div>
            {/* Just for centering reasons */}
            <div className='height_10_percent width_90_percent'></div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
        </div>
    )
}

export default ForgotPasswordInsertCode
