import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Consultancy from "../../../components/Consultancy"
import Expert from "../../../components/Expert"
import HorizontalLogoText from "../../../components/HorizontalLogoText"
import HorizontalNavBar from "../../../components/HorizontalNavBar"
import LoadingSpinner from "../../../components/LoadingSpinner"
import NavBar from "../../../components/NavBar"
import { BASE_URL, EXPERTS_LOGO, HOME_LOGO, MENU_LOGO, MENU_LOGO_FOCUS, PLAN_LOGO_FOCUS, RELATIVE_PATH_TO_PLATFORM, RELATIVE_PATH_TO_PLATFORM_EXPERTS, RELATIVE_PATH_TO_PLATFORM_HOME, RELATIVE_PATH_TO_PLATFORM_PLAN } from "../../../utils/Constants"
import { handleDate, handleDaySpan, handleDaysDifference, handleEnergy, handleExpertRole, handleHoursDifference, handleMinutesDifference, handleParamenterImage, handleParamenterName, handleSleepQuality, handleStressLevel, handleTraining, handleWaterIntake } from "../../../utils/Functions"
import { AppointmentStatus } from "../../../utils/enums/AppointmentStatus"
import { KeyParameter } from "../../../utils/enums/KeyParameter"
import { Mood } from "../../../utils/enums/Mood"
import { NavigationToQuizQ } from "../../../utils/enums/NavigationToQuizQ"
import { QuizType } from "../../../utils/enums/QuizType"
import { ShopifyItemSku } from "../../../utils/enums/ShopifyItemType"
import TrackingStatus from "../../../utils/enums/TrackingStatus"
import { Appointment } from "../components/Appointment"
import MenuPopup from "../components/MenuPopup"
import MoodDialog from "../components/MoodDialog"
import "../css/Platform.css"
import BookApointmentDialog from "../dashboard/components/BookApointmentDialog"
import BookConsultancyWidget from "../dashboard/widgets/BookConsultancyWidget"
import QuizQWidget from "../dashboard/widgets/QuizQWidget"
import RewardPageWidget from "../dashboard/widgets/RewardPageWidget"
import SurveyBWidget from "../dashboard/widgets/SurveyBWidget"
import ExpertCommentWidget from "../widgets/ExpertCommentWidget"
import ImportantThingsWidget from "../widgets/ImportantThingsWidget"
import MoodWidget from "../widgets/MoodWidget"
import NextAppointmentWidget from "../widgets/NextAppointmentWidget"
import PlanCardWidget from "../widgets/PlanCardWidget"
import ParameterWidget from "./components/ParameterWidget"
import "./css/Plan.css"

const Plan = () => {

    // Navigation parameters
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state.userId
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const productName = location.state.productName
    const refreshToken = location.state.refreshToken

    // Values for menu popup, retrieved with GET api
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [familyName, setFamilyName] = useState("")

    // Booleans to display either menu popup or loading spinner
    const [isLoading, setIsLoading] = useState(false)
    const [focusOnMenu, setFocusOnMenu] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_HOME, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onPlanIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_PLAN, { state: { userId, productName, accessToken, idToken, refreshToken } })
    }
    const onExpertsIconClick = () => {
        navigate(RELATIVE_PATH_TO_PLATFORM_EXPERTS, { state: { userId, productName, accessToken, idToken, refreshToken, name } })
    }
    const onMenuIconClick = () => {
        setFocusOnMenu(!focusOnMenu)
    }

    // LOGOUT
    async function callLogoutAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/logout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, body: JSON.stringify(
                {
                    email: email,
                }
            )
        })
        setIsLoading(false)
        if (response.status === 302) {
            const result = await response.json();
            console.log(result)
            navigate(`/login`)
        } else {
            console.log(response)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    // GET users info by user_id
    async function callAPIUserInfo() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            setName(responseJson.data.name);
            setEmail(responseJson.email);
            setFamilyName(responseJson.data.family_name);
            callAPIRetrieveMedicalUserInfo(responseJson.email)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        callAPIUserInfo()
        callAPIUserEmr()
        callGetTrackingByUserIdAPI()
        callGetMostRecentPlanApi()
        callGetPlansPDFApi()
        callGetOrdersRetrievedByUserIdApi()
        callGetSubscriptionsApi()
        setTimeout(() => {
            setIsLoading(false)
        }, 750)
    }, [])

    // *******************************************************************************************************************

    // GET User orders retrieved by user_id
    async function callGetOrdersRetrievedByUserIdApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/orders/user/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            if (responseJson.length !== 0) {
                setUserhasOrders(true)
                var index = 0
                while (responseJson[0].items[index] !== undefined) {
                    // if (responseJson[0].items[index].name === ProductType.DNATestAndSub) {
                    //     setIsSubbedUser(true)
                    // }
                    if (responseJson[0].items[index].type === ShopifyItemSku.ExpertConsult || responseJson[0].items[index].type === ShopifyItemSku.NutritionistConsult) {
                        setUserHasConsult(true)
                    }
                    index++
                }
            } else {

            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User subscriptions by user_id
    async function callGetSubscriptionsApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/payment/subscription/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            if (responseJson.status === "active" || responseJson.status === "trial") {
                setUserhasSubs(true)
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User plan by user_id
    async function callGetMostRecentPlanApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/user/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            setPlanGoal(responseJson.data.goal)
            setExpertComment(responseJson.data.comment)
            setExpertKeyPoints(responseJson.data.important_key_points)
            setImportantKeyPoints(responseJson.data.key_points)
            setPlanAvailable(true)
            callGetPlanPDFUrlApi(responseJson.id)
            setCurrentPlanCreatedAt(responseJson.created_at)
            setCurrentPlanUpdatedAt(responseJson.updated_at)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [currentPlanUrl, setCurrentPlanUrl] = useState("")

    // GET User plan by user_id
    async function callGetPlanPDFUrlApi(planId: string) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/patient/${userId}/plan-url?filename=${planId}.pdf`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            setCurrentPlanUrl(responseJson.url)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // GET User plan by user_id
    async function callGetPlansPDFApi() {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/plans/patient/${userId}/plan-urls`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [userHasOrders, setUserhasOrders] = useState(false)
    const [userHasSubs, setUserhasSubs] = useState(false)
    const [userHasConsult, setUserHasConsult] = useState(false)
    const [planAvailable, setPlanAvailable] = useState(false)
    const [quizQdone, setQuizQdone] = useState(false)
    const [surveyAdone, setSurveyAdone] = useState(false)
    const [surveyBdone, setSurveyBdone] = useState(false)

    // API for getting the tracking by the user id
    async function callGetTrackingByUserIdAPI() {
        // setIsLoading(true)
        const responseTracking = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseTracking.ok) {
            const responseTrackingJson = await responseTracking.json()
            if (responseTrackingJson.length !== 0) {
                setTrackingStatus(responseTrackingJson[0].tracking_status)
            }
        } else if (responseTracking.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseTracking.json()
            console.log(responseJson)
        }
    }

    const [trackingStatus, setTrackingStatus] = useState<TrackingStatus>(TrackingStatus.Purchased)

    // The goal to display as a protocol, the old avatar
    const [goal, setGoal] = useState("")
    // Paramaters coming from surveys
    const [weight, setWeight] = useState("")
    const [waterIntake, setWaterIntake] = useState("")
    const [waterIntakeAnswer, setWaterIntakeAnswer] = useState("")
    const [energy, setEnergy] = useState("")
    const [energyAnswer, setEnergyAnswer] = useState("")
    const [waistLine, setWaistLine] = useState("")
    const [sleepQuality, setSleepQuality] = useState("")
    const [sleepQualityAnswer, setSleepQualityAnswer] = useState("")
    const [stressLevel, setStressLevel] = useState("")
    const [stressLevelAnswer, setStressLevelAnswer] = useState("")
    const [hipsLine, setHipsLine] = useState("")
    const [training, setTraining] = useState("")
    const [trainingAnswer, setTrainingAnswer] = useState("")
    const [armCircumference, setArmCircumference] = useState("")

    // Daily mood
    const [mood, setMood] = useState(Mood.None)
    const [moodLog, setMoodLog] = useState("")

    // Boolean to display the mood dialog
    const [showMoodDialog, setShowMoodDialog] = useState(false)

    const [planGoal, setPlanGoal] = useState("")
    const [expertComment, setExpertComment] = useState("")
    const [expertKeyPoints, setExpertKeyPoints] = useState<string[]>([])
    const [importantKeyPoints, setImportantKeyPoints] = useState<KeyParameter[]>()

    const [currentPlanCreatedAt, setCurrentPlanCreatedAt] = useState("")
    const [currentPlanUpdatedAt, setCurrentPlanUpdatedAt] = useState("")

    // GET user emr by user_id
    async function callAPIUserEmr() {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            setQuizQdone(true)
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
            setGoal(responseJson.data.avatar)
            setWeight(responseJson.data.weight)
            setWaterIntake(handleWaterIntake(responseJson.data.water_intake))
            setWaterIntakeAnswer(responseJson.data.water_intake)
            setEnergy(handleEnergy(responseJson.data.energy))
            setEnergyAnswer(responseJson.data.energy)
            setWaistLine(responseJson.data.waistline)
            setSleepQuality(handleSleepQuality(responseJson.data.sleep_quality))
            setSleepQualityAnswer(responseJson.data.sleep_quality)
            setStressLevel(handleStressLevel(responseJson.data.stress_level))
            setStressLevelAnswer(responseJson.data.stress_level)
            setHipsLine(responseJson.data.hipsline)
            setTraining(handleTraining(responseJson.data.training))
            setTrainingAnswer(responseJson.data.training)
            setStressLevelAnswer(responseJson.data.stress_level)
            setArmCircumference(responseJson.data.arm_circumference)
            if (responseJson.mood !== null) {
                setMood(responseJson.mood.mood)
            }
            var index = 0
            while (responseJson.surveys[index] !== undefined) {
                if (responseJson.surveys[index].type === QuizType.SurveyA) {
                    setSurveyAdone(true)
                }
                if (responseJson.surveys[index].type === QuizType.SurveyB) {
                    setSurveyBdone(true)
                }
                index++
            }
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // UPDATE user emr by user_id
    async function callAPIUpdateUserEmr(moodLog: string) {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/emr/${userId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    mood_log: {
                        mood: mood,
                        log: moodLog,
                    }
                })
        })
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    // GET medical user info by patient
    async function callAPIRetrieveMedicalUserInfo(email: string) {
        // setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/patient/${userId}`, requestOptionsGET)
        // setIsLoading(false)
        if (responseUserinfo.ok) {
            const nutritionist = await responseUserinfo.json()
            const newExpert: Expert = {
                id: nutritionist.id,
                position: nutritionist.data.sex === "M" ? "Dott." : (nutritionist.data.sex === "F" ? "Dott.ssa" : "Doctor"),
                name: nutritionist.data.name,
                familyName: nutritionist.data.family_name,
                role: handleExpertRole(nutritionist.role),
                skills: nutritionist.tags,
                summary: nutritionist.data.bio,
                avatar: nutritionist.calendly_data.calendly_user.avatar_url,
                schedulingUrl: nutritionist.calendly_data.calendly_user.scheduling_url,
            }
            setNutritionist(newExpert)
            callAPIRetrieveBookingInfo(email, newExpert)
        } else if (responseUserinfo.status === 401) {
            // call refreshToken
            navigate("/login")
        } else {
            const responseJson = await responseUserinfo.json()
            console.log(responseJson)
        }
    }

    const [nutritionist, setNutritionist] = useState<Expert>()

    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [allAppointments, setAllAppointments] = useState<Appointment[]>([])

    // This boolean opens a dialog useful to book the next apointment
    const [showBookApointmentDialog, setShowBookApointmentDialog] = useState(false)

    // GET booking info by email
    async function callAPIRetrieveBookingInfo(email: string, nutritionist: Expert) {
        // setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET)
        // setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            const appointmentsArray: Appointment[] = []
            for (const appointment of responseJson) {
                appointmentsArray.push({
                    date: appointment.payload.scheduled_event.start_time,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    expert: nutritionist,
                    status: appointment.payload.status,
                })
            }
            setAppointments(appointmentsArray.filter(appointment => appointment.status === AppointmentStatus.Active))
            setAllAppointments(appointmentsArray)
            const index = responseJson.length - 1
            const newConsultancyDay = responseJson[index].payload.scheduled_event.start_time.substring(0, 10)
            const newConsultancy: Consultancy = {
                scehduledDate: newConsultancyDay,
                scheduledHour: (responseJson[index].payload.scheduled_event.start_time as String).substring(11, 13),
                scheduledMinutes: (responseJson[index].payload.scheduled_event.start_time as String).substring(14, 16),
                connectUrl: responseJson[index].payload.scheduled_event.location.join_url,
                rescheduleUrl: responseJson[index].payload.reschedule_url,
                cancelUrl: responseJson[index].payload.cancel_url,
            }
            setNextConsultancy(newConsultancy)
            const today = new Date()
            const todayYear = today.getFullYear()
            const todayMonth = (today.getMonth() + 1).toString().length === 1 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
            const todayDay = today.getDate()
            const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`
            setDaysToNextConsultancy(handleDaySpan(todayFormatted, newConsultancyDay))
        } else if (response.status === 401) {
            // call refreshToken
            navigate("/login")
        } else if (response.status === 404) {
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    // The next consultancy and days remaining to it
    const [nextConsultancy, setNextConsultancy] = useState<Consultancy>()
    const [daysToNextConsultancy, setDaysToNextConsultancy] = useState(0)

    const handleParameterValue = (parameter: KeyParameter) => {
        switch (parameter) {
            case KeyParameter.Energy:
                return energy
            case KeyParameter.Sleep:
                return sleepQuality
            case KeyParameter.WaistLine:
                return waistLine + " cm"
            case KeyParameter.Water:
                return waterIntake
            case KeyParameter.Weight:
                return weight + " kg"
            case KeyParameter.HipsLine:
                return hipsLine + " cm"
            case KeyParameter.ArmCircunference:
                return armCircumference + " cm"
            case KeyParameter.Training:
                return training
            case KeyParameter.StressLevel:
                return stressLevel
        }
    }

    // *******************************************************************************************************************

    return (
        <div className="App Quiz justify_content_center relative">
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO_FOCUS}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
                isLoading={isLoading}
            />
            <div
                className={`platform-full-screen-no-navbar-container relative ${isLoading ? "visible-0-percent inactive" : ""}`}
            >
                {
                    focusOnMenu ?
                        <MenuPopup
                            email={email}
                            name={name}
                            familyName={familyName}
                            onPersonalDataClick={() => navigate("/platform/personal-data", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onExamsAndTestClick={() => navigate("/platform/exams-and-tests", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                            onMyAnswersClick={() => navigate("/platform/my-answers", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onMyApointmentsClick={() => { navigate("/platform/my-apointments", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onPaymentMethodClick={() => { navigate("/platform/payment-method", { state: { productName, userId, idToken, accessToken, refreshToken } }) }}
                            onMyPurchasesClick={() => navigate("/platform/my-purchases", { state: { productName, userId, idToken, accessToken, refreshToken } })}
                            onFrequentQuestionsClick={() => window.open("https://www.holifya.com/pages/faqs", "_black")}
                            onLogoutClick={callLogoutAPI}
                            onBackgroundClick={onMenuIconClick}
                        /> : null
                }
                <div className={`platform-plan-full-container relative ${(focusOnMenu || showMoodDialog || showBookApointmentDialog) ? "visible_30_percent inactive" : ""}`}>
                    <div className="height_10_percent width_100_percent">
                        <HorizontalLogoText
                            image={"/images/plan-big-logo.svg"}
                            text="Percorso"
                            isInOwnView={true}
                        />
                    </div>
                    <div className="vertical-scroll-group-90 width_100_percent">
                        {
                            planAvailable ?
                                <div className="platform-final-element-container">
                                    <div className="plan-your-protocol-heading">
                                        Il tuo protocollo
                                    </div>
                                    <div className="height_20" />
                                    <div className="plan-your-protocol">
                                        <div className="plan-your-protocol-text">
                                            {planGoal !== "" ? planGoal : goal}
                                        </div>
                                    </div>
                                    {/* <div className="height_35" />
                                    <CheckupSurveyWidget
                                        onClick={() => console.log()}
                                    /> */}
                                    <div className="height_35" />
                                    <MoodWidget
                                        yourFeelingsChoice={mood}
                                        onClick={
                                            mood === "" ?
                                                (choice: Mood) => {
                                                    setMood(choice)
                                                    setShowMoodDialog(true)
                                                } :
                                                (choice: Mood) => { console.log(choice) }}
                                    />
                                    <div className="height_35" />
                                    <div className="plan-expert-area-container">
                                        <ImportantThingsWidget
                                            itemsLength={expertKeyPoints.length}
                                            expertGender={nutritionist?.position!}
                                            items={expertKeyPoints}
                                        />
                                        <ExpertCommentWidget
                                            expertGender={nutritionist?.position!}
                                            expertComment={expertComment}
                                        />
                                    </div>
                                    <div className="height_35" />
                                    <div className="plan-your-protocol-heading">
                                        Parametri importanti
                                    </div>
                                    <div className="height_20" />
                                    <div className="plan-important-key-points-container">
                                        {
                                            importantKeyPoints?.map(keyPoint => (
                                                <ParameterWidget
                                                    key={keyPoint}
                                                    name={handleParamenterName(keyPoint)}
                                                    image={handleParamenterImage(keyPoint, energyAnswer, waterIntakeAnswer, sleepQualityAnswer, stressLevelAnswer, trainingAnswer)}
                                                    value={handleParameterValue(keyPoint)}
                                                />
                                            ))
                                        }
                                        {/* <ParameterWidget
                                            name="Peso"
                                            image="/images/weight-icon.svg"
                                            value={weight + " kg"}
                                        />
                                        <ParameterWidget
                                            name="Acqua"
                                            image="/images/water-icon.svg"
                                            value={handleWaterIntake(waterIntake)}
                                        />
                                        <ParameterWidget
                                            name="Energia"
                                            image="/images/energy-icon.svg"
                                            value={handleEnergy(energy)}
                                        />
                                        <ParameterWidget
                                            name="Girovita"
                                            image="/images/girovita-icon.svg"
                                            value={waistLine + " cm"}
                                        />
                                        <ParameterWidget
                                            name="Sonno"
                                            image="/images/pillow-icon.svg"
                                            value={handleSleepQuality(sleepQuality)}
                                        /> */}
                                    </div>
                                    <div className="height_20" />
                                    <div className="plan-plans-area-container">
                                        {/* <NutritionalPlanSection /> */}
                                        <div className="plan-plans-area-pdf-external-container">
                                            <div className="plan-plans-area-pdf-heading">
                                                Il tuo piano alimentare
                                            </div>
                                            <div className="height_20" />
                                            <PlanCardWidget
                                                planUrl={currentPlanUrl}
                                                planCreatedAt={currentPlanCreatedAt}
                                                planUpdatedAt={currentPlanUpdatedAt}
                                            />
                                        </div>
                                        {/* <OldNutritionalPlansSection /> */}
                                    </div>
                                    <div className="height_20" />
                                </div> :
                                <div className="platform-final-element-container">
                                    <div className="plan-your-protocol-heading">
                                        {(userHasSubs || userHasConsult) ? "Il tuo piano alimentare sarà disponibile qui dopo che il tuo nutrizionista lo avrà condiviso con te a seguito del vostro consulto" : "Acquista un piano o un consulto con un Nutrizionista per ricevere in questa sezione il tuo piano alimentare personalizzato."}
                                    </div>
                                    {
                                        !quizQdone ?
                                            <div>
                                                <div className="height_35" />
                                                <QuizQWidget onClick={() => navigate("/quiz-q-home", { state: { name, idToken, accessToken, userId, productName, fromPlatform: true, fromWhere: NavigationToQuizQ.PlatformPlan } })} />
                                            </div> : null
                                    }
                                    {
                                        (quizQdone && !userHasSubs) ?
                                            <div>
                                                <div className="height_35" />
                                                <RewardPageWidget
                                                    onClick={() => navigate(`/reward-page-new`, { state: { name, idToken, accessToken, userId, doesUserWantTheTest: false, fromPlatform: true } })}
                                                />
                                            </div> : null
                                    }
                                    {
                                        ((userHasSubs || userHasConsult) && quizQdone && surveyAdone && !surveyBdone) ?
                                            <div>
                                                <div className="height_35" />
                                                <SurveyBWidget
                                                    onClick={() => navigate("/survey-b-new", { state: { userId, productName, accessToken, idToken, refreshToken, name } })}
                                                    hasBigPriority={trackingStatus === TrackingStatus.ReportGenerated || !userHasOrders}
                                                />
                                                <div>
                                                    <div className="height_35" />
                                                    {
                                                        (appointments.length === 0 || appointments[appointments.length - 1].remainingMinutes < -30) ?
                                                            nutritionist !== undefined ?
                                                                <BookConsultancyWidget
                                                                    name={nutritionist.name}
                                                                    onClick={() => setShowBookApointmentDialog(true)}
                                                                    isFirst={appointments.length === 0}
                                                                    lastAppointmentDate={appointments.length > 0 ? handleDate(appointments[appointments.length - 1].date) : ""}
                                                                />
                                                                : null
                                                            :
                                                            // <NextApointmentWidget
                                                            //     nutrizionista={nutritionist !== undefined ? nutritionist : fakeExpert}
                                                            //     isUrgent={daysToNextConsultancy === 1}
                                                            //     onEditClick={() => window.open(nextConsultancy?.rescheduleUrl, "_blank")}
                                                            //     onConnectClick={() => window.open(nextConsultancy?.connectUrl, "_blank")}
                                                            //     date={nextConsultancy !== undefined ? `${nextConsultancy.scehduledDate}` : "22 mar"}
                                                            //     time={nextConsultancy !== undefined ? `${nextConsultancy.scheduledHour}:${nextConsultancy.scheduledMinutes}` : "22 mar"}
                                                            //     remainingDays={daysToNextConsultancy}
                                                            // />
                                                            appointments.map(appointment => (
                                                                <NextAppointmentWidget
                                                                    isUrgent={appointment.remainingDays <= 1}
                                                                    appointment={appointment}
                                                                />
                                                            ))
                                                    }
                                                </div>
                                            </div> :
                                            null
                                    }
                                </div>
                        }
                    </div>
                </div>
                {
                    showMoodDialog ?
                        <MoodDialog
                            mood={mood}
                            moodLog={moodLog}
                            onMoodLogChange={(e) => setMoodLog(e.target.value)}
                            onDismissClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }
                            }
                            onDontSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr("")
                            }
                            }
                            onSendButtonClick={() => {
                                setShowMoodDialog(false)
                                callAPIUpdateUserEmr(moodLog)
                            }
                            }
                        /> : null
                }
                {
                    showBookApointmentDialog ?
                        <BookApointmentDialog
                            onCloseClick={() => setShowBookApointmentDialog(false)}
                            email={email}
                            onBookClick={() => {
                                setShowBookApointmentDialog(false)
                                window.open(nutritionist?.schedulingUrl, "_blank")
                            }}
                        /> : null
                }
            </div>
            {
                isLoading ? <div className="width_100_percent height_100_percent div-center absolute"><LoadingSpinner /></div> : null
            }
            <HorizontalNavBar
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                planLogo={PLAN_LOGO_FOCUS}
                onPlanIconClick={onPlanIconClick}
                expertsLogo={EXPERTS_LOGO}
                onExpertsIconClick={onExpertsIconClick}
                menuLogo={focusOnMenu ? MENU_LOGO_FOCUS : MENU_LOGO}
                onMenuIconClick={onMenuIconClick}
            />
        </div>
    )
}


export default Plan
